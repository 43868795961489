import { api } from './api';

export const getUploadAndDownloadUrl = async () => {
    const response = await api.get(`/presigedUrl`);
    return response.data.value;
};

export const postProposal = async (data: any) => {
    await api.post(`/proposal`, data);
};

export const getProposal = async (proposalId: string | undefined) => {
    const response = await api.get(`/proposal/${proposalId}`);
    return response.data.value;
};

export const getProposals = async () => {
    const response = await api.get(`/proposals`);
    return response.data.value.list;
};

export const getSentProposals = async () => {
    const response = await api.get(`/proposals/sent`);
    return response.data.value.list;
};

export const getReceivedProposals = async (cardId: string | undefined) => {
    const response = await api.get(`/proposals/received/${cardId}`);
    return response.data.value.list;
};

//검토하기
export const patchApprovedProposal = async (
    proposalId: string | undefined,
    isApproved: boolean
) => {
    const response = await api.patch(`/proposal/${proposalId}`, {
        isApproved,
    });
    return response.data;
};
