import React from 'react';
import styled from 'styled-components';
import { media } from '../../styles/MediaQueries';

interface GoogleProfileProps {
    onClick?: () => void;
    src?: string | null;
    alt?: string;
}

export default function GoogleProfile({
    onClick,
    src,
    alt,
}: GoogleProfileProps): React.ReactElement {
    const profileImageUrl =
        src && src.trim() !== ''
            ? src
            : 'https://res-console.cloudinary.com/dqx3hiabc/thumbnails/v1/image/upload/v1701417214/ZGVmYXVsdC1wcm9maWxlX3Q1d21maw==/grid_landscape';

    return (
        <StGoogleProfile onClick={onClick} src={profileImageUrl} alt={alt} />
    );
}

const StGoogleProfile = styled.img`
    min-width: 40px;
    max-width: 40px;
    max-height: 40px;
    min-height: 40px;
    border-radius: 50%;
    object-fit: cover;

    ${media.mobile`
    min-width: 35px;
    max-width: 35px;
    max-height: 35px;
    min-height: 35px;
    `}
`;
