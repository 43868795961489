import { CreateCardFormData } from '../pages/CreateCardPage/CreateCardPage';
import { api } from './api';

//카드
export const getCards = async () => {
    try {
        const response = await api.get(`/cards`);
        return response.data.value.list;
    } catch (error) {
        console.error('An error occurred while fetching cards:', error);
        throw error;
    }
};

//카드 디테일
export const getCardDetail = async (cardId: string | undefined) => {
    const response = await api.get(`/card/${cardId}`);
    return response.data.value;
};

//댓글
export const getComments = async (cardId: string | undefined) => {
    const response = await api.get(`/card/${cardId}/comments`);
    return response.data.value.list;
};

export const postComment = async ({
    cardId,
    comment,
    parentCommentId,
}: {
    cardId: string | undefined;
    comment: string;
    parentCommentId?: string | null;
}): Promise<void> => {
    const requestBody: any = {
        content: comment,
    };
    if (parentCommentId) {
        requestBody.parentCommentId = parentCommentId;
    }

    if (!cardId) {
        throw new Error('Card ID is undefined');
    }

    await api.post(`/card/${cardId}/comment`, requestBody);
};

export const patchComment = async ({
    commentId,
    newContent,
}: {
    commentId: string;
    newContent: string;
}) => {
    const requestBody = {
        content: newContent,
    };

    await api.patch(`/comment/${commentId}`, requestBody);
};

export const deleteComment = async (commentId: string) => {
    await api.delete(`/comment/${commentId}`);
};

//박수
export const patchClap = async (cardId: string) => {
    await api.patch(`/card/${cardId}/clap`);
};

//카드 작성, 수정 삭제
export const createCard = async (formData: CreateCardFormData) => {
    const response = await api.post(`/card/create`, formData);
    return response;
};

export const patchCard = async (
    cardId: string,
    formData: CreateCardFormData
) => {
    const response = await api.patch(`/card/${cardId}`, formData);
    return response;
};

export const deleteCard = async (cardId: string) => {
    await api.delete(`/card/${cardId}`);
};

//마이페이지 나의 카드
export const getMyCards = async () => {
    const response = await api.get(`/cards/created`);
    return response.data.value.list;
};

//카드 공개 숨김
export const handlePublicCard = async (cardId: string, isPublic: boolean) => {
    const response = await api.patch(`/card/${cardId}`, {
        isPublic: !isPublic,
    });
    return response;
};
