import React, { forwardRef, useEffect } from 'react';
import styled from 'styled-components';
import { media } from '../../../styles/MediaQueries';

interface ModifyModalProps {
    handleEdit: () => void;
    handleDelete: () => void;
    handleModifyModal: () => void;
}

export const ModifyModal = forwardRef<HTMLDivElement, ModifyModalProps>(
    ({ handleDelete, handleEdit, handleModifyModal }, ref) => {
        const stopPropagation = (event: React.MouseEvent) => {
            event.stopPropagation();
        };

        useEffect(() => {
            const checkIfClickedOutside = (e: MouseEvent) => {
                // ref가 MutableRefObject인지 확인하고, 클릭된 요소가 모달 밖인지 검사
                if (
                    ref &&
                    'current' in ref &&
                    ref.current &&
                    !ref.current.contains(e.target as Node)
                ) {
                    handleModifyModal();
                }
            };

            document.addEventListener('mousedown', checkIfClickedOutside);

            return () => {
                document.removeEventListener(
                    'mousedown',
                    checkIfClickedOutside
                );
            };
        }, [ref, handleModifyModal]);

        return (
            <StModifyModalContainer ref={ref} onClick={stopPropagation}>
                <StModalContent onClick={handleEdit}>수정</StModalContent>
                <StModalContent onClick={handleDelete}>삭제</StModalContent>
            </StModifyModalContainer>
        );
    }
);

const StModifyModalContainer = styled.div`
    display: flex;
    width: 100px;
    height: 65px;
    padding: 8px;
    flex-direction: column;
    align-items: flex-start;
    gap: 2px;
    right: 82px;
    bottom: 234px;
    border-radius: 16px;
    background: #f1f1f1;
    box-shadow: 0px 8px 12px 0px rgba(0, 0, 0, 0.4);
    z-index: 990;
    color: #121212;
    position: absolute;
    top: 30px;
    right: 5px;

    ${media.mobile`
    padding: 4px;
    width: 55px;
    height: 52px;
    `}
`;

const StModalContent = styled.div`
    display: flex;
    padding: 4px 8px;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    border-radius: 8px;
    cursor: pointer;

    &:hover {
        background: rgba(18, 18, 18, 0.1);
    }

    ${media.mobile`
    font-size: 13px;
    `}
`;
