import React from 'react';
import styled from 'styled-components';
import { media } from '../../../../styles/MediaQueries';
import { useRecoilValue } from 'recoil';
import { userState } from '../../../../shared/State/atom';
import { useNavigate } from 'react-router-dom';

export default function InvestorHeader() {
    const navigate = useNavigate();
    const user = useRecoilValue(userState);
    // console.log(user);
    return (
        <StHeaderContainer>
            <StInvestorHeader>
                <p>👋</p>
                <StContainer>
                    <p>안녕하세요, {user?.name} 님</p>
                    <StCreateCard>
                        <StPlus>+</StPlus>
                        <StText onClick={() => navigate('/create-card')}>
                            새로운 카드 만들기
                        </StText>
                    </StCreateCard>
                </StContainer>
            </StInvestorHeader>
        </StHeaderContainer>
    );
}

const StHeaderContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const StInvestorHeader = styled.div`
    display: flex;
    flex-direction: column;
    width: 80%;
    gap: 5px;

    p {
        font-size: 28px;
        font-weight: 700;

        ${media.mobile`
       font-size: 24px;
    `}
    }

    ${media.tablet`
        width: 90%
    `}
`;

const StContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 28px;
    font-weight: 700;
    line-height: 160%;

    ${media.mobile`
        flex-direction: column;
        gap: 20px;
        align-items: baseline;
    `}
`;

const StCreateCard = styled.div`
    cursor: pointer;
    display: flex;
    justify-content: center;
    gap: 10px;
    border: 1px solid white;
    background-color: #feef68;
    padding: 5px 20px;
    border-radius: 10px;

    &:hover {
        opacity: 0.6;
    }
`;

const StPlus = styled.div`
    color: white;
    font-size: 28px;
`;

const StText = styled.div`
    font-size: 16px;
`;
