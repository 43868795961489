import React, { useState, useEffect, useRef } from 'react';
import styled, { keyframes } from 'styled-components';
import { IoArrowBackCircleOutline } from 'react-icons/io5';
import { IoMdPaperPlane } from 'react-icons/io';
import { media } from '../../styles/MediaQueries';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
    connectChat,
    getMessages,
    joinChat,
    postMessage,
} from '../../api/chatApi';

interface Message {
    messageId: string;
    senderId: string;
    text: string;
    createdAt: string;
}

interface GroupedMessages {
    date: string;
    messages: Message[];
}

function formatDate(dateString: string) {
    return new Date(dateString).toLocaleDateString('ko-KR', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    });
}

function formatTime(dateString: string) {
    return new Date(dateString).toLocaleTimeString('ko-KR', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
    });
}

function groupMessagesByDate(messages: Message[]): GroupedMessages[] {
    const grouped: Record<string, Message[]> = {};

    messages.forEach((message) => {
        const date = formatDate(message.createdAt);
        if (!grouped[date]) {
            grouped[date] = [];
        }
        grouped[date].push(message);
    });

    return Object.entries(grouped).map(([date, messages]) => ({
        date,
        messages,
    }));
}

export default function ChatPage() {
    const { partnerUserId } = useParams();
    const [text, setText] = useState('');
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const [messageList, setMessageList] = useState<Message[]>([]);
    const messagesEndRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'auto' });
        }
    }, [messageList]);

    const navigateToChatList = () => {
        navigate('/investor-mypage/messages');
    };

    // console.log(partnerUserId);

    const { data: chat } = useQuery({
        queryKey: ['joinChat', partnerUserId],
        queryFn: () => joinChat(partnerUserId),
    });

    // console.log(chat);

    const chatId = chat?.chatId;

    const {
        data: messages,
        isLoading,
        isError,
        error,
    } = useQuery({
        queryKey: ['getMessages', chatId],
        queryFn: () => getMessages(chatId),
    });

    useEffect(() => {
        if (chatId) {
            // 기존 메시지 목록 가져오기
            getMessages(chatId).then((fetchedMessages) => {
                setMessageList(fetchedMessages);
            });

            // SSE 연결
            const eventSource = connectChat(chatId);

            eventSource.onmessage = (event) => {
                const eventData = JSON.parse(event.data);

                // 서버에서 보낸 'ping' 메시지가 아닌 경우에만 처리
                if (eventData.value && eventData.value.messageId) {
                    setMessageList((prevMessages) => [
                        ...prevMessages,
                        eventData.value,
                    ]);
                }
            };

            return () => {
                eventSource.close();
            };
        }
    }, [chatId]);

    // console.log(messages);

    const groupedMessages = messageList ? groupMessagesByDate(messageList) : [];
    console.log(groupedMessages);

    const mutation = useMutation({
        mutationFn: (data: { chatId: string; text: string }) =>
            postMessage(data),
        onSuccess: (data, variables) => {
            // 메시지 전송 후 목록 업데이트
            const newMessage = {
                messageId: data.messageId, // 서버에서 반환된 messageId 사용
                senderId: '현재 사용자 ID', // 현재 사용자 ID 설정
                text: variables.text, // 전송한 메시지 텍스트
                createdAt: new Date().toISOString(), // 현재 시간
            };

            setMessageList((prevMessages) => [...prevMessages, newMessage]);
            setText(''); // 입력 필드 초기화
        },
    });

    const handleTextChange = (
        event: React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        setText(event.target.value);
    };

    const handleSubmit = () => {
        if (text.trim() !== '') {
            mutation.mutate({ chatId, text });
        }
    };

    if (isLoading) {
        return <Spinner />;
    }

    if (isError) {
        return <span>Error: {error.message}</span>;
    }

    return (
        <StChatPage>
            <StBackToList onClick={navigateToChatList}>
                <IoArrowBackCircleOutline size={30} color='#adadad' />
                <div>채팅 목록 보기</div>
            </StBackToList>
            <StChatBox>
                <StHeader>
                    <StImg src={chat.partner.profileUrl} />
                    <InvestorContainer>
                        <StInvestorName>{chat.partner.name}</StInvestorName>
                        <StInvestorPosition>
                            {`${chat.partner.position} @ ${chat.partner.house.name}`}
                        </StInvestorPosition>
                    </InvestorContainer>
                </StHeader>
                <StBody>
                    <StChatItemList>
                        {groupedMessages.map((group) => (
                            <React.Fragment key={group.date}>
                                <StYMD>{group.date}</StYMD>
                                {group.messages.map((message, messageIndex) => {
                                    // 다음 메시지와 시간 및 발신자 비교
                                    const isLastMessageInGroup =
                                        messageIndex ===
                                        group.messages.length - 1;
                                    const nextMessage =
                                        group.messages[messageIndex + 1];
                                    const isTimeOrSenderChange =
                                        isLastMessageInGroup ||
                                        formatTime(message.createdAt) !==
                                            formatTime(
                                                nextMessage?.createdAt
                                            ) ||
                                        message.senderId !==
                                            nextMessage?.senderId;

                                    return (
                                        <StChatItem key={message.messageId}>
                                            <StMessageContainer
                                                $isSender={
                                                    message.senderId !==
                                                    partnerUserId
                                                }
                                            >
                                                <StText
                                                    $isSender={
                                                        message.senderId !==
                                                        partnerUserId
                                                    }
                                                >
                                                    {message.text}
                                                </StText>
                                                {isTimeOrSenderChange && (
                                                    <StTime
                                                        $isSender={
                                                            message.senderId !==
                                                            partnerUserId
                                                        }
                                                    >
                                                        {formatTime(
                                                            message.createdAt
                                                        )}
                                                    </StTime>
                                                )}
                                            </StMessageContainer>
                                        </StChatItem>
                                    );
                                })}
                            </React.Fragment>
                        ))}
                    </StChatItemList>
                    <div ref={messagesEndRef} />
                </StBody>
                <StFooter>
                    <textarea
                        placeholder='메세지를 입력하세요.'
                        value={text}
                        onChange={handleTextChange}
                    ></textarea>
                    <StIcon onClick={handleSubmit}>
                        <IoMdPaperPlane size={20} />
                    </StIcon>
                </StFooter>
            </StChatBox>
        </StChatPage>
    );
}

const StChatPage = styled.div`
    margin: 80px 0px 100px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 28px;
`;

const StBackToList = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
    width: 80%;
    color: #8b8b8b;

    cursor: pointer;

    &:hover {
        opacity: 0.6;
    }

    ${media.mobile`
        width: 95%;
        `}
`;

const StChatBox = styled.div`
    display: flex;
    flex-direction: column;
    gap: 50px;
    padding: 40px;
    width: 60%;
    background-color: white;
    border: 1px solid #bcbcbc;
    border-radius: 5px;

    ${media.tablet`
        width: 80%;
        `}

    ${media.mobile`
        width: 98%;
        `}
`;

const StHeader = styled.div`
    display: flex;
    align-items: center;
    gap: 20px;
`;

const StImg = styled.img`
    width: 60px;
    height: 60px;
    border-radius: 50%;
    object-fit: cover;
`;

const InvestorContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const StInvestorName = styled.div`
    font-weight: 700;
`;

const StInvestorPosition = styled.div`
    font-size: 13px;
    font-weight: 300;
`;

const StBody = styled.div`
    height: 350px;
    max-height: 350px;
    overflow-y: auto;
`;

const StChatItemList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const StChatItem = styled.div``;

const StYMD = styled.div`
    display: flex;
    justify-content: center;
    margin: 30px 0px;
    font-size: 15px;
    color: #767676;
    font-weight: 500;
`;

const StMessageContainer = styled.div<{ $isSender: boolean }>`
    display: flex;
    flex-direction: ${(props) => (props.$isSender ? 'row-reverse' : 'row')};
`;

const StText = styled.div<{ $isSender: boolean }>`
    display: flex;
    border: none;
    background-color: ${(props) => (props.$isSender ? '#FFF48E' : '#f1f1f1')};
    border-radius: 15px;
    padding: 15px 15px 15px 17px;
    width: fit-content;
    word-break: break-word;
    white-space: pre-line;
    max-width: 50%;
`;

const StTime = styled.div<{ $isSender: boolean }>`
    display: flex;
    align-items: flex-end;

    margin: 5px 10px 0 10px;
    font-size: 13px;
    color: #7f7f7f;
`;

const StFooter = styled.div`
    display: flex;
    position: relative;
    align-items: center;

    textarea {
        width: 100%;
        padding: 10px 40px 10px 20px;
        border-radius: 10px;
        border: 1px solid #121212;
        resize: none;

        &:focus {
            outline: 3px solid #bababa;
        }
    }
`;

const StIcon = styled.div`
    cursor: pointer;
    position: absolute;
    right: 20px;

    &:hover {
        opacity: 0.5;
    }
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

// 스피너 스타일링
const Spinner = styled.div`
    border: 5px solid #f3f3f3;
    border-top: 5px solid #888888;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    animation: ${spin} 2s linear infinite;
    margin: 200px auto;
`;
