import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import { media } from '../../../styles/MediaQueries';
import FounderHeader from './components/FounderHeader';
import FounderNav from './components/FounderNav';

export default function FounderMyPageLayout() {
    return (
        <StLayoutContainer>
            <FounderHeader />
            <StContentContainer>
                <StContent>
                    <FounderNav />
                    <Outlet />
                </StContent>
            </StContentContainer>
        </StLayoutContainer>
    );
}

const StLayoutContainer = styled.article`
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 100%;
    margin-bottom: 100px;
`;

const StContentContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const StContent = styled.div`
    display: flex;
    width: 80%;

    ${media.tablet`
        width: 90%
    `}

    ${media.mobile`
        flex-direction: column;
    `}
`;
