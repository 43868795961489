import styled from 'styled-components';
import { Theme } from '../../styles/theme';
import { media } from '../../styles/MediaQueries';

interface StlogoContainerProps {
    $isLogin: boolean;
}

export const StHeaderContainer = styled.section`
    position: fixed;
    display: flex;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: 1440px;
    height: 80px;
    padding: 14px 51px;
    font-size: 14px;
    justify-content: space-between;
    align-items: center;
    background-color: ${Theme.colors.white};
    color: ${Theme.colors.black};
    z-index: 10;

    p {
        font-weight: 400;
        display: block;

        ${media.mobile`
            display: none; 
        `}
    }

    span {
        font-weight: 700;
    }

    ${media.mobile`
    padding-top: 25px;
        justify-content: center; 
        height: 50px;
    `}
`;

export const StlogoContainer = styled.div<StlogoContainerProps>`
    display: flex;
    width: 125.379px;
    height: 30px;
    justify-content: center;
    align-items: flex-start;
    gap: 10.8px;
    cursor: pointer;
    margin-right: ${(props) => (props.$isLogin ? '20px' : '20px')};
    ${media.mobile`
    margin: 0;
    `}
`;

export const StLogoText = styled.img`
    width: 84.579px;
    height: 26.388px;

    ${media.mobile`
    width: 56.386px;
height: 17.592px;
    `}
`;

export const StLogo = styled.img`
    width: 30px;
    height: 30px;
    ${media.mobile`
    width: 20px;
height: 20px;
    `}
`;

export const StRight = styled.div`
    display: flex;
    align-items: center;
    gap: 50px;
`;

export const StIconContainer = styled.div`
    position: relative;
    ${media.mobile`
    position: absolute;
    right: 80px;
    top: 50%;
    transform: translateY(-50%); 
    `}
`;

export const StUnRead = styled.div`
    position: absolute;
    top: 0px;
    right: -2px;
    width: 6px;
    height: 6px;
    border: none;
    border-radius: 50%;
    background-color: #ff4848;
`;

export const StIcon = styled.div`
    cursor: pointer;
    :hover {
        filter: opacity(0.6);
    }
`;

export const StGoogleProfile = styled.div`
    cursor: pointer;

    ${media.mobile`
    position: absolute;
    right: 16px; // 우측에서 16px 떨어진 위치에 표시
    top: 50%;
    transform: translateY(-50%); // 세로 중앙 정렬
    `}
    &:hover {
        filter: opacity(0.7);
    }
`;

export const StLoginButton = styled.button`
    display: flex;
    height: 50px;
    padding: 14px 25px;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
    border: 2px solid ${Theme.colors.black};
    border-radius: 30px;
    background: #121212;
    color: white;
    font-size: 14px;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
        background: transparent;
        border: 2px solid ${Theme.colors.black};
        color: ${Theme.colors.black};
    }

    ${media.mobile`
        display: none;
    `}
`;

export const StHamburgerIcon = styled.img`
    display: none;
    width: 28px;
    height: 28px;
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;

    &:hover {
        filter: opacity(0.7);
    }

    ${media.mobile`
        display: block;
    `}
`;

export const StBackdrop = styled.div<{ $isOpen: boolean }>`
    display: ${({ $isOpen }) => ($isOpen ? 'block' : 'none')};
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 991;
`;
