import React from 'react';
import styled from 'styled-components';
import { Theme } from '../../../../../styles/theme';
import useNavi from '../../../../../shared/hooks/useNavi';

export default function RequestLogin() {
    const { navigateToLoginPage } = useNavi();
    return (
        <StRequestLogin>
            <div>
                <div>투자 관련 내용</div>
                <div>자세히 알아보기</div>
            </div>
            <button onClick={navigateToLoginPage}>로그인</button>
        </StRequestLogin>
    );
}

const StRequestLogin = styled.div`
    display: flex;
    width: 200px;
    padding: 28px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    border-radius: 24px;
    border: 1px solid #f1f1f1;
    background: #fff;
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.12);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;

    color: #121212;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 150%; /* 21px */

    button {
        display: flex;
        height: 40px;
        padding: 18px 40px;
        justify-content: flex-end;
        align-items: center;
        gap: 8px;
        border-radius: 30px;
        background: #121212;
        color: white;
        font-weight: 700;
        cursor: pointer;
        transition: all 0.3s ease;

        &:hover {
            background: transparent;
            border: 2px solid ${Theme.colors.black};
            color: ${Theme.colors.black};
        }
    }
`;
