import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { media } from '../../../../styles/MediaQueries';
import { getUserInfo, patchUserInfo } from '../../../../api/userApi';
import DeleteUserModal from '../../investor/components/DeleteUserModal';
import { useQuery } from '@tanstack/react-query';
import { toast } from 'react-toastify';

export default function Profile() {
    const [selectedName, setSelectedName] = useState('');
    const [selectedPosition, setSelectedPosition] = useState('');
    const [selectedSector, setSelectedSector] = useState('');
    const [selectedRound, setSelectedRound] = useState('');
    const [isSaving, setIsSaving] = useState(false);
    const [isAgreeEmail, setIsAgreeEmail] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const {
        data: user,
        isLoading,
        isError,
        error,
        refetch,
    } = useQuery({
        queryKey: ['getUserInfo'],
        queryFn: getUserInfo,
    });

    useEffect(() => {
        if (user) {
            setSelectedName(user.name);
            setSelectedPosition(user.position);
            setSelectedSector(user.sector);
            setSelectedRound(user.round);
            setIsAgreeEmail(user.isAgreeEmail);
        }
    }, [user]);

    const handleCheckboxChange = () => {
        setIsAgreeEmail(!isAgreeEmail);
    };

    const handleSave = async () => {
        if (!selectedName.trim()) {
            alert('이름을 입력해주세요.');
            return;
        }

        setIsSaving(true);

        const formData = new FormData();
        formData.append('name', selectedName);
        formData.append('position', selectedPosition);
        formData.append('sector', selectedSector);
        formData.append('round', selectedRound);
        formData.append('isAgreeEmail', isAgreeEmail.toString());

        try {
            await patchUserInfo(formData);
            refetch();
            toast.success('프로필을 저장했어요!');
        } catch (error) {
            // 에러 처리
        } finally {
            setIsSaving(false);
        }
    };

    const handleWithdrawClick = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    if (isLoading) {
        return <Spinner />;
    }

    if (isError) {
        return <span>Error: {error.message}</span>;
    }

    // console.log(user);

    return (
        <StProfile>
            <StHeader>프로필</StHeader>
            <StBody>
                <StInfo>
                    <StKey>이메일</StKey>
                    <StValue>{user?.email}</StValue>
                </StInfo>
                <StInfo>
                    <StKey>이름</StKey>
                    <StInput
                        type='text'
                        value={selectedName}
                        onChange={(e) => setSelectedName(e.target.value)}
                    ></StInput>
                </StInfo>

                <StInfo>
                    <StKey>직책</StKey>
                    <select
                        value={selectedPosition}
                        onChange={(e) => setSelectedPosition(e.target.value)}
                    >
                        <option value='스타트업 대표'>스타트업 대표</option>
                        <option value='스타트업 종사자'>스타트업 종사자</option>
                        <option value='대기업 종사자'>대기업 종사자</option>
                        <option value='예비창업가'>예비창업가</option>
                        <option value='기타'>기타</option>
                    </select>
                </StInfo>
                <StInfo>
                    <StKey>섹터</StKey>
                    <select
                        value={selectedSector}
                        onChange={(e) => setSelectedSector(e.target.value)}
                    >
                        <option value='AR/VR'>AR/VR</option>
                        <option value='핀테크'>핀테크</option>
                        <option value='게임'>게임</option>
                        <option value='SaaS'>SaaS</option>
                        <option value='에듀테크'>에듀테크</option>
                        <option value='로보틱스'>로보틱스</option>
                        <option value='AI'>AI</option>
                        <option value='프롭테크'>프롭테크</option>
                        <option value='블록체인'>블록체인</option>
                        <option value='소셜미디어'>소셜미디어</option>
                        <option value='커뮤니티'>커뮤니티</option>
                        <option value='여행/레저'>여행/레저</option>
                        <option value='시니어'>시니어</option>
                        <option value='환경/ESG'>환경/ESG</option>
                        <option value='제조/하드웨어'>제조/하드웨어</option>
                        <option value='커머스'>커머스</option>
                        <option value='콘텐츠'>콘텐츠</option>
                        <option value='애그리 테크'>애그리 테크</option>
                        <option value='웰니스'>웰니스</option>
                        <option value='펫테크'>펫테크</option>
                        <option value='라이프 스타일'>라이프 스타일</option>
                        <option value='헬스케어/바이오'>헬스케어/바이오</option>
                        <option value='WEB 3.0'>WEB 3.0</option>
                        <option value='기타'>기타</option>
                    </select>
                </StInfo>
                <StInfo>
                    <StKey>현재 투자 단계</StKey>
                    <select
                        value={selectedRound}
                        onChange={(e) => setSelectedRound(e.target.value)}
                    >
                        <option value='연관 없음'>연관 없음</option>
                        <option value='투자유치 전'>투자유치 전</option>
                        <option value='pre-Seed'>pre-Seed</option>
                        <option value='Seed'>Seed</option>
                        <option value='pre-A'>pre-A</option>
                        <option value='Series-A'>Series-A</option>
                        <option value='Series-B'>Series-B</option>
                        <option value='Series-C'>Series-C</option>
                        <option value='pre-IPO'>pre-IPO</option>
                        <option value='IPO'>IPO</option>
                    </select>
                </StInfo>
                <StInfo>
                    <StKey>
                        <div>뉴스레터 구독</div>
                        <p>
                            새로운 카드 업데이트 및<br />
                            투자자의 답변을 빠르게 받아보세요
                        </p>
                    </StKey>
                    <StInput
                        type='checkbox'
                        checked={isAgreeEmail}
                        onChange={handleCheckboxChange}
                    />
                </StInfo>
            </StBody>
            <StFooter>
                <StSave onClick={handleSave}>
                    {isSaving ? <SmallSpinner /> : '저장하기'}
                </StSave>
                <StWithdraw onClick={handleWithdrawClick}>탈퇴하기</StWithdraw>
            </StFooter>
            {showModal && (
                <DeleteUserModal onClose={handleCloseModal} type='FOUNDER' />
            )}
        </StProfile>
    );
}

const StProfile = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-left: 20px;
    gap: 20px;

    ${media.mobile`
    margin-left: 0px;
    `}
`;

const StHeader = styled.div`
    padding: 10px 0px 20px 0px;
    font-size: 20px;
    font-weight: 700;
    border-bottom: 1px solid #d4d4d4;
    width: 100%;
`;

const StInfo = styled.div`
    display: flex;
    border-bottom: 1px solid #d9d9d9;
    align-items: center;
    padding: 20px 5px;

    select {
        padding: 10px;
        border-radius: 8px;
        border: 0.5px solid #828282;
        box-shadow: 0px 1.5px 0px 0px rgba(0, 0, 0, 0.2);
        cursor: pointer;

        &:hover {
            box-shadow: 0px 2.5px 3px 0px rgba(0, 0, 0, 0.2);
        }

        &:focus {
            outline: 3px solid #bababa;
        }
    }
`;

const StKey = styled.div`
    font-size: 18px;
    color: #979797;
    width: 200px;

    p {
        margin-top: 10px;
        font-size: 12px;
    }
`;

const StValue = styled.div`
    padding: 5px;
    font-weight: 500;
    font-size: 16px;
`;

const StBody = styled.div`
    font-size: 18px;
`;

const StInput = styled.input`
    padding: 10px;
    font-size: 15px;
    font-weight: 500;
    border-radius: 8px;
    border: 0.5px solid #828282;
    box-shadow: 0px 1.5px 0px 0px rgba(0, 0, 0, 0.2);

    &[type='checkbox'] {
        transform: scale(1.2);
        box-shadow: none;
    }

    &:hover {
        box-shadow: 0px 2.5px 3px 0px rgba(0, 0, 0, 0.2);
    }

    &:focus {
        outline: 3px solid #bababa;
    }
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const SmallSpinner = styled.div`
    border: 2px solid #f3f3f3;
    border-top: 2px solid #121212;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    animation: ${spin} 2s linear infinite;
`;

const StFooter = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const StSave = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 100px;
    height: 45px;
    border: 1px solid white;
    background-color: #feef68;
    border-radius: 10px;
    font-size: 15px;
    font-weight: 600;

    &:hover {
        opacity: 0.6;
    }
`;

const StWithdraw = styled.div`
    margin-right: 10px;
    color: #d4554c;
    text-decoration: underline;
    cursor: pointer;

    &:hover {
        opacity: 0.7;
    }
`;

const bigSpin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

// 스피너 스타일링
const Spinner = styled.div`
    border: 5px solid #f3f3f3;
    border-top: 5px solid #888888;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    animation: ${bigSpin} 2s linear infinite;
    margin: 300px auto;
`;
