export const saveJwt = (jwt: string) => {
    localStorage.setItem('jwt', jwt);
};

export const getJwt = () => {
    return localStorage?.getItem('jwt');
};

export const saveRefreshToken = (refreshToken: string) => {
    return localStorage.setItem('refreshToken', refreshToken);
};

export const getRefreshToken = () => {
    return localStorage.getItem('refreshToken');
};

export const handleLogout = () => {
    localStorage.removeItem('jwt');
    localStorage.removeItem('refreshToken');
    window.location.replace('/');
};
