import React, { useEffect } from 'react';
import styled from 'styled-components';
import { media } from '../../styles/MediaQueries';
import { useNavigate } from 'react-router-dom';
import { Theme } from '../../styles/theme';

interface CompletedCardModalProps {
    type: string;
}

export default function CompletedCardModal(props: CompletedCardModalProps) {
    const { type } = props; // type prop 추출
    const navigate = useNavigate();

    useEffect(() => {
        document.body.style.overflow = 'hidden';
        return () => {
            document.body.style.overflow = 'unset';
        };
    }, []);

    return (
        <StModalOverlay>
            <StCompletedModal>
                <StModalText>
                    <span>🎉</span> 카드가 {type} 되었어요 <span>🎉</span>
                </StModalText>
                <StModalButton
                    onClick={() => {
                        navigate('/');
                        window.scrollTo(0, 0);
                    }}
                >
                    카드 보러가기
                </StModalButton>
            </StCompletedModal>
        </StModalOverlay>
    );
}

const StModalOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
`;

const StCompletedModal = styled.div`
    background-color: white;
    padding: 40px 80px;
    border-radius: 20px;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
    text-align: center;

    ${media.mobile`
    padding: 30px 40px;
        `}
`;

const StModalText = styled.p`
    font-size: 20px;
    margin-bottom: 20px;
    font-weight: 500;

    span {
        font-size: 30px;
    }

    ${media.mobile`
    font-size: 17px;

    span {
        font-size: 25px;
    }
        `}
`;

const StModalButton = styled.button`
    background-color: #121212;
    color: white;
    border: 2px solid ${Theme.colors.black};
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
    width: 100%;
    border-radius: 20px;
    font-size: 16px;

    &:hover {
        background: transparent;
        border: 2px solid ${Theme.colors.black};
        color: ${Theme.colors.black};
    }
`;
