import { EventSourcePolyfill } from 'event-source-polyfill';
import { getJwt } from '../shared/TokenUtils/TokenUtils';
import { api } from './api';

export const connectNotification = () => {
    const jwt = getJwt();
    const url = `${process.env.REACT_APP_API_URL}/notifications/events`;

    let eventSource = new EventSourcePolyfill(url, {
        headers: { Authorization: `Bearer ${jwt}` },
    });

    let retryCount = 0;
    const maxRetries = 5; // 최대 재시도 횟수
    const retryInterval = 5000; // 재시도 간격 (ms)

    const attemptReconnect = () => {
        if (retryCount < maxRetries) {
            setTimeout(() => {
                console.log(`재연결 시도 ${retryCount + 1}/${maxRetries}`);
                eventSource = new EventSourcePolyfill(url, {
                    headers: { Authorization: `Bearer ${jwt}` },
                });
                retryCount++;
            }, retryInterval);
        } else {
            console.error('최대 재연결 시도 횟수 초과');
        }
    };

    eventSource.onopen = (event) => {
        // console.log('SSE opened:', event);
        retryCount = 0; // 연결 성공 시 재시도 횟수 초기화
    };

    eventSource.onerror = (error) => {
        console.error('SSE failed:', error);
        eventSource.close();
        attemptReconnect();
    };

    return eventSource;
};

export const readNewNotifications = async () => {
    const response = await api.patch(`/notifications/resolve-new-feeds`);
    return response.data.value;
};

export const getNewNotifications = async () => {
    const response = await api.get(`notifications/new-feeds`);
    return response.data.value;
};

export const getNotifications = async () => {
    const response = await api.get(`/notifications`);
    return response.data.value.list;
};

export const readNotification = async (notificationId: string) => {
    await api.patch(`/notifications/read/${notificationId}`);
};

export const readAllNotifications = async () => {
    await api.patch(`/notifications/read`);
};
