import React from 'react';
import styled, { css } from 'styled-components';

interface SectionItemProps {
    icon: string;
    title: string;
    content: string | string[];
    isLoggedIn: boolean;
}

export const SectionItem: React.FC<SectionItemProps> = ({
    icon,
    title,
    content,
    isLoggedIn,
}) => {
    const renderContent = () => {
        if (Array.isArray(content)) {
            if (title === '투자 단계') {
                return <h4>{content.join(', ')}</h4>;
            } else {
                return content.map((item, index) => (
                    <h4 key={index}>{item}</h4>
                ));
            }
        } else {
            return <h4>{content}</h4>;
        }
    };

    return (
        <StItemContainer>
            <StItemBox>
                <StIcon src={icon} alt={title} />
                <div>{title}</div>
            </StItemBox>
            <StContent $blur={!isLoggedIn}>{renderContent()}</StContent>
        </StItemContainer>
    );
};

const StItemContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;
    color: #121212;

    h4 {
        font-size: 16px;
    }
`;

const StItemBox = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
    color: rgba(18, 18, 18, 0.6);
    font-size: 14px;
    font-weight: 400;
    line-height: 140%;
`;

const StIcon = styled.img`
    width: 16px;
    height: 16px;
`;

const StContent = styled.div<{ $blur: boolean }>`
    word-break: break-word;
    filter: ${({ $blur }) => ($blur ? 'blur(5px)' : 'none')};
    user-select: none;
`;
