import React, { useState, useRef, forwardRef } from 'react';
import styled from 'styled-components';
import { CommentData } from '../../../shared/Type/type';
import GoogleProfile from '../../../shared/GoogleProfile/GoogleProfile';
import dotdotdot from '../../../assets/dotdotdot.png';
import CommentInput from './CommentInput';
import { ModifyModal } from './ModifyModal';
import { deleteComment } from '../../../api/cardApi';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import ModifyInput from './ModifyInput';

function timeAgo(createdAt: string) {
    const now = new Date();
    const createdDate = new Date(createdAt);
    const diffInSeconds = Math.floor(
        (now.getTime() - createdDate.getTime()) / 1000
    );

    if (diffInSeconds < 60) {
        return `방금 전`;
    }

    const diffInMinutes = Math.floor(diffInSeconds / 60);
    if (diffInMinutes < 60) {
        return `${diffInMinutes}분`;
    }

    const diffInHours = Math.floor(diffInMinutes / 60);
    if (diffInHours < 24) {
        return `${diffInHours}시간`;
    }

    const diffInDays = Math.floor(diffInHours / 24);
    if (diffInDays < 30) {
        return `${diffInDays}일`;
    }

    const diffInMonths = Math.floor(diffInDays / 30);
    if (diffInMonths < 12) {
        return `${diffInMonths}달`;
    }

    const diffInYears = Math.floor(diffInMonths / 12);
    return `${diffInYears}년`;
}

export const Comment = forwardRef<HTMLDivElement, CommentData>(
    (
        { userId, id, cardId, parentCommentId, writer, content, createdAt },
        ref
    ) => {
        const [isReplying, setIsReplying] = useState(false);
        const [isModifyModalOpen, setIsModifyModalOpen] = useState(false);
        const formattedCreatedAt = timeAgo(createdAt);
        const $isReply = parentCommentId !== null;
        const queryClient = useQueryClient();
        const modalRef = useRef<HTMLDivElement>(null);
        const [isEditing, setIsEditing] = useState(false); // 수정 모드 상태

        const deleteMutation = useMutation({
            mutationFn: deleteComment,
            onSuccess: () => {
                if (cardId) {
                    queryClient.invalidateQueries({
                        queryKey: ['comments', cardId],
                    });
                }
            },
        });

        const handleReplyClick = () => {
            setIsReplying(!isReplying);
        };

        const handleModifyModal = () => {
            setIsModifyModalOpen(!isModifyModalOpen);
        };

        const handleEdit = () => {
            setIsModifyModalOpen(false);
            setIsEditing(!isEditing); // 수정 모드 활성화
        };

        const handleDelete = () => {
            deleteMutation.mutate(id);
            setIsModifyModalOpen(false);
        };

        return (
            <StComment $isReply={$isReply}>
                <GoogleProfile src={writer.profileUrl} />
                <StContentDate>
                    {isEditing ? (
                        <ModifyInput
                            setIsEditing={setIsEditing}
                            content={content}
                            cardId={cardId}
                            commentId={id}
                        />
                    ) : (
                        <StNameContent>
                            <StName>{writer.name}</StName>
                            <div>{content}</div>
                        </StNameContent>
                    )}

                    <StDateContainer>
                        <div>{formattedCreatedAt}</div>
                        {!$isReply && (
                            <StAddReply onClick={handleReplyClick}>
                                답글 달기
                            </StAddReply>
                        )}
                    </StDateContainer>
                    {isReplying && (
                        <CommentInput
                            cardId={cardId}
                            parentCommentId={id}
                            onReplySubmitted={handleReplyClick}
                        />
                    )}
                </StContentDate>
                {userId === writer.id && (
                    <StModalIconContainer>
                        <StDotDotDot
                            src={dotdotdot}
                            alt='more options'
                            onClick={handleModifyModal}
                        />
                        {isModifyModalOpen && (
                            <ModifyModal
                                ref={modalRef}
                                handleEdit={handleEdit}
                                handleDelete={handleDelete}
                                handleModifyModal={handleModifyModal}
                            />
                        )}
                    </StModalIconContainer>
                )}
            </StComment>
        );
    }
);

const StComment = styled.div<{ $isReply: boolean }>`
    display: flex;
    align-items: flex-start;
    gap: 8px;
    padding-right: 20px;
    margin-left: ${({ $isReply }) => ($isReply ? '40px' : '0')};
`;

const StContentDate = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 15px;
    gap: 8px;
    flex-grow: 1;
`;

const StNameContent = styled.div`
    display: flex;
    padding: 12px;
    padding-right: 15px;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    border-radius: 16px;
    background: #f1f1f1;
    word-break: break-word;
`;

const StName = styled.div`
    font-size: 13px;
    font-weight: 700;
`;

const StDateContainer = styled.div`
    display: flex;
    align-items: flex-start;
    font-size: 13px;
    color: rgba(18, 18, 18, 0.6);
    gap: 15px;
    margin-bottom: 6px;
    margin-left: 10px;
`;

const StAddReply = styled.div`
    font-weight: 700;
    color: rgba(18, 18, 18, 0.6);
    cursor: pointer;

    &:hover {
        filter: opacity(0.5);
    }
`;

const StModalIconContainer = styled.div`
    position: relative;
`;

const StDotDotDot = styled.img`
    width: 24px;
    height: 24px;
    cursor: pointer;
    align-self: start;

    &:hover {
        filter: opacity(0.5);
    }
`;
