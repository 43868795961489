import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { getSentProposals } from '../../../../api/proposalApi';
import { Theme } from '../../../../styles/theme';
import { media } from '../../../../styles/MediaQueries';
import { HouseData } from '../../../../shared/Type/type';
import CheckSentReplyModal from '../../../CheckReceivedRequestsPage/components/CheckReplyModal';

interface Investor {
    name: string;
    house: HouseData;
    email: string;
}

interface Proposal {
    id: string;
    investor: Investor;
    createdAt: string;
    isReviewed: boolean;
    isApproved: boolean;
    fileUrl: string;
    review: string;
    companyName: string;
    name: string;
}

export default function SendRequests() {
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentProposal, setCurrentProposal] = useState<Proposal | null>(
        null
    );

    const openModal = (proposal: Proposal) => {
        setCurrentProposal(proposal);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setCurrentProposal(null);
        setIsModalOpen(false);
    };

    const formatDate = (dateString: string) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');

        return `${year}-${month}-${day}\n${hours}:${minutes}`;
    };

    const {
        data: proposalItems,
        isLoading,
        isError,
        error,
    } = useQuery({
        queryKey: ['getsentProposals'],
        queryFn: getSentProposals,
    });
    const [selectedTag, setSelectedTag] = useState('unReviewed');

    const unReviewedCount = proposalItems?.filter(
        (proposal: Proposal) => proposal.isApproved === null
    ).length;
    const approvedCount = proposalItems?.filter(
        (proposal: Proposal) => proposal.isApproved
    ).length;
    const disApprovedCount = proposalItems?.filter(
        (proposal: Proposal) => proposal.isApproved === false
    ).length;

    const filteredProposals = proposalItems
        ? proposalItems.filter((proposal: Proposal) => {
              switch (selectedTag) {
                  case 'unReviewed':
                      return proposal.isApproved === null;
                  case 'approved':
                      return proposal.isApproved;
                  case 'disApproved':
                      return proposal.isApproved === false;
                  default:
                      return true;
              }
          })
        : [];

    if (isLoading) {
        return <Spinner />;
    }

    if (isError) {
        return <span>Error: {error.message}</span>;
    }

    // console.log(proposalItems);

    return (
        <StRequestListContainer>
            <StHeader>{`보낸 검토 요청 (${proposalItems.length})`}</StHeader>
            <StTag>
                <StTagBox
                    onClick={() => setSelectedTag('unReviewed')}
                    selected={selectedTag === 'unReviewed'}
                >
                    {`답변을 기다리는 요청 (${unReviewedCount})`}
                </StTagBox>
                <StTagBox
                    onClick={() => setSelectedTag('approved')}
                    selected={selectedTag === 'approved'}
                >
                    {`후속 제안받은 요청 (${approvedCount})`}
                </StTagBox>
                <StTagBox
                    onClick={() => setSelectedTag('disApproved')}
                    selected={selectedTag === 'disApproved'}
                >
                    {`거절된 요청 (${disApprovedCount})`}
                </StTagBox>
            </StTag>
            <StRequestList>
                {filteredProposals.length > 0 ? (
                    filteredProposals.map(
                        (proposal: Proposal, index: string) => (
                            <StRequestListItem
                                key={index}
                                onClick={() => {
                                    navigate(`/proposal/${proposal.id}`);
                                    window.scrollTo(0, 0);
                                }}
                            >
                                <div>{index + 1}</div>
                                <StHouseContainer>
                                    <div>{proposal.investor.house.name}</div>
                                    <p>{proposal.investor.name}</p>
                                </StHouseContainer>
                                {proposal.isApproved !== null && (
                                    <StCheckReview
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            openModal(proposal);
                                        }}
                                    >
                                        답변 확인
                                    </StCheckReview>
                                )}
                                <StTime>
                                    {formatDate(proposal.createdAt)}
                                </StTime>
                            </StRequestListItem>
                        )
                    )
                ) : (
                    <StEmpty>
                        <div>리스트가 비어있습니다.</div>
                    </StEmpty>
                )}
            </StRequestList>
            {isModalOpen && currentProposal && (
                <CheckSentReplyModal
                    proposalId={currentProposal.id}
                    companyName={currentProposal.companyName}
                    name={currentProposal.name}
                    investorName={currentProposal.investor.name}
                    investorCompany={currentProposal.investor.house.name}
                    investorEmail={currentProposal.investor.email}
                    isApproved={currentProposal.isApproved}
                    isOpen={isModalOpen}
                    onClose={closeModal}
                />
            )}
        </StRequestListContainer>
    );
}

const StRequestListContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-left: 20px;
    gap: 20px;

    ${media.mobile`
    margin-left: 0px;
    `}
`;

const StHeader = styled.div`
    padding: 10px 0px 20px 0px;
    font-size: 20px;
    font-weight: 700;
    border-bottom: 1px solid #d4d4d4;
    width: 100%;
`;

const StTag = styled.div`
    display: flex;
`;

const StTagBox = styled.div<{ selected: boolean }>`
    display: flex;
    border: 1px solid #adadad;
    background-color: white;
    padding: 10px;
    align-items: center;
    justify-content: center;
    flex: 1;
    border-radius: 4px;
    font-weight: 600;
    cursor: pointer;
    border-bottom: ${(props) =>
        props.selected ? '3px solid #fdeb45' : '1px solid #adadad'};

    &:hover {
        opacity: 0.7;
    }

    ${media.mobile`
        font-size: 14px;
    `}
`;

const StRequestList = styled.div`
    display: flex;
    flex-direction: column;
    color: ${Theme.colors.black};
`;

const StRequestListItem = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    padding: 20px;
    margin: 10px 0;
    border-radius: 8px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
    cursor: pointer;

    &:hover {
        box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.3);
    }

    ${media.mobile`
    font-size: 14px;
    `}
`;

const StHouseContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    font-weight: 550;
`;

const StCheckReview = styled.div`
    text-decoration: underline;
    cursor: pointer;

    &:hover {
        opacity: 0.5;
    }

    ${media.mobile`
    font-size: 14px;
    `}
`;

const StTime = styled.div`
    font-size: 12px;
    font-weight: 200;
    white-space: pre-line;
    text-align: right;

    ${media.mobile`
    font-size: 11px;
    `}
`;

const StEmpty = styled.div`
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 50px;

    color: gray;
    font-size: 18px;
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

// 스피너 스타일링
const Spinner = styled.div`
    border: 5px solid #f3f3f3;
    border-top: 5px solid #888888;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    animation: ${spin} 2s linear infinite;
    margin: 100px auto;
`;
