import React from 'react';
import styled, { keyframes } from 'styled-components';
import { media } from '../../../styles/MediaQueries';
import { useQuery } from '@tanstack/react-query';
import { getCardDetail } from '../../../api/cardApi';
import investorDefault from '../../../assets/investor-default.png';

interface VCCardProps {
    cardId?: string;
}

export default function VCCard({ cardId }: VCCardProps) {
    const {
        isLoading,
        isError,
        data: cardDetail,
        error,
    } = useQuery({
        queryKey: ['CardDetail', cardId],
        queryFn: () => getCardDetail(cardId),
        enabled: !!cardId,
    });

    if (isLoading) {
        return <Spinner />;
    }

    if (isError) {
        return <span>Error: {error.message}</span>;
    }

    const profileImageUrl = cardDetail.writer.profileUrl || investorDefault;

    return (
        <StVCCardContainer>
            <StVCProfileContainer>
                <img
                    src={profileImageUrl}
                    referrerPolicy='no-referrer'
                    alt='VCImg'
                />
            </StVCProfileContainer>
            <StContentContainer>
                <StHead>
                    <p>안녕하세요, 대표님!</p>
                    <p>
                        <span>{cardDetail.house.name}</span>{' '}
                        <span>{cardDetail.writer.name}</span>입니다.
                    </p>
                </StHead>
                <StContent>
                    <p>"{cardDetail.title}"</p>
                    <p>
                        만약 본인이 그 문제를 풀고있다면, 아래 폼을 기입해주시면
                        감사하겠습니다.
                    </p>
                </StContent>
            </StContentContainer>
        </StVCCardContainer>
    );
}

const StVCCardContainer = styled.div`
    display: flex;
    gap: 40px;
    width: 60%;
    padding: 32px;
    align-items: center;
    border: 2px solid #fff;
    border-radius: 24px;
    background: #fff48e;
    margin-bottom: 50px;

    ${media.tablet`
        width: 80%;
        `}

    ${media.mobile`
        flex-direction: column;
        width: 90%;
        `}
`;

const StVCProfileContainer = styled.div`
    img {
        width: 200px;
        height: 200px;
        border-radius: 50%;
        object-fit: cover;

        ${media.tablet`
        width: 160px;
        height: 160px;
        `}

        ${media.mobile`
        width: 140px;
        height: 140px;
        `}
    }
`;

const StContentContainer = styled.div`
    font-size: 17px;
    line-height: 160%; /* 28.8px */

    ${media.tablet`
    font-size: 14px;
        `}

    ${media.mobile`
    font-size: 14px;
        `}

    span {
        font-weight: 700;
    }
`;

const StHead = styled.div`
    margin-bottom: 25px;
`;

const StContent = styled.div``;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

// 스피너 스타일링
const Spinner = styled.div`
    border: 5px solid #f3f3f3;
    border-top: 5px solid #888888;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    animation: ${spin} 2s linear infinite;
    margin: 300px auto;
`;
