import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { useQuery } from '@tanstack/react-query';
import { getMyCards, handlePublicCard } from '../../../../api/cardApi';
// import ModifyCardModal from '../components/ModifyCardModal';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { media } from '../../../../styles/MediaQueries';

interface CardData {
    title: string;
    createdAt: string;
    id: string;
    receivedProposalCount: number;
    isPublic: boolean;
}

interface HandlePublicCardParams {
    cardId: string;
    isPublic: boolean;
}

interface StToggleProps {
    $isPublic: boolean;
}

export default function MyCards() {
    // const [showModal, setShowModal] = useState(false);
    const [selectedCard, setSelectedCard] = useState('');
    const navigate = useNavigate();

    const queryClient = useQueryClient();
    const mutation = useMutation<AxiosResponse, Error, HandlePublicCardParams>({
        mutationFn: ({ cardId, isPublic }) =>
            handlePublicCard(cardId, isPublic),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['myCards'] });
        },
        onError: (error) => {
            console.error('Error toggling card public state:', error);
        },
    });

    const handleTogglePublic = (cardId: string, isPublic: boolean) => {
        mutation.mutate({ cardId, isPublic });
    };

    // const handleModifyClick = (card: any) => {
    //     setSelectedCard(card.id);
    //     setShowModal(true);
    // };

    // const handleCloseModal = () => {
    //     setShowModal(false);
    // };

    const navigateToCardDetail = (cardId: string) => {
        navigate(`/detail/${cardId}`);
    };

    const handleProposalCheck = (cardId: string) => {
        navigate(`/investor-mypage/proposals/${cardId}`); // 요청 확인 함수
    };

    const {
        data: myCards,
        isLoading,
        isError,
        error,
    } = useQuery<CardData[]>({
        queryKey: ['myCards'],
        queryFn: getMyCards,
    });

    if (isLoading) {
        return <Spinner />;
    }

    if (isError) {
        return <span>Error: {error.message}</span>;
    }

    const publicCards = myCards?.filter((card) => card.isPublic);
    const hiddenCards = myCards?.filter((card) => !card.isPublic);

    // console.log(myCards);

    return (
        <StMyCards>
            <StHeader>{`나의 카드 (${myCards?.length})`}</StHeader>
            <StOpenSection>
                <StOpenTitle>{`현재 공개 중인 카드 (${publicCards?.length})`}</StOpenTitle>
                <StCards>
                    {publicCards && publicCards?.length > 0 ? (
                        publicCards?.map((card, index) => (
                            <StOpenCardItem key={index}>
                                <StToggle
                                    $isPublic={card.isPublic}
                                    onClick={() =>
                                        handleTogglePublic(
                                            card.id,
                                            card.isPublic
                                        )
                                    }
                                ></StToggle>
                                <StCard
                                    onClick={() =>
                                        navigateToCardDetail(card.id)
                                    }
                                >
                                    {card.title}
                                </StCard>
                                <StBottom>
                                    <StDeleteModify
                                        onClick={() =>
                                            navigate(`/edit-card/${card.id}`)
                                        }
                                    >
                                        수정
                                    </StDeleteModify>
                                    <StCheck
                                        onClick={() =>
                                            handleProposalCheck(card.id)
                                        }
                                    >{`요청 확인하기 (${card.receivedProposalCount})`}</StCheck>
                                </StBottom>
                                <StCreatedAt>{`카드 생성일: ${card.createdAt.slice(
                                    0,
                                    10
                                )}`}</StCreatedAt>
                            </StOpenCardItem>
                        ))
                    ) : (
                        <StEmpty>공개 중인 카드가 없습니다.</StEmpty>
                    )}
                </StCards>
            </StOpenSection>
            <StCloseSection>
                <StCloseTitle>{`숨김 처리된 카드 (${hiddenCards?.length})`}</StCloseTitle>
                <StCards>
                    {hiddenCards && hiddenCards.length > 0 ? (
                        hiddenCards?.map((card, index) => (
                            <StCloseCardItem key={index}>
                                <StToggle
                                    $isPublic={card.isPublic}
                                    onClick={() =>
                                        handleTogglePublic(
                                            card.id,
                                            card.isPublic
                                        )
                                    }
                                ></StToggle>
                                <StCard
                                    onClick={() =>
                                        navigateToCardDetail(card.id)
                                    }
                                >
                                    {card.title}
                                </StCard>
                                <StBottom>
                                    <StDeleteModify
                                        onClick={() =>
                                            navigate(`/edit-card/${card.id}`)
                                        }
                                    >
                                        수정
                                    </StDeleteModify>
                                    <StCheck
                                        onClick={() =>
                                            handleProposalCheck(card.id)
                                        }
                                    >{`요청 확인하기 (${card.receivedProposalCount})`}</StCheck>
                                </StBottom>
                                <StCreatedAt>{`카드 생성일: ${card.createdAt.slice(
                                    0,
                                    10
                                )}`}</StCreatedAt>
                            </StCloseCardItem>
                        ))
                    ) : (
                        <StEmpty>숨김 처리된 카드가 없습니다.</StEmpty>
                    )}
                </StCards>
            </StCloseSection>
            {/* {showModal && (
                <ModifyCardModal
                    cardId={selectedCard}
                    onClose={handleCloseModal}
                />
            )} */}
        </StMyCards>
    );
}

const StMyCards = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-left: 20px;
    gap: 20px;

    ${media.mobile`
    margin-left: 0px;
    `}
`;

const StHeader = styled.div`
    padding: 10px 0px 20px 0px;
    font-size: 20px;
    font-weight: 700;
    border-bottom: 1px solid #d4d4d4;
    width: 100%;
`;

const StOpenSection = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

const StOpenTitle = styled.div`
    font-size: 18px;
    font-weight: 550;
`;

const StCards = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
`;

const StOpenCardItem = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const StToggle = styled.div<StToggleProps>`
    margin-left: 5px;
    width: 35px;
    height: 20px;
    background-color: ${(props) => (props.$isPublic ? '#121212' : '#d9d9d9')};
    border: none;
    border-radius: 20px;
    cursor: pointer;
    position: relative;

    &:hover {
        opacity: 0.6;
    }

    &::after {
        content: '';
        position: absolute;
        top: 3px;
        right: ${(props) => (props.$isPublic ? '3px' : 'auto')};
        left: ${(props) => (props.$isPublic ? 'auto' : '3px')};
        width: 14px;
        height: 14px;
        background-color: white;
        border-radius: 50%;
    }
`;

const StCard = styled.div`
    cursor: pointer;
    width: 270px;
    height: 220px;
    padding: 28px;
    background-color: #feef68;
    border: 1px solid white;
    border-radius: 20px;
    font-size: 20px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1.3;

    &:hover {
        opacity: 0.7;
    }
`;

const StBottom = styled.div`
    display: flex;
    gap: 5px;
    justify-content: center;
`;

const StDeleteModify = styled.div`
    background-color: white;
    border: none;
    padding: 10px 15px;
    width: 120px;
    display: flex;
    justify-content: center;
    font-size: 13px;
    border-radius: 5px;
    cursor: pointer;
    &:hover {
        opacity: 0.7;
    }
`;

const StCheck = styled.div`
    background-color: #121212;
    color: white;
    border: none;
    padding: 10px 0px;
    width: 120px;
    display: flex;
    justify-content: center;
    font-size: 13px;
    border-radius: 5px;
    cursor: pointer;
    &:hover {
        opacity: 0.7;
    }
`;

const StCreatedAt = styled.div`
    margin-left: 15px;
    font-size: 12px;
`;

const StCloseSection = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 40px;
`;

const StCloseTitle = styled.div`
    font-size: 18px;
    font-weight: 550;
`;

const StCloseCardItem = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const StEmpty = styled.div`
    text-align: center;
    color: #919191;
    font-size: 18px;
    padding: 50px 20px;
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

// 스피너 스타일링
const Spinner = styled.div`
    border: 5px solid #f3f3f3;
    border-top: 5px solid #888888;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    animation: ${spin} 2s linear infinite;
    margin: 300px auto;
`;
