import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { Theme } from '../../styles/theme';
import { media } from '../../styles/MediaQueries';
import { TbCircleDotted } from 'react-icons/tb';
import { useQuery } from '@tanstack/react-query';
import { getReceivedProposals } from '../../api/proposalApi';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { IoArrowBackCircleOutline } from 'react-icons/io5';
import ReplyModal from './components/ReplyModal';
import { UserData } from '../../shared/Type/type';
import CheckSentReplyModal from './components/CheckReplyModal';

interface Proposal {
    id: string;
    companyName: string;
    name: string;
    link: string;
    serviceIntroduction: string;
    content: string;
    investedCompany: string;
    investmentRound: string;
    createdAt: string;
    isApproved: boolean;
    fileUrl: string;
    investor: UserData;
}

export default function CheckReceivedRequestsPage() {
    const [isCheckSentReplyModalOpen, setIsCheckSentReplyModalOpen] =
        useState(false);
    const [isReplyModalOpen, setIsReplyModalOpen] = useState(false);
    const [selectedProposal, setSelectedProposal] = useState<Proposal | null>(
        null
    );

    const { cardId } = useParams<string>();
    const formatDate = (dateString: string) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        return `${year}-${month}-${day}, ${hours}:${minutes}`;
    };
    const navigate = useNavigate();

    const {
        data: proposalList,
        isLoading,
        isError,
        error,
        refetch,
    } = useQuery({
        queryKey: ['getReceivedProposals', cardId],
        queryFn: () => getReceivedProposals(cardId),
    });

    const [selectedTag, setSelectedTag] = useState('unReviewed');
    const unReviewedCount =
        proposalList?.filter(
            (proposal: Proposal) => proposal.isApproved === null
        ).length || 0;

    const reviewedCount =
        proposalList?.filter(
            (proposal: Proposal) => proposal.isApproved !== null
        ).length || 0;

    const filteredProposals = proposalList
        ? proposalList.filter((proposal: Proposal) =>
              selectedTag === 'unReviewed'
                  ? proposal.isApproved === null
                  : proposal.isApproved !== null
          )
        : [];

    const navigateCardList = () => {
        navigate('/investor-mypage/mycards');
    };

    const openReplyModal = (proposal: Proposal) => {
        setSelectedProposal(proposal);
        setIsReplyModalOpen(true);
    };

    const openCheckSentReplyModal = (proposal: Proposal) => {
        setSelectedProposal(proposal);
        setIsCheckSentReplyModalOpen(true);
    };

    const handleURLClick = (url: string) => {
        const koreanPattern = /^[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;
        if (koreanPattern.test(url)) {
            alert(`'${url}'은 올바른 형식의 URL이 아닙니다`);
            return;
        }
        if (!url.startsWith('http://') && !url.startsWith('https://')) {
            url = `https://${url}`;
        }

        window.open(url, '_blank');
    };

    // console.log(proposalList);

    console.log(filteredProposals);

    return (
        <StCheckReceivedRequestsPage>
            <StBackToList onClick={navigateCardList}>
                <StIcon>
                    <IoArrowBackCircleOutline size={30} color='#adadad' />
                </StIcon>
                <div>카드 목록 보기</div>
            </StBackToList>
            <StTag>
                <StTagBox
                    onClick={() => setSelectedTag('unReviewed')}
                    selected={selectedTag === 'unReviewed'}
                >
                    {`답변을 기다리는 스타트업 (${unReviewedCount})`}
                </StTagBox>
                <StTagBox
                    onClick={() => setSelectedTag('reviewed')}
                    selected={selectedTag === 'reviewed'}
                >
                    {`답변을 완료한 스타트업 (${reviewedCount})`}
                </StTagBox>
            </StTag>
            <StBody>
                {filteredProposals.length > 0 ? (
                    filteredProposals.map((proposal: Proposal) => (
                        <StRequest key={proposal.id}>
                            <StMain>
                                <StLeft>
                                    <StCompanyName>
                                        {proposal.companyName}
                                    </StCompanyName>
                                    <StFounderName>{`대표명: ${proposal.name}`}</StFounderName>
                                    <StURL
                                        onClick={() =>
                                            handleURLClick(proposal.link)
                                        }
                                    >{`🔗웹사이트`}</StURL>
                                </StLeft>
                                <StRight>
                                    <StTitle>
                                        <strong>한줄 소개:</strong>{' '}
                                        {proposal.serviceIntroduction}
                                    </StTitle>
                                    <StContent>
                                        <strong>투자 검토 개요:</strong>{' '}
                                        {proposal.content}
                                    </StContent>
                                    <StInvestedCompany>
                                        <strong>기투자사:</strong>{' '}
                                        {proposal.investedCompany}
                                    </StInvestedCompany>
                                    <StInvestmentRound>
                                        <strong>현재 투자 라운드:</strong>{' '}
                                        {proposal.investmentRound}
                                    </StInvestmentRound>
                                </StRight>
                            </StMain>
                            <StFooter>
                                <StCreatedAt>{`요청 일시: ${formatDate(
                                    proposal.createdAt
                                )}`}</StCreatedAt>
                                <StButtonContainer>
                                    <StCheckButton
                                        onClick={() =>
                                            window.open(
                                                proposal.fileUrl,
                                                '_blank'
                                            )
                                        }
                                    >
                                        IR덱 확인하기 📥
                                    </StCheckButton>
                                    <StReplyButton
                                        onClick={() => {
                                            if (selectedTag === 'unReviewed') {
                                                openReplyModal(proposal);
                                            } else {
                                                openCheckSentReplyModal(
                                                    proposal
                                                );
                                            }
                                        }}
                                    >
                                        {selectedTag === 'unReviewed'
                                            ? '3초만에 회신하기'
                                            : '보낸 회신 확인하기'}
                                    </StReplyButton>
                                </StButtonContainer>
                            </StFooter>
                        </StRequest>
                    ))
                ) : (
                    <StEmpty>리스트가 비어있습니다.</StEmpty>
                )}
                {isReplyModalOpen && selectedProposal && (
                    <ReplyModal
                        proposalId={selectedProposal.id}
                        companyName={selectedProposal.companyName}
                        name={selectedProposal.name}
                        investorName={selectedProposal.investor.name}
                        investorCompany={selectedProposal.investor.house.name}
                        investorEmail={selectedProposal.investor.email}
                        isOpen={isReplyModalOpen}
                        onClose={() => {
                            setIsReplyModalOpen(false);
                            setSelectedProposal(null);
                        }}
                        refetch={refetch}
                    />
                )}
            </StBody>
            {isCheckSentReplyModalOpen && selectedProposal && (
                <CheckSentReplyModal
                    proposalId={selectedProposal.id}
                    companyName={selectedProposal.companyName}
                    name={selectedProposal.name}
                    investorName={selectedProposal.investor.name}
                    investorCompany={selectedProposal.investor.house.name}
                    investorEmail={selectedProposal.investor.email}
                    isOpen={isCheckSentReplyModalOpen}
                    isApproved={selectedProposal.isApproved}
                    onClose={() => {
                        setIsCheckSentReplyModalOpen(false);
                        setSelectedProposal(null);
                    }}
                />
            )}
        </StCheckReceivedRequestsPage>
    );
}

const StCheckReceivedRequestsPage = styled.div`
    margin: 80px 0px 100px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 28px;
`;

const StBackToList = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
    width: 80%;
    color: #8b8b8b;

    cursor: pointer;

    &:hover {
        opacity: 0.6;
    }

    ${media.mobile`
        width: 95%;
        `}
`;

const StIcon = styled.div``;

const StTag = styled.div`
    display: flex;
    width: 80%;

    ${media.mobile`
        width: 95%;
        `}
`;

const StTagBox = styled.div<{ selected: boolean }>`
    display: flex;
    border: 1px solid #adadad;
    background-color: white;
    padding: 10px;
    align-items: center;
    justify-content: center;
    flex: 1;
    border-radius: 4px;
    font-weight: 600;
    cursor: pointer;
    border-bottom: ${(props) =>
        props.selected ? '3px solid #fdeb45' : '1px solid #adadad'};

    &:hover {
        opacity: 0.7;
    }

    ${media.mobile`
        font-size: 14px;
    `}
`;

const StBody = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 80%;

    ${media.mobile`
        width: 95%;
        `}
`;

const StRequest = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px;
    background-color: white;
    border: 1px solid #adadad;
    border-radius: 4px;
    gap: 20px;
    font-size: 15px;
    font-weight: 300;
`;

const StMain = styled.div`
    display: flex;
    gap: 40px;

    ${media.mobile`
        flex-direction: column;
        `}
`;

const StLeft = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    min-width: 160px;
`;

const StCompanyName = styled.div`
    font-size: 20px;
    font-weight: 600;
`;
const StFounderName = styled.div``;

const StURL = styled.div`
    cursor: pointer;
    text-decoration: underline;
    &:hover {
        opacity: 0.6;
    }
`;

const StRight = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;

    strong {
        font-weight: 600;
    }
`;

const StTitle = styled.div``;

const StContent = styled.div``;

const StInvestedCompany = styled.div``;

const StInvestmentRound = styled.div``;

const StFooter = styled.div`
    border-top: 1px solid #c6c5c5;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;

    ${media.mobile`
        flex-direction: column;
        gap: 10px;
        align-items: baseline;
    `}
`;

const StCreatedAt = styled.div`
    font-size: 14px;
    color: #919191;
`;

const StButtonContainer = styled.div`
    display: flex;
    gap: 10px;
`;

const StCheckButton = styled.div`
    display: flex;
    justify-content: center;
    width: 150px;
    padding: 10px;
    background-color: #121212;
    color: white;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    &:hover {
        opacity: 0.7;
    }
`;

const StReplyButton = styled.div`
    display: flex;
    justify-content: center;
    width: 150px;
    padding: 10px;
    background-color: #f1f1f1;
    color: #121212;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    &:hover {
        opacity: 0.7;
    }
`;

const StEmpty = styled.div`
    text-align: center;
    color: #919191;
    font-size: 18px;
    padding: 100px;
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

// 스피너 스타일링
const Spinner = styled.div`
    border: 5px solid #f3f3f3;
    border-top: 5px solid #888888;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    animation: ${spin} 2s linear infinite;
    margin: 200px auto;
`;
