import React from 'react';
import logo from '../../assets/logo.png';
import styled from 'styled-components';

export default function Footer() {
    const navigateToServiceTerm = () => {
        window.open(
            'https://decacorn-team.notion.site/fddf05944fbb4364bc931ae72c2c9558?pvs=4',
            '_blank'
        );
    };

    const navigateToPrivacyPolicy = () => {
        window.open(
            'https://decacorn-team.notion.site/0b29459b7429451e8db12359475d2acb?pvs=4',
            '_blank'
        );
    };
    return (
        <StFooterContainer>
            <img src={logo} alt='Logo-Deca' />
            <StTextContainer>
                <p onClick={navigateToServiceTerm}>Seek 이용 약관</p>
                <p onClick={navigateToPrivacyPolicy}>개인정보 처리방침</p>
                <div>문의 julia@decacorn.team</div>
            </StTextContainer>
        </StFooterContainer>
    );
}

const StFooterContainer = styled.section`
    display: flex;
    padding-left: 200px;
    align-items: center;
    width: 100%;
    height: 100px;
    background-color: #e2e2e2;
    font-size: 12px;
    margin-top: 100px;
    border-radius: 4px;
    gap: 70px;

    img {
        width: 40px;
        height: auto;
        cursor: pointer;
    }

    @media (max-width: 1075px) {
        padding-left: 100px;
        gap: 50px;
    }

    @media (max-width: 735px) {
        gap: 40px;
        padding-left: 100px; /* 패딩도 조정해줄 수 있습니다 */

        img {
            width: 60px;
            height: auto;
        }
    }
`;

const StTextContainer = styled.div`
    width: 80%;
    display: flex;
    gap: 200px;

    p {
        &:hover {
            opacity: 0.5;
            cursor: pointer;
        }
    }

    @media (max-width: 1075px) {
        gap: 80px;
    }

    @media (max-width: 735px) {
        flex-direction: column;
        gap: 5px;
    }
`;
