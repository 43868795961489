import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { media } from '../../styles/MediaQueries';
import { useNavigate } from 'react-router-dom';
import { getUserInfo, patchUserInfo } from '../../api/userApi';
import { useQuery } from '@tanstack/react-query';

interface FounderMustFillFormData {
    position: string;
    sector: string;
    funnels: string;
    round: string;
}

export default function FounderMustFill() {
    const {
        data: user,
        refetch,
        isSuccess,
    } = useQuery({
        queryKey: ['getUserInfo'],
        queryFn: getUserInfo,
    });
    const navigate = useNavigate();
    const [formData, setFormData] = useState<FounderMustFillFormData>({
        position: '',
        sector: '',
        round: '',
        funnels: '',
    });

    const isNotValid =
        formData.position === '' ||
        formData.sector === '' ||
        formData.round === '' ||
        formData.funnels === '';

    const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        const formDataToSend = new FormData();
        formDataToSend.append('position', formData.position);
        formDataToSend.append('sector', formData.sector);
        formDataToSend.append('round', formData.round);
        formDataToSend.append('funnels', formData.funnels);

        try {
            await patchUserInfo(formDataToSend);
            await refetch();
        } catch (error) {
            console.error('추가정보입력 실패:', error);
            alert('추가 정보 입력에 실패하였습니다.');
        }
    };

    useEffect(() => {
        if (isSuccess && user?.sector) {
            navigate('/');
        }
    }, [user?.sector, isSuccess, navigate]);

    return (
        <StContainer>
            <StFounderOnboardingPage>
                <StTitle>딱, 이것만 알려주세요!</StTitle>
                <StSelect>
                    <label htmlFor='position'>직책 *</label>
                    <select
                        id='position'
                        name='position'
                        value={formData.position}
                        onChange={handleSelectChange}
                        required
                    >
                        <option value='' disabled selected hidden>
                            선택해주세요
                        </option>
                        <option value='스타트업 대표'>스타트업 대표</option>
                        <option value='스타트업 종사자'>스타트업 종사자</option>
                        <option value='대기업 종사자'>대기업 종사자</option>
                        <option value='예비창업가'>예비창업가</option>
                        <option value='기타'>기타</option>
                    </select>
                </StSelect>
                <StSelect>
                    <label htmlFor='sector'>섹터 *</label>
                    <select
                        id='sector'
                        name='sector'
                        value={formData.sector}
                        onChange={handleSelectChange}
                        required
                    >
                        <option value='' disabled selected hidden>
                            선택해주세요
                        </option>
                        <option value='AR/VR'>AR/VR</option>
                        <option value='핀테크'>핀테크</option>
                        <option value='게임'>게임</option>
                        <option value='SaaS'>SaaS</option>
                        <option value='에듀테크'>에듀테크</option>
                        <option value='로보틱스'>로보틱스</option>
                        <option value='AI'>AI</option>
                        <option value='프롭테크'>프롭테크</option>
                        <option value='블록체인'>블록체인</option>
                        <option value='소셜미디어'>소셜미디어</option>
                        <option value='커뮤니티'>커뮤니티</option>
                        <option value='여행/레저'>여행/레저</option>
                        <option value='시니어'>시니어</option>
                        <option value='환경/ESG'>환경/ESG</option>
                        <option value='제조/하드웨어'>제조/하드웨어</option>
                        <option value='커머스'>커머스</option>
                        <option value='콘텐츠'>콘텐츠</option>
                        <option value='애그리 테크'>애그리 테크</option>
                        <option value='웰니스'>웰니스</option>
                        <option value='펫테크'>펫테크</option>
                        <option value='라이프 스타일'>라이프 스타일</option>
                        <option value='헬스케어/바이오'>헬스케어/바이오</option>
                        <option value='WEB 3.0'>WEB 3.0</option>
                        <option value='기타'>기타</option>
                    </select>
                </StSelect>
                <StSelect>
                    <label htmlFor='round'>현재 투자 단계 *</label>
                    <select
                        id='round'
                        name='round'
                        value={formData.round}
                        onChange={handleSelectChange}
                        required
                    >
                        <option value='' disabled selected hidden>
                            선택해주세요
                        </option>
                        <option value='연관 없음'>연관 없음</option>
                        <option value='투자유치 전'>투자유치 전</option>
                        <option value='pre-Seed'>pre-Seed</option>
                        <option value='Seed'>Seed</option>
                        <option value='pre-A'>pre-A</option>
                        <option value='Series-A'>Series-A</option>
                        <option value='Series-B'>Series-B</option>
                        <option value='Series-C'>Series-C</option>
                        <option value='pre-IPO'>pre-IPO</option>
                        <option value='IPO'>IPO</option>
                    </select>
                </StSelect>
                <StSelect>
                    <label htmlFor='funnels'>
                        어떻게 ‘Seek’을 알게되셨나요? *
                    </label>
                    <select
                        id='funnels'
                        name='funnels'
                        value={formData.funnels}
                        onChange={handleSelectChange}
                        required
                    >
                        <option value='' disabled selected hidden>
                            선택해주세요
                        </option>
                        <option value='카카오톡 오픈채팅방'>
                            카카오톡 오픈채팅방
                        </option>
                        <option value='지인 추천'>지인 추천</option>
                        <option value='디스콰이엇'>디스콰이엇</option>
                        <option value='이오플래닛'>이오플래닛</option>
                        <option value='검색'>검색</option>
                        <option value='광고'>광고</option>
                        <option value='기타'>기타</option>
                    </select>
                </StSelect>
                <StFooter>
                    <StStartButton disabled={isNotValid} onClick={handleSubmit}>
                        SEEK 시작하기
                    </StStartButton>
                </StFooter>
            </StFounderOnboardingPage>
        </StContainer>
    );
}

const StContainer = styled.div`
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    height: 100%;
`;

const StFounderOnboardingPage = styled.form`
    margin-top: 100px;
    margin-bottom: 100px;
    width: 70%;
    min-height: 100px;
    border-radius: 20px;
    padding: 86px 10px 60px 10px;
    border: 1px solid #c5c5c5;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;

    @media (max-width: 930px) {
        width: 90%;
    }
`;

const StTitle = styled.div`
    color: #121212;
    font-size: 25px;
    font-weight: 500;
    margin-bottom: 30px;

    ${media.mobile`
    font-size: 20px;
    `}
`;

const StSelect = styled.div`
    display: flex;
    width: 60%;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 25px;

    label {
        font-size: 18px;
        font-weight: 700;
    }

    select {
        padding: 20px;
        font-size: 15px;
        font-weight: 300;
        height: 60px;
        border-radius: 8px;
        border: 0.5px solid #828282;
        box-shadow: 0px 1.5px 0px 0px rgba(0, 0, 0, 0.2);
        cursor: pointer;

        &:hover {
            box-shadow: 0px 2.5px 3px 0px rgba(0, 0, 0, 0.2);
        }

        &:focus {
            outline: 3px solid #bababa;
        }
    }

    ${media.tablet`
        width: 80%;
        `}
    ${media.mobile`
        width: 90%;
        `}
`;
const StFooter = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 60%;

    ${media.tablet`
        width: 80%;
        `}
    ${media.mobile`
        width: 90%;
        `}
`;

const StStartButton = styled.button`
    cursor: pointer;
    background-color: #121212;
    padding: 13px;
    font-size: 15px;
    color: white;
    border: none;
    border-radius: 30px;
    width: 100%;
    min-width: 310px;

    &:hover {
        opacity: 0.7;
    }

    &:disabled {
        cursor: default;
        background-color: #f1f1f1;
        color: #828282;

        &:hover {
            opacity: 1;
        }
    }
`;
