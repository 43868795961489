import React, { useState, ChangeEventHandler } from 'react';
import styled from 'styled-components';
import VCCard from './components/VCCard';
import Input from './components/Input';
import SendButton from '../DetailPage/components/SendButton';
import { Theme } from '../../styles/theme';
import { media } from '../../styles/MediaQueries';
import { useNavigate, useParams } from 'react-router-dom';
import { getUploadAndDownloadUrl, postProposal } from '../../api/proposalApi';
import { toast } from 'react-toastify';
import axios, { AxiosError } from 'axios';

export default function SendMailPage() {
    const { cardId } = useParams();
    const navigate = useNavigate();
    const [isSending, setIsSending] = useState(false);
    const [selectedFileName, setSelectedFileName] = useState<string | null>(
        null
    );
    const [data, setData] = useState<any>({
        cardId,
        name: '',
        email: '',
        companyName: '',
        serviceName: '',
        serviceIntroduction: '',
        link: '',
        investmentRound: '투자유치 전',
        investedCompany: '',
        content: '',
        fileUrl: '',
    });

    const handleChange: ChangeEventHandler<
        HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    > = (e) => {
        const { name, value, type } = e.target;

        if (type === 'file') {
            // 'files' 속성에 안전하게 접근하기 위해 타입 가드를 사용합니다.
            const input = e.target as HTMLInputElement;
            const file = input.files ? input.files[0] : null;

            if (file && file.type !== 'application/pdf') {
                alert('PDF 형식만 제출할 수 있어요');
                input.value = ''; // Reset file input
                setSelectedFileName(null);
                return;
            }
            setSelectedFileName(file ? file.name : null);
            // 파일 입력의 경우 별도 처리가 필요하지만, 여기서 상태 업데이트는 하지 않습니다.
        } else {
            // 파일 입력이 아닌 경우 상태를 업데이트합니다.
            setData((prev: any) => ({
                ...prev,
                [name]: value,
            }));
        }
    };

    const handleFileClick = () => {
        document.getElementById('file')!.click();
    };

    const handleSelectChange: ChangeEventHandler<HTMLSelectElement> = (e) => {
        setData((prev: any) => ({
            ...prev,
            investmentRound: e.target.value,
        }));
    };

    const handleFileUploadAndSubmit = async () => {
        const fileInput = document.getElementById('file') as HTMLInputElement;
        if (fileInput && fileInput.files) {
            const file = fileInput.files[0];
            try {
                const { uploadUrl, downloadUrl } =
                    await getUploadAndDownloadUrl();

                await axios.put(uploadUrl, file, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });

                const proposalData = { ...data, fileUrl: downloadUrl };
                await postProposal(proposalData);
                navigate(-1);
                toast.success('검토 요청을 성공적으로 전달했어요!');
            } catch (error) {
                console.error('제안 전송에 실패했습니다.', error);
                alert('검토 요청 전송에 실패했습니다.');
            }
        } else {
            alert('파일을 선택해주세요.');
        }
    };

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        setIsSending(true);

        await handleFileUploadAndSubmit();

        setIsSending(false);
    };

    // console.log(formData);
    return (
        <StSendMailPage onSubmit={handleSubmit}>
            <VCCard cardId={cardId} />
            <StRow>
                <Input
                    label='창업자 이름'
                    type='text'
                    name='name'
                    value={data.name}
                    onChange={handleChange}
                    required
                />
                <Input
                    label='이메일'
                    type='email'
                    name='email'
                    value={data.email}
                    onChange={handleChange}
                    required
                />
            </StRow>
            <StRow>
                <Input
                    label='회사명'
                    type='text'
                    name='companyName'
                    value={data.companyName}
                    onChange={handleChange}
                    required
                />
                <Input
                    label='주요 서비스/제품명'
                    type='text'
                    name='serviceName'
                    value={data.serviceName}
                    onChange={handleChange}
                    required
                />
            </StRow>
            <StRow>
                <Input
                    label='서비스 한줄 소개'
                    type='text'
                    name='serviceIntroduction'
                    value={data.serviceIntroduction}
                    onChange={handleChange}
                    maxLength={20}
                    required
                    placeholder='20자 이내로 입력해주세요'
                />
                <Input
                    label='웹사이트 주소'
                    type='text'
                    name='link'
                    value={data.link}
                    onChange={handleChange}
                />
            </StRow>
            <StSelect>
                <label htmlFor='investmentRound'>투자 라운드 *</label>
                <select
                    id='investmentRound'
                    name='investmentRound'
                    value={data.investmentRound}
                    onChange={handleSelectChange}
                    required
                >
                    <option value='투자유치 전'>투자유치 전</option>
                    <option value='엔젤 투자'>엔젤 투자</option>
                    <option value='pre-Seed'>pre-Seed</option>
                    <option value='Seed'>Seed</option>
                    <option value='pre-A'>pre-A</option>
                    <option value='Series-A'>Series-A</option>
                    <option value='Series-B'>Series-B</option>
                    <option value='Series-C'>Series-C</option>
                    <option value='pre-IPO'>pre-IPO</option>
                    <option value='IPO'>IPO</option>
                </select>
            </StSelect>
            <StTextArea>
                <StLabel>
                    <label htmlFor='investedCompany'>기투자사 리스트</label>
                    <span>(선택)</span>
                </StLabel>
                <p>투자받은 곳이 있다면 작성해주시길 바랍니다.</p>
                <textarea
                    id='investedCompany'
                    name='investedCompany'
                    value={data.investedCompany}
                    onChange={handleChange}
                ></textarea>
            </StTextArea>
            <StContent>
                <label htmlFor='content'>투자제안 개요 *</label>
                <textarea
                    id='content'
                    name='content'
                    value={data.content}
                    onChange={handleChange}
                    required
                ></textarea>
            </StContent>
            <StFileInput>
                <label htmlFor='file'>회사소개서 혹은 IR 자료 *</label>
                <p>50MB 이내의 PDF 형식으로 제출 부탁 드립니다.</p>
                <div onClick={handleFileClick} className='custom-file-input'>
                    {selectedFileName || '파일을 선택해주세요'}
                </div>
                <input
                    type='file'
                    id='file'
                    name='file'
                    accept='.pdf'
                    style={{ display: 'none' }}
                    onChange={handleChange}
                    required
                />
            </StFileInput>
            <SendButton type='submit' isSending={isSending} />
        </StSendMailPage>
    );
}

const StSendMailPage = styled.form`
    margin: 40px 0px 100px 0px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
`;

const StRow = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    gap: 15px;

    ${media.tablet`
    align-items: center;
        flex-direction: column;
        `}
`;

const StTextArea = styled.div`
    display: flex;
    width: 60%;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 25px;

    label {
        font-size: 18px;
        font-weight: 700;
    }

    textarea {
        padding: 20px;
        font-size: 15px;
        font-weight: 300;
        height: 80px;
        border-radius: 8px;
        resize: none;
        outline: none;
        border: 0.5px solid #828282;
        box-shadow: 0px 1.5px 0px 0px rgba(0, 0, 0, 0.2);

        &:hover {
            box-shadow: 0px 2.5px 3px 0px rgba(0, 0, 0, 0.2);
        }

        &:focus {
            outline: 3px solid #bababa;
        }
    }

    ${media.tablet`
        width: 80%;
        `}
    ${media.mobile`
        width: 90%;
        `}
`;

const StLabel = styled.div`
    display: flex;
    gap: 5px;

    span {
        font-weight: 200;
    }
`;

const StContent = styled.div`
    display: flex;
    width: 60%;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 25px;

    label {
        font-size: 18px;
        font-weight: 700;
    }

    textarea {
        padding: 20px;
        font-size: 15px;
        font-weight: 300;
        height: 140px;
        border-radius: 8px;
        resize: none;
        outline: none;
        border: 0.5px solid #828282;
        box-shadow: 0px 1.5px 0px 0px rgba(0, 0, 0, 0.2);

        &:hover {
            box-shadow: 0px 2.5px 3px 0px rgba(0, 0, 0, 0.2);
        }
        &:focus {
            outline: 3px solid #bababa;
        }
    }

    ${media.tablet`
        width: 80%;
        `}
    ${media.mobile`
        width: 90%;
        `}
`;

const StFileInput = styled.div`
    display: flex;
    width: 60%;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 25px;

    label {
        font-size: 18px;
        font-weight: 700;
    }

    .custom-file-input {
        cursor: pointer;
        background-color: white;
        padding: 20px;
        font-size: 15px;
        font-weight: 300;
        height: 80px;
        border: 2px dashed ${Theme.colors.gray2};
        border-radius: 8px;
        box-shadow: 0px 1.5px 0px 0px rgba(0, 0, 0, 0.2);
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
            box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.4);
        }
    }

    input[type='file'] {
        display: none;
    }

    ${media.tablet`
        width: 80%;
        `}
    ${media.mobile`
        width: 90%;
        `}
`;

const StSelect = styled.div`
    display: flex;
    width: 60%;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 25px;

    label {
        font-size: 18px;
        font-weight: 700;
    }

    select {
        padding: 20px;
        font-size: 15px;
        font-weight: 300;
        height: 60px;
        border-radius: 8px;
        border: 0.5px solid #828282;
        box-shadow: 0px 1.5px 0px 0px rgba(0, 0, 0, 0.2);
        cursor: pointer;

        &:hover {
            box-shadow: 0px 2.5px 3px 0px rgba(0, 0, 0, 0.2);
        }

        &:focus {
            outline: 3px solid #bababa;
        }
    }

    ${media.tablet`
        width: 80%;
        `}
    ${media.mobile`
        width: 90%;
        `}
`;
