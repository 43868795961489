import axios from 'axios';
import { getJwt, handleLogout, saveJwt } from '../shared/TokenUtils/TokenUtils';

export const api = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}`,
    headers: {
        'Content-Type': 'application/json',
    },
});

api.interceptors.request.use(
    (config) => {
        const token = getJwt();
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

api.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;
        // console.log(error);

        // JWT 만료 시 자동 토큰 갱신
        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            const refreshToken = localStorage.getItem('refreshToken');
            // console.log(refreshToken);

            try {
                // `/auth/refresh-token` 엔드포인트로 토큰 갱신 요청
                const response = await axios.post(
                    `${process.env.REACT_APP_API_URL}/auth/refresh-token`,
                    { refreshToken }
                );
                // console.log(response);
                const jwt = response.data.value.jwt;

                // 새 토큰을 로컬 스토리지에 저장
                saveJwt(jwt);

                // 기존 요청의 헤더 업데이트
                api.defaults.headers.common['Authorization'] = `Bearer ${jwt}`;
                originalRequest.headers['Authorization'] = `Bearer ${jwt}`;

                // 기존 요청 재시도
                return api(originalRequest);
            } catch (refreshError) {
                handleLogout();
                return Promise.reject(refreshError);
            }
        }

        return Promise.reject(error);
    }
);
