import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { getCardDetail, patchCard } from '../../api/cardApi';
import { IoArrowBackCircleOutline } from 'react-icons/io5';
import { media } from '../../styles/MediaQueries';
import { Theme } from '../../styles/theme';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { CreateCardFormData } from '../CreateCardPage/CreateCardPage';
import CompletedCardModal from '../../shared/modals/CompletedCardModal';

export default function EditCardPage() {
    const navigate = useNavigate();
    const { cardId } = useParams<{ cardId: string }>();
    const [selectedType, setSelectedType] = useState<string | null>(null);
    const [selectedRounds, setSelectedRounds] = useState<string[]>([]);
    const [requirements, setRequirements] = useState<string[]>(['']);
    const [formData, setFormData] = useState<CreateCardFormData>({
        houseId: '',
        title: '',
        purpose: '',
        messageToEntrepreneur: '',
        tag: '',
        investmentRounds: [''],
        minInvestAmount: null,
        maxInvestAmount: null,
        investmentRequirements: [''],
    });
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    const handleCardClick = (type: string) => {
        setSelectedType(type);
        if (type === 'VC') {
            const { houseId, ...rest } = formData;
            setFormData({ ...rest });
        } else if (type === 'Angel') {
            setFormData({ ...formData, houseId: '6566f238d1f60098495245fd' });
        }
    };

    const handleRoundClick = (round: string) => {
        setSelectedRounds((prevRounds) => {
            if (prevRounds.includes(round)) {
                return prevRounds.filter((item) => item !== round);
            } else {
                return [...prevRounds, round];
            }
        });
    };

    useEffect(() => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            investmentRounds: selectedRounds,
        }));
    }, [selectedRounds]);

    const addRequirement = () => {
        setRequirements((prev) => [...prev, '']);
    };

    const handleRequirementChange = (value: string, index: number) => {
        setRequirements((prev) => {
            const newRequirements = [...prev];
            newRequirements[index] = value;
            return newRequirements;
        });
    };

    useEffect(() => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            investmentRequirements: requirements.filter((req) => req !== ''),
        }));
    }, [requirements]);

    const updateFormData = (field: keyof CreateCardFormData, value: string) => {
        let updatedValue: string | number | null = value;

        if (
            (field === 'minInvestAmount' || field === 'maxInvestAmount') &&
            value !== ''
        ) {
            updatedValue = parseFloat(value);
        }

        setFormData((prevFormData) => ({
            ...prevFormData,
            [field]: updatedValue,
        }));
    };

    const isFormValid = () => {
        return (
            formData.houseId !== '' &&
            formData.title.trim() !== '' &&
            formData.purpose.trim() !== '' &&
            formData.messageToEntrepreneur.trim() !== '' &&
            formData.tag.trim() !== '' &&
            formData.investmentRounds.length > 0 &&
            formData.minInvestAmount != null &&
            formData.minInvestAmount > 0 &&
            formData.maxInvestAmount != null &&
            formData.maxInvestAmount > 0 &&
            formData.investmentRequirements.length > 0 &&
            formData.investmentRequirements.every((req) => req.trim() !== '')
        );
    };

    const mutation = useMutation({
        mutationFn: (newData: CreateCardFormData) =>
            patchCard(cardId!, newData),
        onSuccess: () => {
            setIsModalOpen(true);
        },
        onError: (error: any) => {},
    });

    const handleSubmit = () => {
        if (isFormValid()) {
            mutation.mutate(formData); // formData를 mutate에 전달
        } else {
            toast.info('모든 필드를 올바르게 채워주세요.');
        }
    };

    const {
        data: cardDetail,
        isLoading,
        isError,
        error,
    } = useQuery({
        queryKey: ['getCardDetail', cardId],
        queryFn: () => getCardDetail(cardId),
        enabled: !!cardId,
    });

    useEffect(() => {
        if (cardDetail) {
            setFormData({
                houseId: cardDetail.house.id,
                title: cardDetail.title,
                purpose: cardDetail.purpose,
                messageToEntrepreneur: cardDetail.messageToEntrepreneur,
                tag: cardDetail.tag,
                investmentRounds: cardDetail.investmentRounds,
                minInvestAmount: cardDetail.minInvestAmount,
                maxInvestAmount: cardDetail.maxInvestAmount,
                investmentRequirements: cardDetail.investmentRequirements,
            });
            setSelectedRounds(cardDetail.investmentRounds);
            setRequirements(cardDetail.investmentRequirements);
            setSelectedType(
                cardDetail.house.id === '6566f238d1f60098495245fd'
                    ? 'Angel'
                    : 'VC'
            );
        }
    }, [cardDetail]);

    if (isLoading) {
        return <Spinner />;
    }

    if (isError) {
        return <div>Error: {error?.message}</div>;
    }

    // console.log(formData);

    return (
        <StEditCardPage>
            <StLineContainer>
                <StIcon onClick={() => navigate(-1)}>
                    <IoArrowBackCircleOutline size={40} color='#adadad' />
                </StIcon>
                <StTitleContainer>
                    <h1>카드 수정하기</h1>
                    <div>찾고있는 스타트업/창업가와 가장 빠르게 연결돼요!</div>
                </StTitleContainer>
                <StChooseType>
                    <h3>카드 유형 선택 *</h3>
                    <StCardContainer>
                        <StCard
                            onClick={() => handleCardClick('VC')}
                            style={{
                                backgroundColor:
                                    selectedType === 'VC' ? '#fff48e' : 'white',
                            }}
                        >
                            <StEmoji>🙋</StEmoji>
                            <StCardTitle>
                                VC 심사역으로 카드를 생성할래요
                            </StCardTitle>
                            <StCardContent>
                                <p>투자자님의 하우스 이름과 로고가</p>
                                <p>카드에 노출됩니다.</p>
                            </StCardContent>
                        </StCard>
                        <StCard
                            onClick={() => handleCardClick('Angel')}
                            style={{
                                backgroundColor:
                                    selectedType === 'Angel'
                                        ? '#fff48e'
                                        : 'white',
                            }}
                        >
                            <StEmoji>👼</StEmoji>
                            <StCardTitle>
                                개인 엔젤투자자로 카드를 생성할래요
                            </StCardTitle>
                            <StCardContent>
                                <p>하우스 이름과 로고 대신</p>
                                <p>
                                    '엔젤투자자'와 이모지가 카드에 노출됩니다.
                                </p>
                            </StCardContent>
                        </StCard>
                    </StCardContainer>
                </StChooseType>
                <StOneLiner>
                    <h3>원라이너 (45자 이내) *</h3>
                    <p>
                        찾고자 하는 스타트업 혹은 창업가를 한 줄로 작성해
                        주세요.
                    </p>
                    <textarea
                        value={formData.title}
                        onChange={(e) =>
                            updateFormData('title', e.target.value)
                        }
                        placeholder='ex. 액티브 시니어의 즐거운 소비 경험을 설계하는 창업가를 찾습니다'
                        maxLength={45}
                        required
                    ></textarea>
                </StOneLiner>
                <StPurpose>
                    <h3>왜 이런 스타트업/창업가를 찾고 계신가요? *</h3>
                    <textarea
                        value={formData.purpose}
                        onChange={(e) =>
                            updateFormData('purpose', e.target.value)
                        }
                        placeholder='ex. 액티브 시니어들의 취향/취미 소비에 대한 니즈를 적절히 충족해 주는 서비스/플랫폼이 등장한다면, 중장기적으로 실버 시장의 게임 체인저가 될 것이라 생각합니다.'
                        required
                    ></textarea>
                </StPurpose>
                <StMessage>
                    <h3>
                        이 문제를 해결하고 있는 스타트업이나 찾고 있는 조건에
                        부합하는 창업가에게 한마디 *
                    </h3>
                    <textarea
                        value={formData.messageToEntrepreneur}
                        onChange={(e) =>
                            updateFormData(
                                'messageToEntrepreneur',
                                e.target.value
                            )
                        }
                        placeholder='ex. 아직 문제를 뚜렷하게 정의하지 못하셨더라도 좋습니다. 이 시장에 대해서 함께 고민하는 파트너가 되어드리고 싶습니다!'
                        required
                    ></textarea>
                </StMessage>
                <StSector>
                    <label htmlFor='sector'>섹터 *</label>
                    <select
                        id='sector'
                        name='sector'
                        value={formData.tag}
                        onChange={(e) => updateFormData('tag', e.target.value)}
                        required
                    >
                        <option value='' disabled hidden>
                            찾고자 하는 스타트업/창업가의 섹터를 정의해 주세요.
                        </option>
                        <option value='AR/VR'>AR/VR</option>
                        <option value='핀테크'>핀테크</option>
                        <option value='게임'>게임</option>
                        <option value='SaaS'>SaaS</option>
                        <option value='에듀테크'>에듀테크</option>
                        <option value='로보틱스'>로보틱스</option>
                        <option value='AI'>AI</option>
                        <option value='프롭테크'>프롭테크</option>
                        <option value='블록체인'>블록체인</option>
                        <option value='소셜미디어'>소셜미디어</option>
                        <option value='커뮤니티'>커뮤니티</option>
                        <option value='여행/레저'>여행/레저</option>
                        <option value='시니어'>시니어</option>
                        <option value='환경/ESG'>환경/ESG</option>
                        <option value='제조/하드웨어'>제조/하드웨어</option>
                        <option value='커머스'>커머스</option>
                        <option value='콘텐츠'>콘텐츠</option>
                        <option value='애그리 테크'>애그리 테크</option>
                        <option value='웰니스'>웰니스</option>
                        <option value='펫테크'>펫테크</option>
                        <option value='라이프 스타일'>라이프 스타일</option>
                        <option value='헬스케어/바이오'>헬스케어/바이오</option>
                        <option value='WEB 3.0'>WEB 3.0</option>
                        <option value='기타'>기타</option>
                    </select>
                </StSector>
                <StRounds>
                    <h3>투자 검토 희망 단계 * (다중 선택 가능)</h3>
                    <StRoundsContainer>
                        {[
                            '투자유치 전',
                            'pre-Seed',
                            'Seed',
                            'pre-A',
                            'Series-A',
                            'Series-B',
                            'Series-C',
                            'pre-IPO',
                            'IPO',
                        ].map((round) => (
                            <StRound
                                key={round}
                                onClick={() => handleRoundClick(round)}
                                style={{
                                    backgroundColor: selectedRounds.includes(
                                        round
                                    )
                                        ? '#fff48e'
                                        : '#ffffff',
                                    color: selectedRounds.includes(round)
                                        ? 'black'
                                        : '#8c8c8c',
                                }}
                            >
                                {round}
                            </StRound>
                        ))}
                    </StRoundsContainer>
                </StRounds>
                <StAmounts>
                    <StMin>
                        <h3>최소 투자 금액 *</h3>
                        <StMinAmount>
                            <input
                                type='text'
                                placeholder='숫자만 입력해주세요'
                                value={formData.minInvestAmount ?? ''}
                                onChange={(e) =>
                                    updateFormData(
                                        'minInvestAmount',
                                        e.target.value
                                    )
                                }
                                required
                            ></input>
                            <span>억 원</span>
                        </StMinAmount>
                    </StMin>
                    <StMax>
                        <h3>최대 투자 금액 *</h3>
                        <StMaxAmount>
                            <input
                                type='text'
                                placeholder='숫자만 입력해주세요'
                                value={formData.maxInvestAmount ?? ''}
                                onChange={(e) =>
                                    updateFormData(
                                        'maxInvestAmount',
                                        e.target.value
                                    )
                                }
                                required
                            ></input>
                            <span>억 원</span>
                        </StMaxAmount>
                    </StMax>
                </StAmounts>
                <StRequirements>
                    <h3>투자 우대 조건 *</h3>
                    <h4>
                        '이런 조건을 만족하면 좋겠다!' 하는 스타트업/창업가의
                        특징을 알려주세요.(3개까지 입력할 수 있습니다.)
                    </h4>

                    <p>
                        - 주목적 펀드에 집행 가능한 스타트업을 찾고 있는 경우
                        (ex. 법인 연차, 지역 소재지 등)
                    </p>
                    <p>
                        - 특정 조건을 원하는 경우 (ex. 북미 시장의 경험이 있는
                        경우)
                    </p>
                    <p>
                        - 요청사항이 있는 경우 (ex. 검토 요청 시 자세한 팀
                        소개가 필요, 매출이 발생하고 있는 팀 우대, 타
                        창업가로부터 추천을 받은 팀 우대)
                    </p>
                    <StInputContainer>
                        <StRequirements>
                            {requirements.map((requirement, index) => (
                                <StRequirement
                                    key={index}
                                    value={requirement}
                                    onChange={(e) =>
                                        handleRequirementChange(
                                            e.target.value,
                                            index
                                        )
                                    }
                                    placeholder='ex. 법인 설립 3년 미만 우대'
                                />
                            ))}

                            {requirements.length < 3 && (
                                <StPlus onClick={addRequirement}>+</StPlus>
                            )}
                        </StRequirements>
                    </StInputContainer>
                </StRequirements>
                <StButtonContainer>
                    <StCreateButton
                        disabled={!isFormValid()}
                        onClick={handleSubmit}
                    >
                        수정 완료하기
                    </StCreateButton>
                </StButtonContainer>
            </StLineContainer>

            {isModalOpen && <CompletedCardModal type='수정' />}
        </StEditCardPage>
    );
}

const StEditCardPage = styled.div`
    display: flex;
    justify-content: center;
`;

const StLineContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 80%;
    margin-top: 50px;
    margin-bottom: 50px;
    border: 1.5px solid #c5c5c5;
    border-radius: 20px;
    padding: 70px 100px;
    position: relative;
    gap: 50px;

    h3 {
        display: inline-block;
        font-size: 22px;
        font-weight: 500;
        background-color: #fff48e;
        padding: 5px 10px;
        margin-bottom: 20px;
    }

    textarea {
        padding: 20px;
        font-size: 15px;
        font-weight: 300;
        width: 100%;
        height: 140px;
        border-radius: 8px;
        resize: none;
        outline: none;
        border: 0.5px solid #828282;
        box-shadow: 0px 1.5px 0px 0px rgba(0, 0, 0, 0.2);
        margin-top: 15px;

        &:hover {
            box-shadow: 0px 2.5px 3px 0px rgba(0, 0, 0, 0.2);
        }
        &:focus {
            outline: 3px solid #bababa;
        }
    }

    ${media.tablet`
        width: 90%;
        padding: 70px 50px;
        `}

    ${media.mobile`
        width: 100%;
        padding: 70px 10px;
        `}
`;

const StIcon = styled.div`
    cursor: pointer;
    position: absolute;
    top: 30px;
    left: 30px;

    &:hover {
        opacity: 0.7;
    }
`;

const StTitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    color: #121212;

    h1 {
        margin-top: 20px;
        font-size: 28px;
        font-weight: 600;
    }
`;

const StChooseType = styled.div``;

const StCardContainer = styled.div`
    display: flex;
    gap: 20px;

    @media (max-width: 1010px) {
        flex-direction: column;
    }
`;

const StCard = styled.div`
    display: flex;
    cursor: pointer;
    flex-direction: column;
    gap: 15px;
    border: 1.5px solid #c5c5c5;
    border-radius: 20px;
    background-color: white;
    padding: 30px;
    min-width: 340px;
    max-width: 340px;
`;

const StEmoji = styled.div`
    font-size: 25px;
    margin-bottom: 15px;
`;

const StCardTitle = styled.div`
    font-size: 18px;
    font-weight: 550;
`;

const StCardContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
`;

const StOneLiner = styled.div`
    textarea {
        height: 100px;
    }

    p {
        padding-left: 5px;
    }
`;

const StPurpose = styled.div``;

const StMessage = styled.div``;

const StSector = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;

    label {
        width: 80px;
        font-size: 22px;
        font-weight: 500;
        background-color: #fff48e;
        padding: 5px 10px;
        margin-bottom: 20px;
    }

    select {
        padding: 20px;
        font-size: 15px;
        font-weight: 300;
        height: 60px;
        border-radius: 8px;
        border: 0.5px solid #828282;
        box-shadow: 0px 1.5px 0px 0px rgba(0, 0, 0, 0.2);
        cursor: pointer;

        &:hover {
            box-shadow: 0px 2.5px 3px 0px rgba(0, 0, 0, 0.2);
        }

        &:focus {
            outline: 3px solid #bababa;
        }
    }
`;

const StRounds = styled.div``;

const StRoundsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
`;

const StRound = styled.div`
    border: 1px solid #c5c5c5;
    padding: 10px 15px;
    border-radius: 20px;
    color: #8c8c8c;
    background-color: #ffffff;
    white-space: nowrap;
    cursor: pointer;
`;

const StAmounts = styled.div`
    display: flex;
    width: 100%;

    span {
        font-size: 20px;
        font-weight: 500;
    }
`;

const StMin = styled.div`
    width: 50%;
`;

const StMinAmount = styled.div`
    input {
        font-size: 18px;
        border: none;
        border-bottom: 1px solid black;
        background-color: #f1f1f1;
        padding: 15px;
        width: 70%;
        margin-right: 10px;

        &::placeholder {
            font-size: 15px;
        }

        &:focus {
            border-radius: 8px;
            outline: 3px solid #bababa;
        }
    }
`;

const StMax = styled.div`
    width: 50%;
`;

const StMaxAmount = styled.div`
    input {
        font-size: 18px;
        border: none;
        border-bottom: 1px solid black;
        background-color: #f1f1f1;
        padding: 15px;
        width: 70%;
        margin-right: 10px;

        &::placeholder {
            font-size: 15px;
        }

        &:focus {
            border-radius: 8px;
            outline: 3px solid #bababa;
        }
    }
`;

const StRequirements = styled.div`
    h4 {
        color: #7c7c7c;
        margin-bottom: 10px;
    }

    p {
        margin-bottom: 5px;
    }
`;

const StInputContainer = styled.div`
    margin-top: 20px;
`;

const StRequirement = styled.input`
    margin-bottom: 10px;
    width: 100%;
    padding: 20px;
    font-size: 15px;
    font-weight: 300;
    height: 50px;
    border-radius: 8px;
    border: 0.5px solid #828282;
    box-shadow: 0px 1.5px 0px 0px rgba(0, 0, 0, 0.2);

    &:hover {
        box-shadow: 0px 2.5px 3px 0px rgba(0, 0, 0, 0.2);
    }

    &:focus {
        outline: 3px solid #bababa;
    }
`;

const StPlus = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 60px;
    padding: 5px;
    border: none;
    font-size: 30px;
    background-color: white;
    color: #ffdd00;
    border-radius: 10px;
    box-shadow: 0px 2.5px 3px 0px rgba(0, 0, 0, 0.2);
    margin-top: 20px;

    &:hover {
        opacity: 0.8;
        box-shadow: 0px 0.5px 1px 0px rgba(0, 0, 0, 0.2);
    }
`;

const StButtonContainer = styled.div`
    display: flex;
    justify-content: center;
`;

const StCreateButton = styled.button`
    cursor: pointer;
    width: 50%;
    color: white;
    border: 2px solid ${Theme.colors.black};
    background-color: #121212;
    padding: 15px;
    font-size: 18px;
    border-radius: 30px;

    transition: all 0.3s ease;

    &:hover {
        background: transparent;
        border: 2px solid ${Theme.colors.black};
        color: ${Theme.colors.black};
    }

    &:disabled {
        cursor: default;
        background-color: #f1f1f1;
        border: 1px solid #828282;
        color: #828282;
    }

    ${media.mobile`
        width: 70%;
        `}
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const Spinner = styled.div`
    border: 5px solid #f3f3f3;
    border-top: 5px solid #888888;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    animation: ${spin} 2s linear infinite;
    margin: 300px auto;
`;
