import React from 'react';
import styled from 'styled-components';
import { Theme } from '../../../../styles/theme';
import { media } from '../../../../styles/MediaQueries';

import { AiOutlineArrowRight } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';

export default function Banner() {
    const navigate = useNavigate();
    return (
        <StBannerContainer>
            <StContentContainer>
                <p>보고 싶은 카드를 아직 못찾으셨나요?</p>
                <StContactButton onClick={() => navigate('/login')}>
                    <div>새 카드 알림 신청하기</div>
                    <AiOutlineArrowRight />
                </StContactButton>
            </StContentContainer>
        </StBannerContainer>
    );
}

const StBannerContainer = styled.div`
    position: fixed; // 고정 위치
    bottom: 0; // 화면 하단에 위치
    left: 0; // 왼쪽 정렬
    right: 0; // 오른쪽 정렬
    display: flex;
    height: 140px;
    padding: 35px 210px;
    justify-content: center;
    align-items: center;
    border-radius: 28px 28px 0px 0px;
    background-color: ${Theme.colors.black};
    color: white;

    @media (max-width: 1185px) {
        padding: 0px 155px;
    }

    ${media.tablet`
        padding: 0px 110px;
    `}

    @media (max-width: 840px) {
        padding: 0px 75px;
    }

    ${media.mobile`
        padding: 16px 20px;
    `}
`;

const StContentContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1240px;
    width: 100%;
    p {
        font-size: 32px;
        font-weight: 600;
        line-height: 140%;
    }

    span {
        font-weight: 900;
    }

    @media (max-width: 1185px) {
        p {
            font-size: 29px;
        }
    }

    ${media.tablet`
            gap:12px;     

        p {
            font-size: 28px;
        }
    `}

    @media (max-width: 840px) {
        p {
            font-size: 22px;
        }
    }

    ${media.mobile`
        
        flex-direction: column;
        gap:12px;
        text-align: center;

        p {
            font-size: 20px;
        }
    `}
`;

const StContactButton = styled.div`
    display: flex;
    height: 60px;
    padding: 5px 26px 5px 34px;
    justify-content: center;
    align-items: center;
    gap: 8px;

    border-radius: 100px;
    border: 2px solid #fff;

    color: #fff;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
        color: ${Theme.colors.black};
        border: 2px solid ${Theme.colors.black};
        background-color: ${Theme.colors.white};
    }

    ${media.tablet`
    padding: 5px 20px 5px 28px;
    height: 50px;
       font-size: 15px;
    `}

    ${media.mobile`
    height: 40px;
       font-size: 14px;
    `}
`;
