import React, { useEffect, useRef, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { media } from '../../../../styles/MediaQueries';
import { getUserInfo, patchUserInfo } from '../../../../api/userApi';
import investorDefault from '../../../../assets/investor-default.png';
import DeleteUserModal from '../components/DeleteUserModal';

import { toast } from 'react-toastify';
import { useQuery } from '@tanstack/react-query';

export default function Profile() {
    const [selectedPosition, setSelectedPosition] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [profileImage, setProfileImage] = useState<File | null>(null);
    const [previewImage, setPreviewImage] = useState<string | null>(null);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [isSaving, setIsSaving] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [isAgreeEmail, setIsAgreeEmail] = useState(false);

    const {
        data: user,
        isLoading,
        isError,
        error,
        refetch,
    } = useQuery({
        queryKey: ['getUserInfo'],
        queryFn: getUserInfo,
    });

    // console.log(user);

    useEffect(() => {
        if (!isLoading && user) {
            setSelectedPosition(user.position || '');
            setPhoneNumber(user.phoneNumber || '');
            setIsAgreeEmail(user.isAgreeEmail || '');
        }
    }, [isLoading, user]);

    const handleFileInputClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files ? e.target.files[0] : null;

        if (file) {
            if (!file.type.startsWith('image/')) {
                alert('이미지 파일만 업로드 가능합니다.');
                return;
            }
            if (file.size > 2 * 1024 * 1024) {
                alert('파일 크기는 2MB를 넘을 수 없습니다.');
                return;
            }

            setProfileImage(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                if (typeof reader.result === 'string') {
                    setPreviewImage(reader.result); // 문자열일 때만 상태 업데이트
                }
            };
            reader.readAsDataURL(file);
        } else {
            setProfileImage(null);
            setPreviewImage(null);
        }
    };

    const handleCheckboxChange = () => {
        setIsAgreeEmail(!isAgreeEmail);
    };

    const handleSave = async () => {
        if (!phoneNumber) {
            alert('전화번호를 입력해주세요.');
            return;
        }

        setIsSaving(true);
        const formData = new FormData();

        if (profileImage) {
            formData.append('file', profileImage);
        }

        formData.append('position', selectedPosition || '');
        formData.append('phoneNumber', phoneNumber || '');
        formData.append('isAgreeEmail', isAgreeEmail.toString());

        try {
            await patchUserInfo(formData);
            refetch();
            toast.success('프로필을 저장했어요!');
        } catch (error) {
        } finally {
            setIsSaving(false);
        }
    };

    const handleWithdrawClick = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    if (isLoading) {
        return <Spinner />;
    }

    if (isError) {
        return <span>Error: {error.message}</span>;
    }

    // console.log(user);

    return (
        <StProfile>
            <StHeader>프로필</StHeader>
            <StImgSection>
                <StTitle>프로필 이미지 *</StTitle>
                <StDescription>
                    프로필 이미지는 '우리 회사 검토 요청하기' 페이지에서
                    노출되며, 신뢰도가 높아집니다.
                </StDescription>
                <StImgContainer>
                    <StImg>
                        <img
                            src={
                                previewImage ||
                                user?.profileUrl ||
                                investorDefault
                            }
                            alt='Profile'
                        />
                    </StImg>
                    <StTextContainer>
                        <div>추천 사이즈: 360 * 360</div>
                        <div>JPG, PNG 최대 2MB</div>
                        <input
                            type='file'
                            ref={fileInputRef}
                            onChange={handleImageChange}
                            style={{ display: 'none' }}
                        />
                        <p onClick={handleFileInputClick}>이미지 변경하기</p>
                    </StTextContainer>
                </StImgContainer>
            </StImgSection>
            <StInformationSection>
                <StInfo>
                    <StKey>이름</StKey>
                    <StValue>{user?.name}</StValue>
                </StInfo>
                <StInfo>
                    <StKey>하우스명</StKey>
                    <StValue>{user?.house.name}</StValue>
                </StInfo>
                <StInfo>
                    <StKey>이메일</StKey>
                    <StValue>{user?.email}</StValue>
                </StInfo>
                <StInfo>
                    <StKey>직책</StKey>
                    <select
                        value={selectedPosition}
                        onChange={(e) => setSelectedPosition(e.target.value)}
                    >
                        <option value='투자 심사역'>투자 심사역</option>
                        <option value='책임 심사역'>책임 심사역</option>
                        <option value='수석 심사역'>수석 심사역</option>
                        <option value='수석 팀장'>수석 팀장</option>
                        <option value='VP'>VP</option>
                        <option value='파트너'>파트너</option>
                        <option value='대표'>대표</option>
                        <option value='기타'>기타</option>
                    </select>
                </StInfo>
                <StInfo>
                    <StKey>전화번호</StKey>
                    <StInput
                        type='text'
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                    />
                </StInfo>
                <StInfo>
                    <StKey>뉴스레터 구독</StKey>
                    <StInput
                        type='checkbox'
                        checked={isAgreeEmail}
                        onChange={handleCheckboxChange}
                    />
                </StInfo>
            </StInformationSection>
            <StFooter>
                <StSave onClick={handleSave}>
                    {isSaving ? <SmallSpinner /> : '저장하기'}
                </StSave>
                {/* <StWithdraw onClick={handleWithdrawClick}>탈퇴하기</StWithdraw> */}
            </StFooter>
            {showModal && (
                <DeleteUserModal onClose={handleCloseModal} type='INVESTOR' />
            )}
        </StProfile>
    );
}

const StProfile = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-left: 20px;
    gap: 20px;

    ${media.mobile`
    margin-left: 0px;
    `}
`;

const StHeader = styled.div`
    padding: 10px 0px 20px 0px;
    font-size: 20px;
    font-weight: 700;
    border-bottom: 1px solid #d4d4d4;
    width: 100%;
`;

const StImgSection = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 30px 20px;
    background-color: white;
    border-radius: 12px;
    gap: 20px;
`;

const StTitle = styled.div`
    font-size: 18px;
    font-weight: 550;
`;

const StDescription = styled.div``;

const StImgContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 20px;

    ${media.mobile`
        flex-direction: column;
    `}
`;

const StImg = styled.div`
    img {
        width: 160px;
        height: 160px;
        border-radius: 50%;
        object-fit: cover;
    }
`;

const StTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
    font-size: 14px;
    color: #777777;

    p {
        font-size: 15px;
        font-weight: 500;
        color: black;
        cursor: pointer;

        &:hover {
            opacity: 0.6;
        }
    }
`;

const StInformationSection = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 30px 20px;
    background-color: white;
    border-radius: 12px;
    gap: 20px;
`;

const StInfo = styled.div`
    display: flex;
    align-items: center;
    gap: 20px;

    select {
        padding: 5px;
        font-size: 15px;
        font-weight: 500;
        border-radius: 8px;
        border: 0.5px solid #828282;
        box-shadow: 0px 1.5px 0px 0px rgba(0, 0, 0, 0.2);
        cursor: pointer;

        &:hover {
            box-shadow: 0px 2.5px 3px 0px rgba(0, 0, 0, 0.2);
        }

        &:focus {
            outline: 3px solid #bababa;
        }
    }
`;

const StKey = styled.div`
    font-size: 14px;
    width: 150px;
`;

const StValue = styled.div`
    padding: 5px;
    font-weight: 500;
    font-size: 16px;
`;

const StInput = styled.input`
    padding: 5px;
    font-size: 15px;
    font-weight: 500;
    border-radius: 8px;
    border: 0.5px solid #828282;
    box-shadow: 0px 1.5px 0px 0px rgba(0, 0, 0, 0.2);

    &[type='checkbox'] {
        transform: scale(1.2);
        box-shadow: none;
    }

    &:hover {
        box-shadow: 0px 2.5px 3px 0px rgba(0, 0, 0, 0.2);
    }

    &:focus {
        outline: 3px solid #bababa;
    }
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const SmallSpinner = styled.div`
    border: 2px solid #f3f3f3;
    border-top: 2px solid #121212;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    animation: ${spin} 2s linear infinite;
`;

const StFooter = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const StSave = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 100px;
    height: 45px;
    border: 1px solid white;
    background-color: #feef68;
    border-radius: 10px;
    font-size: 15px;
    font-weight: 600;

    &:hover {
        opacity: 0.6;
    }
`;

const StWithdraw = styled.div`
    margin-right: 10px;
    color: #d4554c;
    text-decoration: underline;
    cursor: pointer;

    &:hover {
        opacity: 0.7;
    }
`;

const bigSpin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

// 스피너 스타일링
const Spinner = styled.div`
    border: 5px solid #f3f3f3;
    border-top: 5px solid #888888;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    animation: ${bigSpin} 2s linear infinite;
    margin: 300px auto;
`;
