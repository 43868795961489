import React, { ChangeEventHandler } from 'react';
import styled from 'styled-components';
import { media } from '../../../styles/MediaQueries';

interface InputProps {
    label: string;
    type: string;
    name: string;
    value: string;
    onChange: ChangeEventHandler<HTMLInputElement>;
    required?: boolean;
    maxLength?: number;
    placeholder?: string;
}

const Input: React.FC<InputProps> = ({
    label,
    type,
    name,
    value,
    onChange,
    required,
    maxLength,
    placeholder,
}) => {
    return (
        <StInput>
            <StRow>
                <label htmlFor={name}>{label}</label>
                {required ? <StRequired>*</StRequired> : <span>(선택)</span>}
            </StRow>
            <input
                type={type}
                id={name}
                name={name}
                value={value}
                onChange={onChange}
                required={required}
                maxLength={maxLength}
                placeholder={placeholder}
            />
        </StInput>
    );
};

const StInput = styled.div`
    display: flex;
    width: 29.5%;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 25px;

    label {
        font-size: 18px;
        font-weight: 700;
    }

    input {
        padding: 20px;
        font-size: 15px;
        font-weight: 300;
        height: 50px;
        border-radius: 8px;
        border: 0.5px solid #828282;
        box-shadow: 0px 1.5px 0px 0px rgba(0, 0, 0, 0.2);

        &:hover {
            box-shadow: 0px 2.5px 3px 0px rgba(0, 0, 0, 0.2);
        }

        &:focus {
            outline: 3px solid #bababa;
        }
    }

    ${media.tablet`
        width: 80%;
        `}

    ${media.mobile`
        width: 90%;
        `}
`;

const StRow = styled.div`
    display: flex;
    gap: 5px;

    span {
        font-weight: 200;
    }
`;

const StRequired = styled.div`
    display: flex;
    padding: 2px;
    font-weight: 800;
`;

export default Input;
