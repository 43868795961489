import React, { forwardRef } from 'react';
import styled, { keyframes } from 'styled-components';
import CommentInput from '../../components/CommentInput';
import GoogleProfile from '../../../../shared/GoogleProfile/GoogleProfile';
import { media } from '../../../../styles/MediaQueries';
import { useQuery } from '@tanstack/react-query';
import { getComments } from '../../../../api/cardApi';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { userState } from '../../../../shared/State/atom';
import { Comment } from '../../components/Comment';
import { CommentData } from '../../../../shared/Type/type';

export const CommentView = forwardRef<HTMLDivElement, {}>((props, ref) => {
    const { cardId } = useParams();
    const user = useRecoilValue(userState);

    const {
        data: comments,
        isPending,
        isError,
        error,
    } = useQuery<CommentData[]>({
        queryKey: ['comments', cardId],
        queryFn: () => getComments(cardId),
    });

    if (isPending) {
        return <Spinner />;
    }

    if (isError) {
        return <span>Error: {error.message}</span>;
    }

    return (
        <StCommentViewContainer ref={ref}>
            <h2>{`Comments(${comments.length})`}</h2>
            <StLine />
            <StCommentContent id='comments-container'>
                {comments.map((comment) => (
                    <Comment
                        userId={user?.id}
                        key={comment.id}
                        id={comment.id}
                        cardId={comment.cardId}
                        parentCommentId={comment.parentCommentId}
                        content={comment.content}
                        writer={comment.writer}
                        createdAt={comment.createdAt}
                    />
                ))}
            </StCommentContent>
            <StLine />
            <StProfileContainer>
                {user && <GoogleProfile src={user.profileUrl} />}
                <CommentInput cardId={cardId} />
            </StProfileContainer>
        </StCommentViewContainer>
    );
});

const StCommentViewContainer = styled.div`
    display: flex;
    width: 60%;
    padding: 40px 20px 20px 40px;
    flex-direction: column;
    gap: 16px;
    border-radius: 20px;
    background: #fff;
    white-space: pre-line;

    h2 {
        color: #121212;
        font-size: 20px;
        font-weight: 700;
        line-height: 130%;
        display: flex;
        align-items: center;
        gap: 16px;
    }

    ${media.tablet`
    width: 80%;
    `}

    ${media.mobile`
    width: 90%;
    padding: 20px 20px 10px 20px;   
    `}
`;

const StLine = styled.div`
    width: 100%;
    height: 2px;
    background: #f1f1f1;
`;

const StCommentContent = styled.div`
    width: 100%;
    max-height: 614px;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const StProfileContainer = styled.div`
    width: 100%;
    display: flex;
    gap: 8px;
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

// 스피너 스타일링
const Spinner = styled.div`
    border: 5px solid #f3f3f3;
    border-top: 5px solid #888888;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: ${spin} 2s linear infinite;
    margin: 50px auto;
`;
