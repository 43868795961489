import styled from 'styled-components';
import { media } from '../../styles/MediaQueries';

export const StLoginPageContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;

    p {
        margin-top: 20px;
        margin-bottom: 58px;
        text-align: center;
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%; /* 28.6px */

        ${media.mobile`
        font-size: 14px;
        margin-top: 10px;
        margin-bottom: 38px;
    `}
    }
`;

export const StLogoContainer = styled.div`
    display: flex;
    width: 376.136px;
    height: 90px;
    justify-content: center;
    align-items: center;
    gap: 32.4px;
    cursor: pointer;

    ${media.mobile`
    gap: 20px;   
    `}
`;

export const StLogoText = styled.img`
    width: 253.736px;
    height: 79.164px;
    flex-shrink: 0;

    ${media.mobile`
    height: 50px;
    width: auto;    
    `}
`;

export const StLogo = styled.img`
    width: 90px;
    height: 90px;
    flex-shrink: 0;
    ${media.mobile`
    height: 50px;
    width: 50px;    
    `}
`;

export const StGoogleLoginButton = styled.div`
    display: flex;
    width: 400px;
    height: 60px;
    padding: 18px 24px;
    justify-content: center;
    align-items: center;
    gap: 12px;

    border-radius: 60px;
    background-color: #121212;
    box-shadow: 0px 12px 24px 0px rgba(0, 0, 0, 0.3);
    cursor: pointer;

    ${media.mobile`
        width: 300px;
    `}
`;

export const StGoogle = styled.img`
    width: 24px;
    height: 24px;
`;

export const StGoogleButtonText = styled.div`
    color: white;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;

    ${media.mobile`
        font-size: 18px;
        font-weight: 500;
    `}
`;

export const StCheckBoxContainer = styled.div`
    display: flex;
    justify-content: flex-start; // 좌측 정렬
    align-items: center;

    padding-left: 2px;
    span {
        font-size: 11px;
        color: #717171;
    }
`;
