import { Route, Routes } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';
import LandingPage from '../pages/LandingPage/LandingPage';
import Layout from '../Layout';
import SendMailPage from '../pages/SendMailPage/SendMailPage';
import LoginPage from '../pages/LoginPage/LoginPage';
import CheckRequestPage from '../pages/CheckRequestPage/CheckRequestPage';
import GoogleRedirectPage from '../pages/LoginPage/GoogleRedirectPage';
import CheckReceivedRequestsPage from '../pages/CheckReceivedRequestsPage/CheckReceivedRequestsPage';
import DetailPage from '../pages/DetailPage/DetailPage';
import ChooseTypePage from '../pages/LoginPage/ChooseTypePage';
import FounderOnboarding from '../pages/LoginPage/FounderOnboarding';
import InvestorOnboarding from '../pages/LoginPage/InvestorOnboarding';
import CreateCardPage from '../pages/CreateCardPage/CreateCardPage';
import EditCardPage from '../pages/EditCardPage/EditCardPage';
import InvestorMyPageLayout from '../pages/MyPage/investor/InvestorMyPageLayout';
import MyCards from '../pages/MyPage/investor/pages/MyCards';
import Messages from '../pages/MyPage/investor/pages/Messages';
import InvestorProfile from '../pages/MyPage/investor/pages/Profile';
import SendRequests from '../pages/MyPage/founder/pages/SendRequests';
import FounderMyPageLayout from '../pages/MyPage/founder/FounderMyPageLayout';
import FounderProfile from '../pages/MyPage/founder/pages/Profile';
import ChatPage from '../pages/ChatPage/ChatPage';
import FounderMustFill from '../pages/LoginPage/FounderMustFillPage';
import InvestorMustFillPage from '../pages/LoginPage/InvestorMustFillPage';

export default function RouterProvider() {
    return (
        <Routes>
            <Route element={<Layout />}>
                <Route path='/' element={<LandingPage />} />
                <Route path='/detail/:cardId' element={<DetailPage />} />
                <Route
                    path='/contact/:cardId'
                    element={
                        <ProtectedRoute>
                            <SendMailPage />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path='/proposal/:proposalId'
                    element={
                        <ProtectedRoute>
                            <CheckRequestPage />
                        </ProtectedRoute>
                    }
                />
                <Route element={<FounderMyPageLayout />}>
                    <Route
                        path='/founder-mypage/send-requests'
                        element={
                            <ProtectedRoute>
                                <SendRequests />
                            </ProtectedRoute>
                        }
                    />
                </Route>
                <Route element={<FounderMyPageLayout />}>
                    <Route
                        path='/founder-mypage/profile'
                        element={
                            <ProtectedRoute>
                                <FounderProfile />
                            </ProtectedRoute>
                        }
                    />
                </Route>
                <Route element={<InvestorMyPageLayout />}>
                    <Route
                        path='/investor-mypage/mycards'
                        element={
                            <ProtectedRoute>
                                <MyCards />
                            </ProtectedRoute>
                        }
                    />
                </Route>
                <Route element={<InvestorMyPageLayout />}>
                    <Route
                        path='/investor-mypage/messages'
                        element={
                            <ProtectedRoute>
                                <Messages />
                            </ProtectedRoute>
                        }
                    />
                </Route>
                <Route element={<InvestorMyPageLayout />}>
                    <Route
                        path='/investor-mypage/profile'
                        element={
                            <ProtectedRoute>
                                <InvestorProfile />
                            </ProtectedRoute>
                        }
                    />
                </Route>
                <Route
                    path='/investor-mypage/proposals/:cardId'
                    element={
                        <ProtectedRoute>
                            <CheckReceivedRequestsPage />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path='/chat/:partnerUserId'
                    element={
                        <ProtectedRoute>
                            <ChatPage />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path='/create-card'
                    element={
                        <ProtectedRoute>
                            <CreateCardPage />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path='/edit-card/:cardId'
                    element={
                        <ProtectedRoute>
                            <EditCardPage />
                        </ProtectedRoute>
                    }
                />
            </Route>
            <Route path='/login' element={<LoginPage />} />
            <Route path='/login/google' element={<GoogleRedirectPage />} />
            <Route path='/login/choose-type' element={<ChooseTypePage />} />
            <Route
                path='/login/founder-onboarding'
                element={<FounderOnboarding />}
            />
            <Route
                path='/login/investor-onboarding'
                element={<InvestorOnboarding />}
            />
            <Route
                path='/founder-onboarding/must-fill'
                element={<FounderMustFill />}
            />
            <Route
                path='/investor-onboarding/must-fill'
                element={<InvestorMustFillPage />}
            />
        </Routes>
    );
}
