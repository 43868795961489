import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export default function GoogleRedirectPage() {
    const navigate = useNavigate();

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const jwt = urlParams.get('jwt');
        const accessToken = urlParams.get('accessToken');
        const refreshToken = urlParams.get('refreshToken');

        if (jwt && jwt !== 'null') {
            // 이미 회원가입한 사용자
            localStorage.setItem('jwt', jwt);
            if (refreshToken && refreshToken !== 'null') {
                localStorage.setItem('refreshToken', refreshToken);
            }

            redirectToPreviousOrHomePage();
        } else if (accessToken && accessToken !== 'null') {
            // 회원가입하지 않은 사용자
            localStorage.setItem('accessToken', accessToken);
            navigate('/login/choose-type');
        } else {
            // accessToken이 없는 경우
            navigate('/login'); // 적절한 경로로 리다이렉트
        }
    }, [navigate]);

    function redirectToPreviousOrHomePage() {
        const previousPath = localStorage.getItem('preLoginPath') || '/';
        localStorage.removeItem('preLoginPath');
        navigate(previousPath);
    }

    return <div></div>;
}
