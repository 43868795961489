import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Theme } from '../../../../styles/theme';

interface TallySurveyProps {
    isLogin: boolean;
}
const TallySurvey = ({ isLogin }: TallySurveyProps) => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://tally.so/widgets/embed.js';
        script.async = true;
        script.onload = () => {
            if (window.Tally) {
                window.Tally.loadEmbeds();
            }
        };

        document.body.appendChild(script);

        return () => {
            if (document.body.contains(script)) {
                document.body.removeChild(script);
            }
        };
    }, []);

    return (
        <StSurvey $isLogin={isLogin}>
            <StButton
                data-tally-open='nPzrE0'
                data-tally-emoji-text='📝'
                data-tally-emoji-animation='wave'
            >
                Seek은 성장하고 싶어요!&nbsp;&nbsp;&nbsp;📝
            </StButton>
        </StSurvey>
    );
};

const StSurvey = styled.div<{ $isLogin: boolean }>`
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 40px;
    bottom: ${(props) => (props.$isLogin ? '60px' : '160px')};

    @media (max-width: 700px) {
        margin-top: 100px;
        position: initial;
        width: 100%;
    }
`;

const StButton = styled.button`
    padding: 10px 20px 10px 30px;
    border: 2px solid ${Theme.colors.black};
    border-radius: 10px;
    background: #121212;
    color: white;
    font-weight: 700;
    font-size: 1.2rem;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
        background: ${Theme.colors.white};
        border: 2px solid ${Theme.colors.black};
        color: ${Theme.colors.black};
    }

    @media (max-width: 700px) {
        width: 85%;
    }
`;

export default TallySurvey;
