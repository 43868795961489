import React, { useState } from 'react';
import {
    StGoogle,
    StGoogleButtonText,
    StGoogleLoginButton,
    StLoginPageContainer,
    StLogo,
    StLogoContainer,
    StLogoText,
} from './LoginPageStyle';
import logo from '../../assets/logo.png';
import logoText from '../../assets/logo-text.png';
import google from '../../assets/google.png';
import useNavi from '../../shared/hooks/useNavi';

export default function LoginPage() {
    const { navigateToLandingPage } = useNavi();

    const handleGoogleLogin = () => {
        const googleUrl = process.env.REACT_APP_GOOGLE_URL;
        if (!googleUrl) {
            console.error(
                'Google URL is not defined in the environment variables.'
            );
            return;
        }
        window.location.href = googleUrl;
    };

    return (
        <StLoginPageContainer>
            <StLogoContainer onClick={navigateToLandingPage}>
                <StLogoText src={logoText} alt='logo-text' />
                <StLogo src={logo} alt='logo' />
            </StLogoContainer>
            <p>VC와 스타트업이 가장 빠르게 연결되는 곳</p>
            <StGoogleLoginButton onClick={handleGoogleLogin}>
                <StGoogle src={google} alt='google' />
                <StGoogleButtonText>Google로 시작하기</StGoogleButtonText>
            </StGoogleLoginButton>
        </StLoginPageContainer>
    );
}
