import React, { useEffect, useState } from 'react';
import {
    StHamburgerIcon,
    StHeaderContainer,
    StLoginButton,
    StLogo,
    StLogoText,
    StlogoContainer,
    StBackdrop,
    StRight,
    StIconContainer,
    StIcon,
    StGoogleProfile,
    StUnRead,
} from './HeaderStyle';
import logo from '../../assets/logo.png';
import logoText from '../../assets/logo-text.png';
import hamburger from '../../assets/hamburger.png';
import useNavi from '../hooks/useNavi';
import GoogleProfile from '../GoogleProfile/GoogleProfile';
import { getJwt } from '../TokenUtils/TokenUtils';
import { ProfileSlide } from './components/ProfileSlide';
import { UserData } from '../Type/type';
import { userState } from '../State/atom';
import { useQuery } from '@tanstack/react-query';
import { getUserInfo } from '../../api/userApi';
import { useRecoilState } from 'recoil';
import { GoBell } from 'react-icons/go';
import NotificationModal from './components/NotificationModal';
import {
    connectNotification,
    getNewNotifications,
    readNewNotifications,
} from '../../api/NotificationApi';
import { useNavigate } from 'react-router-dom';

export default function Header() {
    const [hasNewNotifications, setHasNewNotifications] = useState(false);
    const { navigateToLandingPage, navigateToLoginPage } = useNavi();
    const [isLogin, setIsLogin] = useState(false);
    const jwt = getJwt();
    const [user, setUser] = useRecoilState<UserData | null>(userState);
    const [isProfileSlideOpen, setIsProfileSlideOpen] =
        useState<boolean>(false);
    const [isNotificationModalOpen, setIsNotificationModalOpen] =
        useState(false);
    const navigate = useNavigate();

    const toggleProfileSlide = () => {
        setIsProfileSlideOpen(!isProfileSlideOpen);
    };

    useEffect(() => {
        jwt ? setIsLogin(true) : setIsLogin(false);
    }, []);

    useEffect(() => {
        if (!isLogin) {
            return;
        }

        const eventSource = connectNotification();

        eventSource.onmessage = (event) => {
            const data = JSON.parse(event.data);
            if (data.value && data.value !== 'ping') {
                // console.log('New notification:', data.value.type);
                setHasNewNotifications(true);
            }
        };

        eventSource.onerror = (error) => {
            console.error('SSE failed:', error);
            // alert('네트워크 연결에 문제가 있습니다. 연결 상태를 확인해주세요.');
            // window.location.reload(); // 페이지 새로고침
        };

        return () => {
            eventSource.close();
        };
    }, [isLogin]);

    useEffect(() => {
        const fetchNewNotifications = async () => {
            try {
                const newNotifications = await getNewNotifications();
                setHasNewNotifications(newNotifications);
            } catch (error) {
                console.error('Error fetching new notifications:', error);
            }
        };

        if (isLogin) {
            fetchNewNotifications();
        }
    }, [isLogin]);

    useEffect(() => {
        const refreshToke = localStorage.getItem('refreshToke');
        if (refreshToke) {
            localStorage.removeItem('jwt');
            localStorage.removeItem('refreshToke');
            window.location.replace('/');
        }
    }, []);

    const handleNotification = () => {
        setIsNotificationModalOpen(!isNotificationModalOpen);
        readNewNotifications().then(() => {
            setHasNewNotifications(false);
        });
    };

    const { data, isError, error, isSuccess } = useQuery({
        queryKey: ['getUserInfo'],
        queryFn: getUserInfo,
        enabled: isLogin,
    });

    useEffect(() => {
        if (data) {
            setUser(data);
        }
    }, [data]);

    useEffect(() => {
        if (isSuccess) {
            if (data.userType === 'INVESTOR' && data.phoneNumber === '') {
                navigate('/investor-onboarding/must-fill');
            } else if (data.userType === 'FOUNDER' && data.sector === '') {
                navigate('/founder-onboarding/must-fill');
            }
        }
    }, [data]);

    // console.log(data);

    if (isError) {
        return <span>Error: {error.message}</span>;
    }

    return (
        <>
            <StHeaderContainer>
                <p>
                    Powered by <span>DECACORN</span>
                </p>
                <StlogoContainer
                    $isLogin={isLogin}
                    onClick={navigateToLandingPage}
                >
                    <StLogoText src={logoText} alt='Logo' />
                    <StLogo src={logo} alt='Logo' />
                </StlogoContainer>
                {isLogin ? (
                    <StRight>
                        <StIconContainer>
                            <StIcon onClick={handleNotification}>
                                <GoBell size={25} />
                                {hasNewNotifications && <StUnRead />}
                            </StIcon>
                            {isNotificationModalOpen && (
                                <NotificationModal
                                    setIsNotificationModalOpen={
                                        setIsNotificationModalOpen
                                    }
                                />
                            )}
                        </StIconContainer>
                        <StGoogleProfile>
                            <GoogleProfile
                                onClick={toggleProfileSlide}
                                src={user?.profileUrl}
                                alt='User Profile'
                            />
                        </StGoogleProfile>
                    </StRight>
                ) : (
                    <>
                        <StLoginButton onClick={navigateToLoginPage}>
                            회원가입 / 로그인
                        </StLoginButton>
                        <StHamburgerIcon
                            src={hamburger}
                            alt='Menu'
                            onClick={toggleProfileSlide}
                        />
                    </>
                )}
            </StHeaderContainer>

            <ProfileSlide
                $isOpen={isProfileSlideOpen}
                toggleProfileSlide={toggleProfileSlide}
            />
            <StBackdrop
                $isOpen={isProfileSlideOpen}
                onClick={toggleProfileSlide}
            />
        </>
    );
}
