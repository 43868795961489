import React from 'react';
import styled, { keyframes } from 'styled-components';
import VCCard from '../SendMailPage/components/VCCard';
import { media } from '../../styles/MediaQueries';
import { useQuery } from '@tanstack/react-query';
import { getProposal } from '../../api/proposalApi';
import { useParams } from 'react-router-dom';

export default function CheckRequestPage() {
    const { proposalId } = useParams();

    const downLoad = () => {
        if (proposalData && proposalData.fileUrl) {
            window.location.href = proposalData.fileUrl;
        }
    };

    const {
        isLoading,
        isError,
        data: proposalData,
        error,
    } = useQuery({
        queryKey: ['proposal', proposalId],
        queryFn: () => getProposal(proposalId),
        enabled: !!proposalId,
    });

    // console.log(proposalData);

    if (isLoading) {
        return <Spinner />;
    }

    if (isError) {
        return <span>Error: {error.message}</span>;
    }

    return (
        <StCheckRequestPageContainer>
            <VCCard cardId={proposalData.card.id} />
            <section>
                <StTime>
                    <label>검토 요청 날짜</label>
                    <p>{proposalData.createdAt.split('T')[0]}</p>
                </StTime>
                <StRow>
                    <StBox>
                        <label>창업자 이름</label>
                        <div>{proposalData.name}</div>
                    </StBox>
                    <StBox>
                        <label>이메일</label>
                        <div>{proposalData.email}</div>
                    </StBox>
                </StRow>
                <StRow>
                    <StBox>
                        <label>회사명</label>
                        <div>{proposalData.companyName}</div>
                    </StBox>
                    <StBox>
                        <label>주요 서비스/제품명</label>
                        <div>{proposalData.serviceName}</div>
                    </StBox>
                </StRow>
                <StRow>
                    <StBox>
                        <label>서비스 한줄 소개</label>
                        <div>{proposalData.serviceIntroduction}</div>
                    </StBox>
                    <StBox>
                        <label>웹사이트 주소</label>
                        <div>{proposalData.link}</div>
                    </StBox>
                </StRow>
                <StRow>
                    <StBox>
                        <label>투자 라운드</label>
                        <div>{proposalData.investmentRound}</div>
                    </StBox>
                    <StBox>
                        <label>기투자사 리스트</label>
                        <div>{proposalData.investedCompany}</div>
                    </StBox>
                </StRow>
                <StBox>
                    <label>투자제안 개요</label>
                    <div>{proposalData.content}</div>
                </StBox>
                <StBox>
                    <label>회사소개서 혹은 IR 자료</label>
                    <p onClick={downLoad}>파일 다운로드</p>
                </StBox>
            </section>
        </StCheckRequestPageContainer>
    );
}

const StCheckRequestPageContainer = styled.div`
    margin: 40px 0px 100px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;

    section {
        display: flex;
        width: 60%;
        flex-direction: column;
        gap: 40px;
        margin-bottom: 25px;

        ${media.tablet`
        width: 80%;
        `}
        ${media.mobile`
        width: 90%;
        `}
    }
`;

const StRow = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    gap: 15px;

    ${media.tablet`
    align-items: center;
        flex-direction: column;
        gap: 40px;
        `}
`;

const StTime = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 5px;

    label {
        font-weight: 600;
    }

    margin-bottom: 30px;
`;

const StBox = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 10px;

    label {
        font-size: 18px;
        font-weight: 700;
    }

    div {
        background-color: white;
        padding: 20px;
        font-size: 18px;
        font-weight: 300;
        min-height: 60px;
        height: 100%;
        border-radius: 8px;
        border: 0.5px solid #828282;
        box-shadow: 0px 1.5px 0px 0px rgba(0, 0, 0, 0.2);
        word-break: break-word;
        white-space: pre-line;
    }

    p {
        background-color: white;
        padding: 20px;
        font-size: 18px;
        font-weight: 600;
        height: 60px;
        border-radius: 8px;
        border: 0.5px solid #828282;
        box-shadow: 0px 1.5px 0px 0px rgba(0, 0, 0, 0.2);
        cursor: pointer;

        &:hover {
            opacity: 0.6;
            box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.4);
        }
    }
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

// 스피너 스타일링
const Spinner = styled.div`
    border: 5px solid #f3f3f3;
    border-top: 5px solid #888888;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    animation: ${spin} 2s linear infinite;
    margin: 300px auto;
`;
