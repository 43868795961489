import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import reply from '../../../assets/reply.png';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { patchComment } from '../../../api/cardApi';

interface ModifyInputProps {
    content: string;
    cardId: string;
    commentId: string;
    setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function ModifyInput({
    content,
    cardId,
    commentId,
    setIsEditing,
}: ModifyInputProps) {
    const inputRef = useRef<HTMLTextAreaElement>(null);
    const [newContent, setNewContent] = useState(content);
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: patchComment,
        onSuccess: () => {
            if (cardId) {
                queryClient.invalidateQueries({
                    queryKey: ['comments', cardId],
                });
            }
        },
    });

    const handleSubmit = () => {
        mutation.mutate(
            { commentId, newContent },
            {
                onSuccess: () => {
                    setIsEditing(false);
                },
            }
        );
    };

    useEffect(() => {
        const textarea = inputRef.current;
        if (textarea) {
            textarea.focus();
            const length = textarea.value.length;
            textarea.setSelectionRange(length, length);
        }
    }, []);

    return (
        <StModifyInputContainer>
            <StCommntInputContainer
                value={newContent}
                onChange={(e) => setNewContent(e.target.value)}
                ref={inputRef}
            />
            <StReplyIcon src={reply} alt='reply' onClick={handleSubmit} />
        </StModifyInputContainer>
    );
}

const StModifyInputContainer = styled.div`
    position: relative;
    width: 100%;
`;

const StCommntInputContainer = styled.textarea`
    width: 100%;
    height: 80px;
    padding: 15px;
    padding-right: 40px;
    flex-direction: column;
    gap: 4px;
    border-radius: 16px;
    background: #f1f1f1;
    border: none;
    resize: none;
    outline: none;
    font-size: 15px;
`;

const StReplyIcon = styled.img`
    position: absolute;
    right: 12px;
    bottom: 12px;
    width: 24px;
    height: 24px;
    cursor: pointer;

    &:hover {
        filter: opacity(0.5);
    }
`;
