import React, { useState } from 'react';
import styled from 'styled-components';
import { media } from '../../styles/MediaQueries';
import { Theme } from '../../styles/theme';
import logo from '../../assets/logo.png';
import logoText from '../../assets/logo-text.png';
import { useNavigate } from 'react-router-dom';

type StCardProps = {
    selected: boolean;
};

export default function ChooseTypePage() {
    const [selectedCard, setSelectedCard] = useState('');
    const navigate = useNavigate();

    const handleCardClick = (cardType: string) => {
        setSelectedCard(cardType);
    };

    const handleNextClick = () => {
        if (selectedCard === 'general') {
            navigate('/login/founder-onboarding');
        } else if (selectedCard === 'investor') {
            navigate('/login/investor-onboarding');
        }
    };

    return (
        <StContainer>
            <StLogoBox>
                <StLogoContainer>
                    <StLogoText src={logoText} alt='logo-text' />
                    <StLogo src={logo} alt='logo' />
                </StLogoContainer>
            </StLogoBox>
            <StChooseTypePage>
                <StTitle>원하는 회원가입 유형을 선택하세요.</StTitle>
                <StCardContainer>
                    <StCard
                        onClick={() => handleCardClick('general')}
                        selected={selectedCard === 'general'}
                    >
                        <StEmoji>🙋‍♀️</StEmoji>
                        <StCardTitle>일반 유형으로 가입할게요.</StCardTitle>
                        <p>투자자가 찾는 스타트업/창업가가 궁금해요.</p>
                        <StPunch />
                    </StCard>
                    <StCard
                        onClick={() => handleCardClick('investor')}
                        selected={selectedCard === 'investor'}
                    >
                        <StEmoji>🙋</StEmoji>
                        <StCardTitle>투자자로 가입할게요.</StCardTitle>
                        <p>스타트업/창업가를 찾고있어요.</p>
                        <StPunch />
                    </StCard>
                </StCardContainer>
                <StStartButton
                    onClick={handleNextClick}
                    disabled={!selectedCard}
                >
                    다음
                </StStartButton>
            </StChooseTypePage>
        </StContainer>
    );
}

const StContainer = styled.div`
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    height: 100%;
`;

const StLogoBox = styled.div`
    position: absolute;
    position: fixed;
    top: 20px;
    left: 3vw;
`;

const StLogoContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 50px;
`;
const StLogoText = styled.img`
    width: auto;
    height: 30px;
    flex-shrink: 0;
`;
const StLogo = styled.img`
    width: 30px;
    height: 30px;
    flex-shrink: 0;
`;

const StChooseTypePage = styled.div`
    width: 70%;
    min-height: 100px;
    border-radius: 20px;
    padding: 86px 10px 60px 10px;
    border: 1px solid #c5c5c5;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 40px;

    @media (max-width: 930px) {
        width: 90%;
    }
`;

const StTitle = styled.div`
    color: #121212;
    font-size: 25px;
    font-weight: 500;

    ${media.mobile`
    font-size: 20px;
    `}
`;

const StCardContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;

    @media (max-width: 930px) {
        flex-direction: column;
    }
`;

const StEmoji = styled.div`
    font-size: 40px;
    margin-bottom: 15px;
`;

const StCard = styled.div<StCardProps>`
    position: relative;
    width: 100%;
    min-width: 310px;
    min-height: 100px;
    border-radius: 20px;
    padding: 35px 26px;
    border: 1px solid #c5c5c5;
    display: flex;
    flex-direction: column;
    gap: 15px;
    font-size: 15px;
    cursor: pointer;

    background-color: ${({ selected }) => (selected ? '#fff48e' : '#fff')};
`;

const StCardTitle = styled.div`
    font-size: 20px;
`;

const StStartButton = styled.button`
    cursor: pointer;
    background-color: #121212;
    padding: 13px;
    font-size: 15px;
    color: white;
    border: none;
    border-radius: 30px;
    width: 50%;
    min-width: 310px;

    &:disabled {
        cursor: default;
        background-color: #f1f1f1;
        color: #828282;
    }
`;

const StPunch = styled.div`
    position: absolute;
    right: 10px;
    top: 10px;
    background-color: white;
    border: 1px solid #c5c5c5;
    width: 20px;
    height: 20px;
    border-radius: 100%;
`;
