import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import GoogleProfile from '../../GoogleProfile/GoogleProfile';
import logoText from '../../../assets/logo-text.png';
import logo from '../../../assets/logo.png';
import { getJwt, handleLogout } from '../../TokenUtils/TokenUtils';
import useNavi from '../../hooks/useNavi';
import { useRecoilValue } from 'recoil';
import { userState } from '../../State/atom';

interface ProfileSlideProps {
    $isOpen: boolean;
    toggleProfileSlide: () => void;
}

export const ProfileSlide: React.FC<ProfileSlideProps> = ({
    $isOpen,
    toggleProfileSlide,
}) => {
    const {
        navigateToLoginPage,
        navigateToFounderMyPage,
        navigateToInvestorMyPage,
    } = useNavi();

    const [isLogin, setIsLogin] = useState(false);
    const jwt = getJwt();
    const user = useRecoilValue(userState);

    useEffect(() => {
        jwt ? setIsLogin(true) : setIsLogin(false);
    }, []);

    const handleMyPageClick = () => {
        if (user?.userType === 'INVESTOR') {
            navigateToInvestorMyPage();
        } else {
            navigateToFounderMyPage();
        }
        toggleProfileSlide();
    };

    return (
        <StProfileSlide $isOpen={$isOpen}>
            <StTopContainer>
                <StlogoContainer>
                    <StLogoText src={logoText} alt='Logo' />
                    <StLogo src={logo} alt='Logo' />
                </StlogoContainer>
                {isLogin && <p onClick={handleMyPageClick}>마이페이지</p>}
                {isLogin ? (
                    <p onClick={handleLogout}>로그아웃</p>
                ) : (
                    <p onClick={navigateToLoginPage}>로그인</p>
                )}
            </StTopContainer>
            <StFooter>
                Powered by <span>DECACORN</span>
            </StFooter>
        </StProfileSlide>
    );
};

const StProfileSlide = styled.div<{ $isOpen: boolean }>`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 300px;
    height: 100vh;
    padding: 50px 30px 40px 40px;
    position: fixed;
    right: 0;
    top: 0;
    background: #fff;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
    transform: translateX(100%); // 우측에서 시작
    transition: transform 0.3s ease-in-out;
    z-index: 999;

    transform: ${({ $isOpen }) =>
        $isOpen ? 'translateX(0)' : 'translateX(100%)'};
`;

const StTopContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 30px;
    color: #121212;
    font-size: 20px;
    font-weight: 600;
    line-height: 130%; /* 20.8px */
    p {
        cursor: pointer;

        &:hover {
            filter: opacity(0.5);
        }
    }
`;

const StlogoContainer = styled.div`
    display: flex;
    width: 100px;
    height: 24px;
    justify-content: center;
    align-items: flex-start;
    gap: 8.614px;
    margin-bottom: 20px;
`;

const StGoogleProfile = styled.div`
    cursor: pointer;
    &:hover {
        filter: opacity(0.7);
    }
`;

const StLogoText = styled.img`
    height: 30px;
    width: auto;
`;

const StLogo = styled.img`
    height: 30px;
    width: auto;
`;

const StFooter = styled.p`
    color: #121212;
    font-size: 13px;
    font-weight: 400;
    line-height: 30px; /* 230.769% */

    span {
        font-weight: 700;
    }
`;
