import React from 'react';
import styled from 'styled-components';
import { media } from '../../../../styles/MediaQueries';
import { useRecoilValue } from 'recoil';
import { userState } from '../../../../shared/State/atom';

export default function FounderHeader() {
    const user = useRecoilValue(userState);
    // console.log(user);
    return (
        <StHeaderContainer>
            <StFounderHeader>
                <p>👋</p>
                <StContainer>
                    <p>안녕하세요, {user?.name}님</p>
                </StContainer>
            </StFounderHeader>
        </StHeaderContainer>
    );
}

const StHeaderContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const StFounderHeader = styled.div`
    display: flex;
    flex-direction: column;
    width: 80%;
    gap: 5px;

    p {
        font-size: 28px;
        font-weight: 700;

        ${media.mobile`
       font-size: 24px;
    `}
    }

    ${media.tablet`
        width: 90%
    `}
`;

const StContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 28px;
    font-weight: 700;
    line-height: 160%;

    ${media.mobile`
        flex-direction: column;
        gap: 20px;
        align-items: baseline;
    `}
`;
