import React, { useMemo } from 'react';
import Tag from '../../../../shared/Tag/Tag';
import view from '../../../../assets/view.png';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { media } from '../../../../styles/MediaQueries';
import { Theme } from '../../../../styles/theme';

interface CardProps {
    index?: number;
    createdAt: string;
    id: string;
    title: string;
    profileUrl?: string;
    name: string;
    tag: string;
    viewCount: number;
}

export default function Card({
    index = 0,
    createdAt,
    id,
    title,
    profileUrl,
    name,
    tag,
    viewCount,
}: CardProps) {
    const formatviewCount = (count: number) => {
        if (count >= 1000) {
            return `${(count / 1000).toFixed(1)}k`;
        }
        return count.toString();
    };

    const isNew = useMemo(() => {
        const createdDate = new Date(createdAt);
        const currentDate = new Date();
        const differenceInTime = currentDate.getTime() - createdDate.getTime();
        const differenceInDays = differenceInTime / (1000 * 3600 * 24);
        return differenceInDays <= 21;
    }, [createdAt]);

    const navigate = useNavigate();

    return (
        <StCardContainer
            $index={index}
            onClick={() => {
                navigate(`/detail/${id}`);
                window.scrollTo(0, 0);
            }}
        >
            <StTagTitleContainer>
                <StTagviewContainer>
                    <Tag label={tag} />
                    <StviewContainer>
                        <Stview src={view} alt='view' />
                        <div>{formatviewCount(viewCount)}</div>
                    </StviewContainer>
                </StTagviewContainer>
                <h2>{title}</h2>
            </StTagTitleContainer>
            <StVCContainer>
                <StLogoContainer>
                    <img src={profileUrl} alt='심사역 로고' />
                </StLogoContainer>
                <StHouseName>{name}</StHouseName>
            </StVCContainer>
            {isNew && <StBadge>NEW 🎉</StBadge>}
        </StCardContainer>
    );
}

export const StBadge = styled.div`
    position: absolute;
    border: 2px solid white;
    top: -22px;
    right: -15px;
    padding: 13px 15px 13px 22px;
    background-color: ${Theme.colors.black};
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 400;
    color: #fff;

    animation: bounce2times 0.5s ease-in-out 1s both;
    @keyframes bounce2times {
        0% {
        }
        25% {
            transform: translateY(5px);
        }
        50% {
            transform: translateY(0);
        }
        75% {
            transform: translateY(5px);
        }
        100% {
            transform: translateY(0);
        }
    }
`;

export const StCardContainer = styled.div<{ $index: number }>`
    position: relative;
    display: flex;
    width: 300px;
    height: 300px;
    padding: 28px;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    border-radius: 20px;
    border: 1px solid #fff;
    margin-bottom: 20px;

    background-color: #fff48e;
    background-image: repeating-linear-gradient(
        0deg,
        transparent,
        transparent 33px,
        #ffea61 33px,
        #ffea61 35px
    );
    box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.15);

    transition: transform 0.3s ease;
    cursor: pointer;
    &:hover {
        transform: translateY(-10px);
        box-shadow: 0px 10px 26px 0px rgba(0, 0, 0, 0.25);
    }

    h2 {
        font-size: 24px;
        font-weight: 700;
        line-height: 150%; /* 36px */
        letter-spacing: -1px;
    }
    ${media.mobile`
    &:hover {
        transform: translateY(0px);
    }
    `}
`;

export const StTagTitleContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

export const StTagviewContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const StviewContainer = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;
`;

export const Stview = styled.img`
    width: 24px;
    height: auto;
`;

export const StVCContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;

    font-size: 18px;
    line-height: 140%;
`;

export const StLogoContainer = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`;

const StHouseName = styled.p`
    font-weight: 600;
`;
