import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { media } from '../../styles/MediaQueries';
import { useNavigate } from 'react-router-dom';
import { getUserInfo, patchUserInfo } from '../../api/userApi';
import { useQuery } from '@tanstack/react-query';

interface InvestorMustFillFormData {
    position: string;
    phoneNumber: string;
}

export default function InvestorMustFillPage() {
    const {
        data: user,
        refetch,
        isSuccess,
    } = useQuery({
        queryKey: ['getUserInfo'],
        queryFn: getUserInfo,
    });

    // console.log(user);

    const navigate = useNavigate();
    const [formData, setFormData] = useState<InvestorMustFillFormData>({
        position: '',
        phoneNumber: '',
    });

    const isNotValid = formData.position === '' || formData.phoneNumber === '';

    const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        const formDataToSend = new FormData();
        formDataToSend.append('position', formData.position);
        formDataToSend.append('phoneNumber', formData.phoneNumber);

        try {
            await patchUserInfo(formDataToSend);
            await refetch();
        } catch (error) {
            console.error('추가정보입력 실패:', error);
            alert('추가 정보 입력에 실패하였습니다.');
        }
    };

    useEffect(() => {
        if (isSuccess && user?.phoneNumber) {
            navigate('/');
        }
    }, [user?.phoneNumber, isSuccess, navigate]);

    return (
        <StContainer>
            <StFounderOnboardingPage>
                <StTitle>딱, 이것만 알려주세요!</StTitle>
                <StSelect>
                    <label htmlFor='position'>직책 *</label>
                    <select
                        id='position'
                        name='position'
                        value={formData.position}
                        onChange={handleSelectChange}
                        required
                    >
                        <option value='' disabled selected hidden>
                            선택해주세요
                        </option>
                        <option value='투자 심사역'>투자 심사역</option>
                        <option value='책임 심사역'>책임 심사역</option>
                        <option value='수석 심사역'>수석 심사역</option>
                        <option value='수석 팀장'>수석 팀장</option>
                        <option value='VP'>VP</option>
                        <option value='파트너'>파트너</option>
                        <option value='대표'>대표</option>
                        <option value='기타'>기타</option>
                    </select>
                </StSelect>
                <StInput>
                    <label htmlFor='phoneNumber'>전화번호 *</label>
                    <input
                        type='text'
                        name='phoneNumber'
                        value={formData.phoneNumber}
                        onChange={handleInputChange}
                        placeholder='숫자만 입력해주세요'
                        required
                    ></input>
                </StInput>

                <StFooter>
                    <StStartButton disabled={isNotValid} onClick={handleSubmit}>
                        SEEK 시작하기
                    </StStartButton>
                </StFooter>
            </StFounderOnboardingPage>
        </StContainer>
    );
}

const StContainer = styled.div`
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    height: 100%;
`;

const StFounderOnboardingPage = styled.form`
    margin-top: 100px;
    margin-bottom: 100px;
    width: 70%;
    min-height: 100px;
    border-radius: 20px;
    padding: 86px 10px 60px 10px;
    border: 1px solid #c5c5c5;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;

    @media (max-width: 930px) {
        width: 90%;
    }
`;

const StTitle = styled.div`
    color: #121212;
    font-size: 25px;
    font-weight: 500;
    margin-bottom: 30px;

    ${media.mobile`
        font-size: 20px;
        `}
`;

const StSelect = styled.div`
    display: flex;
    width: 60%;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 25px;

    label {
        font-size: 18px;
        font-weight: 700;
    }

    select {
        padding: 20px;
        font-size: 15px;
        font-weight: 300;
        height: 60px;
        border-radius: 8px;
        border: 0.5px solid #828282;
        box-shadow: 0px 1.5px 0px 0px rgba(0, 0, 0, 0.2);
        cursor: pointer;

        &:hover {
            box-shadow: 0px 2.5px 3px 0px rgba(0, 0, 0, 0.2);
        }

        &:focus {
            outline: 3px solid #bababa;
        }
    }

    ${media.tablet`
            width: 80%;
            `}
    ${media.mobile`
            width: 90%;
            `}
`;

const StInput = styled.div`
    display: flex;
    width: 60%;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 25px;
    position: relative;

    label {
        font-size: 17px;
        font-weight: 700;
    }

    input {
        padding: 20px;
        font-size: 15px;
        font-weight: 300;
        height: 50px;
        border-radius: 8px;
        border: 0.5px solid #828282;
        box-shadow: 0px 1.5px 0px 0px rgba(0, 0, 0, 0.2);

        &:hover {
            box-shadow: 0px 2.5px 3px 0px rgba(0, 0, 0, 0.2);
        }

        &:focus {
            outline: 3px solid #bababa;
        }
    }

    ${media.tablet`
    width: 80%;
    `}

    ${media.mobile`
    width: 90%;
    `}
`;

const StFooter = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 60%;

    ${media.tablet`
            width: 80%;
            `}
    ${media.mobile`
            width: 90%;
            `}
`;

const StStartButton = styled.button`
    cursor: pointer;
    background-color: #121212;
    padding: 13px;
    font-size: 15px;
    color: white;
    border: none;
    border-radius: 30px;
    width: 100%;
    min-width: 310px;

    &:hover {
        opacity: 0.7;
    }

    &:disabled {
        cursor: default;
        background-color: #f1f1f1;
        color: #828282;

        &:hover {
            opacity: 1;
        }
    }
`;
