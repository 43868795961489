import { useNavigate } from 'react-router-dom';

export default function useNavi() {
    const navigate = useNavigate();
    function navigateToLandingPage() {
        navigate('/');
        window.scrollTo(0, 0);
    }
    function navigateToLoginPage() {
        const currentPath = window.location.pathname;
        localStorage.setItem('preLoginPath', currentPath);
        navigate('/login');
    }
    function navigateToFounderMyPage() {
        navigate('/founder-mypage/profile');
        window.scrollTo(0, 0);
    }
    function navigateToInvestorMyPage() {
        navigate('/investor-mypage/profile');
        window.scrollTo(0, 0);
    }

    return {
        navigateToLandingPage,
        navigateToLoginPage,
        navigateToFounderMyPage,
        navigateToInvestorMyPage,
    };
}
