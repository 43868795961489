import { api } from './api';

export const getUserInfo = async () => {
    const response = await api.get(`/user`);
    return response.data.value;
};

export const patchUserInfo = async (formData: FormData) => {
    const response = await api.patch('/user', formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
    return response.data;
};

export const deleteUser = async () => {
    await api.delete('/user');
};
