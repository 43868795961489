import React from 'react';
import { GlobalStyle } from './styles/GlobalStyle';
import RouterProvider from './router/Router';
import { TanstackQueryProvider } from './providers/TanstackQueryProvider';
import { RecoilProvider } from './providers/RecoilProvider';
import { ThemeProvider } from 'styled-components';
import { Theme } from './styles/theme';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function App() {
    return (
        <>
            <ToastContainer />
            <GlobalStyle />
            <RecoilProvider>
                <TanstackQueryProvider>
                    <ThemeProvider theme={Theme}>
                        <RouterProvider />
                    </ThemeProvider>
                </TanstackQueryProvider>
            </RecoilProvider>
        </>
    );
}
