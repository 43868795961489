import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { IoIosCloseCircle } from 'react-icons/io';
import { patchApprovedProposal } from '../../../api/proposalApi';
import { toast } from 'react-toastify';

interface ReplyModalProps {
    proposalId: string;
    companyName: string;
    name: string;
    investorName: string;
    investorCompany: string;
    investorEmail: string;
    isOpen: boolean;
    onClose: () => void;
    refetch: () => void;
}

export default function ReplyModal({
    proposalId,
    companyName,
    name,
    investorName,
    investorCompany,
    investorEmail,
    isOpen,
    onClose,
    refetch,
}: ReplyModalProps) {
    const [selectedTag, setSelectedTag] = useState<'deny' | 'contact'>('deny');
    const [isSending, setIsSending] = useState(false);

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
        }
        return () => {
            document.body.style.overflow = 'unset';
        };
    }, [isOpen]);

    const handleDenyClick = async () => {
        setIsSending(true);
        try {
            const response = await patchApprovedProposal(proposalId, false);
            if (!response.hasError) {
                refetch();
                toast.success('답변을 성공적으로 전달했어요!');
            } else {
                alert(response.error);
            }
        } catch (error) {
            console.error('Error updating proposal:', error);
        } finally {
            setIsSending(false);
            onClose();
        }
    };

    const handleApproveClick = async () => {
        setIsSending(true);
        try {
            const response = await patchApprovedProposal(proposalId, true);
            if (!response.hasError) {
                refetch();
                toast.success('답변을 성공적으로 전달했어요!');
            } else {
                alert(response.error);
            }
        } catch (error) {
            console.error('Error updating proposal:', error);
        } finally {
            setIsSending(false);
            onClose();
        }
    };

    return (
        <StOverlay>
            <StReplyModal>
                <StCloseButton>
                    <IoIosCloseCircle size={30} onClick={onClose} />
                </StCloseButton>
                <StHeader>3초만에 답변을 전달!</StHeader>
                <StDescription>
                    스타트업과 빠르고 효율적으로 소통해보세요.
                </StDescription>
                <StMessageContainer>
                    <StTagContainer>
                        <StDenyTag
                            selected={selectedTag === 'deny'}
                            onClick={() => setSelectedTag('deny')}
                        >
                            🙏 현재 검토 불가
                        </StDenyTag>
                        <StContactTag
                            selected={selectedTag === 'contact'}
                            onClick={() => setSelectedTag('contact')}
                        >
                            👍 후속 미팅 제안
                        </StContactTag>
                    </StTagContainer>
                    {selectedTag === 'deny' && (
                        <StMessage>
                            {`${companyName} 투자 검토 요청에 대한 답변
           
                                안녕하세요 ${name} 님.
                                ${investorCompany} ${investorName} 입니다.
                                
                                먼저 투자 검토 요청을 보내주셔서 감사드립니다.
                                긴 시간 심사숙고 하였으나 아쉽게도 이번 기회에는 투자를 진행하지 않기로 결정하였습니다.

                                검토 결과는 제안 내용과 저희의 현재 포트폴리오 등 종합적인 고려 끝에 결정된 것을 알아주시고,
                                크게 낙심하지 않으시길 소망합니다.

                                다시 한번 저희에게 보여주신 귀사의 열정과 비전에 감사드리며
                                좋은 기회에 다시 만나뵐 수 있기를 진심으로 바라겠습니다.
                                
                                ${investorCompany}
                                ${investorName}`}
                        </StMessage>
                    )}
                    {selectedTag === 'contact' && (
                        <StMessage>
                            {`${companyName} 투자 검토 요청에 대한 답변
            
                                안녕하세요 ${name} 님.
                                ${investorCompany} ${investorName} 입니다.
                                
                                먼저 투자 검토 요청을 보내주셔서 감사드립니다.
                                귀사의 열정과 비전을 느낄 수 있었고, 제안서도 꼼꼼히 살펴봤습니다.
                                
                                사업 모델과 성장 잠재력에 대해 더 깊이 이해하고 싶습니다.
                                이에, 추가적인 논의를 위한 미팅을 제안드립니다.

                                귀사의 장기적인 전략과 현재 시장에서의 위치, 그리고 향후 성장 계획에 대해 논의하고 싶습니다.

                                가능한 시간을 ${investorEmail} 이메일로 알려주시면 좋겠습니다. 
                                
                                ${investorCompany}
                                ${investorName}`}
                        </StMessage>
                    )}
                </StMessageContainer>
                {selectedTag === 'contact' ? (
                    <StContactButton
                        onClick={!isSending ? handleApproveClick : undefined}
                    >
                        {isSending ? <SmallSpinner /> : '후속 미팅 제안하기'}
                    </StContactButton>
                ) : (
                    <StDenyButton
                        onClick={!isSending ? handleDenyClick : undefined}
                    >
                        {isSending ? <SmallSpinner /> : '메시지 전달하기'}
                    </StDenyButton>
                )}
            </StReplyModal>
        </StOverlay>
    );
}

const StOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
    overflow-y: auto;
`;

const StReplyModal = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px 30px;
    background-color: white;
    border-radius: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    min-width: 365px;
    gap: 20px;
    position: relative;
`;

const StCloseButton = styled.div`
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 15px;

    &:hover {
        opacity: 0.6;
    }
`;

const StHeader = styled.div`
    font-weight: 600;
    font-size: 22px;
`;

const StDescription = styled.div`
    font-weight: 300;
    font-size: 13px;
    margin-bottom: 20px;
`;

const StMessageContainer = styled.div``;

const StTagContainer = styled.div`
    display: flex;
    width: 100%;
    font-weight: 600;
`;

const StDenyTag = styled.div<{ selected: boolean }>`
    cursor: pointer;
    display: flex;
    flex: 1;
    justify-content: center;
    padding: 20px;
    border: 1px solid ${(props) => (props.selected ? '#ff4d4d' : '#adadad')};
    border-top-left-radius: 4px;
    border-right: none;
    background-color: ${(props) => (props.selected ? '#ff4d4d' : 'white')};
    color: ${(props) => (props.selected ? 'white' : 'black')};

    &:hover {
        background-color: #ff4d4d;
        color: white;
    }
`;

const StContactTag = styled.div<{ selected: boolean }>`
    cursor: pointer;
    display: flex;
    flex: 1;
    justify-content: center;
    padding: 20px;
    border: 1px solid #adadad;
    border-top-right-radius: 4px;
    background-color: ${(props) => (props.selected ? '#fff48e' : 'white')};
    color: ${(props) => (props.selected ? 'black' : 'black')};

    &:hover {
        background-color: #fff48e;
    }
`;

const StMessage = styled.div`
    font-size: 14px;
    border: 1px solid #adadad;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-top: none;
    padding: 50px 30px;
    line-height: 1.2;
    white-space: pre-line;
`;

const StDenyButton = styled.div`
    display: flex;
    justify-content: center;
    cursor: pointer;
    background-color: #ff4d4d;
    color: white;
    border: none;
    border-radius: 20px;
    padding: 10px;
    width: 250px;

    &:hover {
        opacity: 0.7;
    }
`;

const StContactButton = styled.div`
    display: flex;
    justify-content: center;
    cursor: pointer;
    background-color: #121212;
    color: white;
    border: none;
    border-radius: 20px;
    padding: 10px;
    width: 250px;

    &:hover {
        opacity: 0.7;
    }
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const SmallSpinner = styled.div`
    border: 2px solid #f3f3f3;
    border-top: 2px solid #121212;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    animation: ${spin} 2s linear infinite;
`;
