import { Outlet } from 'react-router-dom';
import styled from 'styled-components';

import Header from './shared/Header/Header';

export default function Layout() {
    return (
        <StLayoutContainer>
            <Header />
            <article>
                <Outlet />
            </article>
        </StLayoutContainer>
    );
}

const StLayoutContainer = styled.article`
    display: flex;
    flex-direction: column;
    height: 100%;

    article {
        margin-top: 80px;
    }
`;
