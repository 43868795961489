import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import mail from '../../../assets/mail.png';
import mailBlack from '../../../assets/mail-black.png';
import { Theme } from '../../../styles/theme';
import { media } from '../../../styles/MediaQueries';
import { useRecoilValue } from 'recoil';
import { userState } from '../../../shared/State/atom';
import { useNavigate } from 'react-router-dom';

interface SendButtonProps {
    navigateToSendMailPage?: () => void;
    type?: string;
    writerId?: string;
    cardId?: string;
    isSending?: boolean;
}

export default function SendButton({
    type,
    navigateToSendMailPage,
    writerId,
    cardId,
    isSending,
}: SendButtonProps) {
    const user = useRecoilValue(userState);
    const [isHovered, setIsHovered] = useState(false);
    const navigate = useNavigate();

    const handleClick = () => {
        if (user?.id === writerId) {
            navigate(`/investor-mypage/proposals/${cardId}`);
        } else if (user?.userType === 'INVESTOR') {
            navigate(`/chat/${writerId}`);
        } else if (navigateToSendMailPage) {
            navigateToSendMailPage();
        }
        window.scrollTo(0, 0);
    };

    // console.log(user);

    return (
        <StSendButton
            type='submit'
            onClick={handleClick}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            {isSending ? (
                <SmallSpinner />
            ) : (
                <p>
                    {user?.id === writerId
                        ? '검토 요청 확인하기'
                        : user?.userType === 'INVESTOR'
                        ? '투자자와 채팅하기'
                        : '우리 회사 검토 요청하기'}
                </p>
            )}
            <StMail src={isHovered ? mailBlack : mail} alt='mail' />
        </StSendButton>
    );
}
const StSendButton = styled.button`
    display: flex;
    width: 60%;
    height: 60px;
    padding: 18px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border: 2px solid ${Theme.colors.black};
    border-radius: 60px;
    background: #121212;
    box-shadow: 0px 12px 24px 0px rgba(0, 0, 0, 0.3);

    color: #fff;

    text-align: center;
    font-size: 18px;
    font-weight: 700;
    line-height: 18px;

    cursor: pointer;

    transition: all 0.2s ease;

    &:hover {
        background: transparent;
        border: 2px solid ${Theme.colors.black};
        color: ${Theme.colors.black};
    }

    ${media.tablet`
    width: 80%;
    `}

    ${media.mobile`
    width: 90%;
    `}
`;

const StMail = styled.img`
    width: 22px;
    height: 22px;
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const SmallSpinner = styled.div`
    border: 2px solid #f3f3f3;
    border-top: 2px solid #121212;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    animation: ${spin} 2s linear infinite;
`;
