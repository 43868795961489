import React from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { IoLogoLinkedin, IoLogoFacebook, IoMdShare } from 'react-icons/io';

export default function ShareModal() {
    const handleFacebookShare = () => {
        const shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
            window.location.href
        )}`;
        window.open(shareUrl, '_blank', 'width=600,height=400');
    };
    const handleLinkedinShare = () => {
        const shareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
            window.location.href
        )}`;
        window.open(shareUrl, '_blank', 'width=600,height=400');
    };
    const handleCopyUrl = () => {
        navigator.clipboard.writeText(window.location.href).then(() => {
            toast.success('URL이 복사되었습니다.');
        });
    };

    return (
        <StShareModal>
            <StLogoBox>
                <IoLogoFacebook size={20} />
                <div onClick={handleFacebookShare}>페이스북 공유</div>
            </StLogoBox>
            <StLogoBox>
                <IoLogoLinkedin size={20} />
                <div onClick={handleLinkedinShare}>링크드인 공유</div>
            </StLogoBox>
            <StLogoBox>
                <IoMdShare size={19} />
                <div onClick={handleCopyUrl}>URL 복사</div>
            </StLogoBox>
        </StShareModal>
    );
}

const StShareModal = styled.div`
    position: absolute;
    width: 160px;
    bottom: 130%;
    left: 50%;
    transform: translateX(-50%);
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    color: #121212;

    > div {
        padding: 10px;
        cursor: pointer;
        &:hover {
            background-color: #f5f5f5;
        }
    }
`;

const StLogoBox = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`;
