import { OnboardingFormData } from '../pages/LoginPage/FounderOnboarding';
import { api } from './api';

export const postOnboarding = async (formData: OnboardingFormData) => {
    const response = await api.post(`/auth/signup`, formData);
    return response;
};

export const postCode = async (businessEmail: string) => {
    try {
        await api.post('/auth/send-code', { businessEmail });
    } catch (error) {
        console.error('Error sending code:', error);
    }
};

export const verifyCode = async (businessEmail: string, code: string) => {
    try {
        const response = await api.post('/auth/verify-code', {
            businessEmail,
            code,
        });
        return response.data.hasError;
    } catch (error) {
        console.error('Error verifing code:', error);
    }
};
