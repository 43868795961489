import React from 'react';
import { Navigate } from 'react-router-dom';
import { getJwt } from '../shared/TokenUtils/TokenUtils';

interface ProtectedRouteProps {
    children: React.ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
    const jwt = getJwt();

    if (!jwt) {
        const currentPath = window.location.pathname;
        localStorage.setItem('preLoginPath', currentPath);
        return <Navigate to='/login' replace />;
    }

    return <>{children}</>;
};

export default ProtectedRoute;
