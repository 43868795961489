import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { FC, ReactNode } from 'react';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

type TanstackQueryProviderProps = {
    children: ReactNode;
};

const queryClient = new QueryClient();

export const TanstackQueryProvider: FC<TanstackQueryProviderProps> = ({
    children,
}) => {
    return (
        <QueryClientProvider client={queryClient}>
            {children}
            <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
    );
};
