import React, { useState } from 'react';
import styled from 'styled-components';
import logo from '../../assets/logo.png';
import logoText from '../../assets/logo-text.png';
import { media } from '../../styles/MediaQueries';
import { useNavigate } from 'react-router-dom';
import { postOnboarding } from '../../api/onboardingApi';

export interface OnboardingFormData {
    accessToken: string | null;
    isAgreeEmail: boolean;
    position: string;
    userType: string;
    name?: string;
    sector?: string;
    phoneNumber?: string;
    houseId?: string;
    businessEmail?: string;
    funnels?: string;
    round?: string;
}

export default function FounderOnboarding() {
    const navigate = useNavigate();
    const accessToken = localStorage.getItem('accessToken');
    const [formData, setFormData] = useState<OnboardingFormData>({
        accessToken,
        userType: 'FOUNDER',
        position: '',
        sector: '',
        round: '',
        funnels: '',
        isAgreeEmail: true,
    });
    const [isNewsletterSubscribed, setIsNewsletterSubscribed] = useState(true);

    const navigateToServiceTerm = () => {
        window.open(
            'https://decacorn-team.notion.site/fddf05944fbb4364bc931ae72c2c9558?pvs=4',
            '_blank'
        );
    };

    const navigateToPrivacyPolicy = () => {
        window.open(
            'https://decacorn-team.notion.site/0b29459b7429451e8db12359475d2acb?pvs=4',
            '_blank'
        );
    };

    const isNotValid =
        formData.position === '' ||
        formData.sector === '' ||
        formData.round === '' ||
        formData.funnels === '';

    const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const handleNewsletterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const isChecked = e.target.checked;
        setIsNewsletterSubscribed(isChecked);
        setFormData((prevFormData) => ({
            ...prevFormData,
            isAgreeEmail: isChecked,
        }));
    };

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        try {
            const response = await postOnboarding(formData);

            const newJwt = response.data.value.user.jwt;
            const newRefreshToken = response.data.value.user.refreshToken;
            localStorage.setItem('jwt', newJwt);
            localStorage.setItem('refreshToken', newRefreshToken);

            const previousPath = localStorage.getItem('preLoginPath') || '/';
            navigate(previousPath);
        } catch (error) {
            console.error('회원가입 실패:', error);
            alert('회원가입에 실패하였습니다.');
        }
    };

    // console.log(formData);

    return (
        <StContainer>
            <StLogoBox>
                <StLogoContainer>
                    <StLogoText src={logoText} alt='logo-text' />
                    <StLogo src={logo} alt='logo' />
                </StLogoContainer>
            </StLogoBox>
            <StFounderOnboardingPage>
                <StTitle>딱, 이것만 알려주세요!</StTitle>
                <StSelect>
                    <label htmlFor='position'>직책 *</label>
                    <select
                        id='position'
                        name='position'
                        value={formData.position}
                        onChange={handleSelectChange}
                        required
                    >
                        <option value='' disabled selected hidden>
                            선택해주세요
                        </option>
                        <option value='스타트업 대표'>스타트업 대표</option>
                        <option value='스타트업 종사자'>스타트업 종사자</option>
                        <option value='대기업 종사자'>대기업 종사자</option>
                        <option value='예비창업가'>예비창업가</option>
                        <option value='기타'>기타</option>
                    </select>
                </StSelect>
                <StSelect>
                    <label htmlFor='sector'>섹터 *</label>
                    <select
                        id='sector'
                        name='sector'
                        value={formData.sector}
                        onChange={handleSelectChange}
                        required
                    >
                        <option value='' disabled selected hidden>
                            선택해주세요
                        </option>
                        <option value='AR/VR'>AR/VR</option>
                        <option value='핀테크'>핀테크</option>
                        <option value='게임'>게임</option>
                        <option value='SaaS'>SaaS</option>
                        <option value='에듀테크'>에듀테크</option>
                        <option value='로보틱스'>로보틱스</option>
                        <option value='AI'>AI</option>
                        <option value='프롭테크'>프롭테크</option>
                        <option value='블록체인'>블록체인</option>
                        <option value='소셜미디어'>소셜미디어</option>
                        <option value='커뮤니티'>커뮤니티</option>
                        <option value='여행/레저'>여행/레저</option>
                        <option value='시니어'>시니어</option>
                        <option value='환경/ESG'>환경/ESG</option>
                        <option value='제조/하드웨어'>제조/하드웨어</option>
                        <option value='커머스'>커머스</option>
                        <option value='콘텐츠'>콘텐츠</option>
                        <option value='애그리 테크'>애그리 테크</option>
                        <option value='웰니스'>웰니스</option>
                        <option value='펫테크'>펫테크</option>
                        <option value='라이프 스타일'>라이프 스타일</option>
                        <option value='헬스케어/바이오'>헬스케어/바이오</option>
                        <option value='WEB 3.0'>WEB 3.0</option>
                        <option value='기타'>기타</option>
                    </select>
                </StSelect>
                <StSelect>
                    <label htmlFor='round'>현재 투자 단계 *</label>
                    <select
                        id='round'
                        name='round'
                        value={formData.round}
                        onChange={handleSelectChange}
                        required
                    >
                        <option value='' disabled selected hidden>
                            선택해주세요
                        </option>
                        <option value='연관 없음'>연관 없음</option>
                        <option value='투자유치 전'>투자유치 전</option>
                        <option value='pre-Seed'>pre-Seed</option>
                        <option value='Seed'>Seed</option>
                        <option value='pre-A'>pre-A</option>
                        <option value='Series-A'>Series-A</option>
                        <option value='Series-B'>Series-B</option>
                        <option value='Series-C'>Series-C</option>
                        <option value='pre-IPO'>pre-IPO</option>
                        <option value='IPO'>IPO</option>
                    </select>
                </StSelect>
                <StSelect>
                    <label htmlFor='funnels'>
                        어떻게 ‘Seek’을 알게되셨나요? *
                    </label>
                    <select
                        id='funnels'
                        name='funnels'
                        value={formData.funnels}
                        onChange={handleSelectChange}
                        required
                    >
                        <option value='' disabled selected hidden>
                            선택해주세요
                        </option>
                        <option value='카카오톡 오픈채팅방'>
                            카카오톡 오픈채팅방
                        </option>
                        <option value='지인 추천'>지인 추천</option>
                        <option value='디스콰이엇'>디스콰이엇</option>
                        <option value='이오플래닛'>이오플래닛</option>
                        <option value='검색'>검색</option>
                        <option value='광고'>광고</option>
                        <option value='기타'>기타</option>
                    </select>
                </StSelect>
                <StFooter>
                    <StAgreementContainer>
                        <StCheckBoxContainer>
                            <input
                                type='checkbox'
                                checked={isNewsletterSubscribed}
                                onChange={handleNewsletterChange}
                            />
                            <span>Seek 뉴스레터 수신 동의</span>
                        </StCheckBoxContainer>
                        <StPolicyText>
                            동의하고 시작하기를 누르는 것으로{' '}
                            <span onClick={navigateToServiceTerm}>
                                Seek 이용 약관
                            </span>
                            과{' '}
                            <span onClick={navigateToPrivacyPolicy}>
                                개인정보 처리방침
                            </span>
                            에 동의합니다.
                        </StPolicyText>
                    </StAgreementContainer>
                    <StStartButton disabled={isNotValid} onClick={handleSubmit}>
                        동의하고 시작하기
                    </StStartButton>
                </StFooter>
            </StFounderOnboardingPage>
        </StContainer>
    );
}

const StContainer = styled.div`
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    height: 100%;
`;

const StLogoBox = styled.div`
    position: absolute;
    top: 20px;
    left: 3vw;
`;

const StLogoContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 50px;
`;
const StLogoText = styled.img`
    width: auto;
    height: 30px;
    flex-shrink: 0;
`;
const StLogo = styled.img`
    width: 30px;
    height: 30px;
    flex-shrink: 0;
`;

const StFounderOnboardingPage = styled.form`
    margin-top: 100px;
    margin-bottom: 100px;
    width: 70%;
    min-height: 100px;
    border-radius: 20px;
    padding: 86px 10px 60px 10px;
    border: 1px solid #c5c5c5;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;

    @media (max-width: 930px) {
        width: 90%;
    }
`;

const StTitle = styled.div`
    color: #121212;
    font-size: 25px;
    font-weight: 500;

    ${media.mobile`
    font-size: 20px;
    `}
`;

const StSelect = styled.div`
    display: flex;
    width: 60%;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 25px;

    label {
        font-size: 18px;
        font-weight: 700;
    }

    select {
        padding: 20px;
        font-size: 15px;
        font-weight: 300;
        height: 60px;
        border-radius: 8px;
        border: 0.5px solid #828282;
        box-shadow: 0px 1.5px 0px 0px rgba(0, 0, 0, 0.2);
        cursor: pointer;

        &:hover {
            box-shadow: 0px 2.5px 3px 0px rgba(0, 0, 0, 0.2);
        }

        &:focus {
            outline: 3px solid #bababa;
        }
    }

    ${media.tablet`
        width: 80%;
        `}
    ${media.mobile`
        width: 90%;
        `}
`;
const StFooter = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 60%;

    ${media.tablet`
        width: 80%;
        `}
    ${media.mobile`
        width: 90%;
        `}
`;

const StAgreementContainer = styled.div`
    min-width: 310px;
    display: flex;
    flex-direction: column;
    justify-content: baseline;
    width: 100%;
`;

const StCheckBoxContainer = styled.div`
    font-size: 11px;
    display: flex;
    gap: 5px;
    align-items: center;
    padding-left: 2px;
    span {
    }
    ${media.mobile`
   font-size: 9.5px;
    `}
`;
const StPolicyText = styled.p`
    padding-left: 5px;
    font-size: 11px;
    margin-bottom: 10px;
    span {
        text-decoration: underline;
        cursor: pointer;

        &:hover {
            color: black;
        }
    }

    ${media.mobile`
   font-size: 9.5px;
    `}
`;

const StStartButton = styled.button`
    cursor: pointer;
    background-color: #121212;
    padding: 13px;
    font-size: 15px;
    color: white;
    border: none;
    border-radius: 30px;
    width: 100%;
    min-width: 310px;

    &:hover {
        opacity: 0.7;
    }

    &:disabled {
        cursor: default;
        background-color: #f1f1f1;
        color: #828282;

        &:hover {
            opacity: 1;
        }
    }
`;
