import React, { useState } from 'react';
import styled from 'styled-components';
import reply from '../../../assets/reply.png';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { postComment } from '../../../api/cardApi';
import { toast } from 'react-toastify';
import { getJwt } from '../../../shared/TokenUtils/TokenUtils';

interface CommentInputProps {
    cardId: string | undefined;
    parentCommentId?: string;
    onReplySubmitted?: () => void;
}

export default function CommentInput({
    cardId,
    parentCommentId,
    onReplySubmitted,
}: CommentInputProps) {
    const [comment, setComment] = useState('');
    const queryClient = useQueryClient();
    const jwt = getJwt();
    const isLogin = !!jwt;

    const mutation = useMutation({
        mutationFn: postComment,
        onSuccess: () => {
            if (cardId) {
                queryClient.invalidateQueries({
                    queryKey: ['comments', cardId],
                });

                setTimeout(() => {
                    const commentsContainer = document.querySelector(
                        '#comments-container'
                    );
                    if (commentsContainer) {
                        commentsContainer.scrollTop =
                            commentsContainer.scrollHeight;
                    }
                }, 500);
            }
        },
    });

    const handleSubmit = () => {
        if (!isLogin) {
            // 로그인하지 않은 경우 토스트 메시지 표시
            toast.info('로그인이 필요한 서비스입니다', {
                position: 'top-right',
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
            });
            return;
        }

        // 로그인한 경우 댓글 작성 로직 수행
        mutation.mutate(
            { cardId, comment, parentCommentId },
            {
                onSuccess: () => {
                    setComment('');
                    if (onReplySubmitted) {
                        onReplySubmitted();
                    }
                },
            }
        );
    };

    return (
        <StContainer>
            <StCommntInputContainer
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                placeholder='댓글을 입력하세요...'
            />
            <StReplyIcon src={reply} alt='reply' onClick={handleSubmit} />
        </StContainer>
    );
}

const StContainer = styled.div`
    position: relative;
    padding-left: 5px;
    width: 100%;
`;

const StCommntInputContainer = styled.textarea`
    width: 100%;
    height: 80px;
    padding: 15px;
    padding-right: 40px;
    flex-direction: column;
    gap: 4px;
    border-radius: 16px;
    background: #f1f1f1;
    border: none;
    resize: none;
    outline: none;
    font-size: 15px;

    &::placeholder {
        padding-top: 0;
        color: rgba(18, 18, 18, 0.5);
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
    }
`;

const StReplyIcon = styled.img`
    position: absolute;
    right: 12px;
    bottom: 12px;
    width: 24px;
    height: 24px;
    cursor: pointer;

    &:hover {
        filter: opacity(0.5);
    }
`;
