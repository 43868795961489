import React, { useRef, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { media } from '../../../styles/MediaQueries';
import { useQuery } from '@tanstack/react-query';

import { UserData } from '../../Type/type';
import { useNavigate } from 'react-router-dom';
import {
    getNotifications,
    readNotification,
    readAllNotifications,
} from '../../../api/NotificationApi';

function timeAgo(createdAt: string) {
    const now = new Date();
    const createdDate = new Date(createdAt);
    const diffInSeconds = Math.floor(
        (now.getTime() - createdDate.getTime()) / 1000
    );

    if (diffInSeconds < 60) {
        return `방금 전`;
    }

    const diffInMinutes = Math.floor(diffInSeconds / 60);
    if (diffInMinutes < 60) {
        return `${diffInMinutes}분 전`;
    }

    const diffInHours = Math.floor(diffInMinutes / 60);
    if (diffInHours < 24) {
        return `${diffInHours}시간 전`;
    }

    const diffInDays = Math.floor(diffInHours / 24);
    if (diffInDays < 30) {
        return `${diffInDays}일 전`;
    }
}

interface Notification {
    id: string;
    sender: UserData;
    type: string;
    createdAt: string;
    contentId: string;
    isRead: boolean;
}

interface NotificationModalProps {
    setIsNotificationModalOpen: (isOpen: boolean) => void;
}

export default function NotificationModal({
    setIsNotificationModalOpen,
}: NotificationModalProps) {
    const modalRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (
                modalRef.current &&
                !modalRef.current.contains(event.target as Node)
            ) {
                setIsNotificationModalOpen(false);
            }
        }

        // 이벤트 리스너 추가
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // 컴포넌트 언마운트 시 이벤트 리스너 제거
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [setIsNotificationModalOpen]);

    const navigate = useNavigate();
    const {
        isLoading,
        isError,
        data: notifications,
        error,
        isSuccess,
        refetch,
    } = useQuery({
        queryKey: ['notifications'],
        queryFn: () => getNotifications(),
    });

    const getTypeText = (type: string) => {
        switch (type) {
            case 'CHAT':
                return '메세지';
            case 'PROPOSAL_REQUEST':
                return '검토 요청';
            case 'PROPOSAL_RESPONSE':
                return '검토 요청 결과';
            default:
                return type;
        }
    };

    const handleItemClick = (
        notification: Notification,
        cardId: string,
        partnerUserId: string
    ) => {
        if (notification.type === 'PROPOSAL_REQUEST') {
            navigate(`/investor-mypage/proposals/${cardId}`);
            window.scrollTo(0, 0);
        }
        if (notification.type === 'PROPOSAL_RESPONSE') {
            navigate(`/founder-mypage/send-requests`);
            window.scrollTo(0, 0);
        }
        if (notification.type === 'CHAT') {
            navigate(`/chat/${partnerUserId}`);
            window.scrollTo(0, 0);
        }
        if (!notification.isRead) {
            readNotification(notification.id);
        }
        setIsNotificationModalOpen(false);
    };

    const handleReadAllClick = async () => {
        await readAllNotifications();
        refetch();
    };

    if (isLoading) {
        return (
            <StNotificationModal>
                <StSpinnerContainer>
                    <Spinner />
                </StSpinnerContainer>
            </StNotificationModal>
        );
    }

    if (isError) {
        return <span>Error: {error.message}</span>;
    }

    if (notifications.length === 0) {
        return (
            <StNone>
                <div>새로운 소식이 없습니다.</div>
            </StNone>
        );
    }

    // console.log(notifications);

    return (
        <StNotificationModal ref={modalRef}>
            <StReadAll onClick={handleReadAllClick}>모두 읽음 처리</StReadAll>
            {notifications.map((notification: Notification, index: number) => {
                const postPosition =
                    notification.type === 'PROPOSAL_REQUEST' ? '이' : '가';

                return (
                    <React.Fragment key={notification.id}>
                        <StNotificationItem
                            key={notification.id}
                            onClick={() =>
                                handleItemClick(
                                    notification,
                                    notification.contentId,
                                    notification.sender.id
                                )
                            }
                        >
                            <StImg src={notification.sender.profileUrl} />
                            <StInformationContainer>
                                <StContent>
                                    <StStrongText>
                                        {notification.sender.name}
                                    </StStrongText>
                                    님으로부터{' '}
                                    <StStrongText>
                                        {getTypeText(notification.type)}
                                    </StStrongText>
                                    {`${postPosition} 도착했어요!`}
                                </StContent>
                                <StTime>
                                    {timeAgo(notification.createdAt)}
                                </StTime>
                            </StInformationContainer>
                            {!notification.isRead && <StUnRead />}
                        </StNotificationItem>
                        {index < notifications.length - 1 && <StDivider />}
                    </React.Fragment>
                );
            })}
        </StNotificationModal>
    );
}

const StNone = styled.div`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #747474;
    width: 350px;
    height: 100px;
    z-index: 999;
    border-radius: 12px;
    background-color: #fff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    right: -50px;
    top: 40px;

    ${media.mobile`
    right: -70px;
    `}
`;

const StNotificationModal = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 350px;
    max-height: 400px;
    overflow-y: auto;
    padding: 16px 12px 22px 20px;
    z-index: 10000;
    border-radius: 12px;
    background-color: #fff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    right: -50px;
    top: 40px;

    ${media.mobile`
    right: -70px;
    `}
`;

const StReadAll = styled.div`
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    font-size: 13px;
    margin-bottom: 15px;
    letter-spacing: -0.7px;
    text-decoration: underline;
    color: #4b4b4b;

    &:hover {
        opacity: 0.7;
    }
`;

const StNotificationItem = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    position: relative;
    gap: 12px;
    width: 100%;

    &:hover {
        opacity: 0.5;
    }
`;

const StImg = styled.img`
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
`;

const StInformationContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const StContent = styled.div`
    width: 250px;
`;

const StStrongText = styled.span`
    font-weight: 600;
`;

const StTime = styled.div`
    font-size: 12px;
    color: #7b7b7b;
`;

const StUnRead = styled.div`
    position: absolute;
    right: 2px;
    width: 6px;
    height: 6px;
    border: none;
    border-radius: 50%;
    background-color: #ff4848;
`;

const StDivider = styled.hr`
    border: none;
    height: 0.2px;
    background-color: #d7d7d7;
    margin: 10px 0;
`;

const StSpinnerContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

// 스피너 스타일링
const Spinner = styled.div`
    border: 2px solid #f3f3f3;
    border-top: 2px solid #121212;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    animation: ${spin} 2s linear infinite;
`;
