import { ThemeType } from './type';

export const Theme: ThemeType = {
    media: {
        mobile: '680px',
        tablet: '990px',
    },
    colors: {
        purple: '#8f62f4',
        blue: '#5782FA',
        blueGray: '#F0F1F5',
        gray1: '#E0E3EB',
        gray2: '#C2C8D6',
        gray3: '#A3ACC2',
        gray4: '#8A8FA8',
        gray5: '#73738C',
        gray6: '#5C5C70',
        gray7: '#454554',
        gray8: '#292F3D',
        white: '#F1F1F1',
        black: '#121212',
        deem: 'rgba(41, 47, 61, 0.40)',
    },
    fontSizes: {
        h1: '25px',
        h2: '18px',
        h3: '15px',
        body1: '18px',
        body2: '16px',
        body3: '14px',
        body4: '15px',
        label1: '13px',
        label2: '12px',
        label3: '12px',
        mH1: '18px',
        mH2: '14px',
        mH3: '13px',
        mBody1: '12px',
        mBody2: '12px',
        mBody3: '12px',
        mL1: '10px',
    },
    fontWeights: {
        h1: '600',
        h2: '700',
        h3: '600',
        body1: '500',
        body2: '500',
        body3: '500',
        body4: '300',
        label1: '300',
        label2: '300',
        label3: '500',
        mH1: '700',
        mH2: '600',
        mH3: '600',
        mBody1: '500',
        mBody2: '400',
        mBody3: '300',
        mL1: '300',
    },
    textDecoration: {
        label3: 'underline',
    },
};
