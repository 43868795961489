import React from 'react';
import styled, { keyframes } from 'styled-components';

export default function SkeletonCardList() {
    return (
        <StSkeletonCardList>
            {Array(8)
                .fill(0)
                .map((_, index) => (
                    <StSkeletonCard key={index} />
                ))}
        </StSkeletonCardList>
    );
}

const shimmer = keyframes`
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
`;

const StSkeletonCard = styled.div`
    width: 300px;
    height: 300px;
    border-radius: 20px;
    background-color: #d8d8d8;
    background-image: linear-gradient(
        to right,
        #d8d8d8 0%,
        #e0e0e0 20%,
        #d8d8d8 40%,
        #d8d8d8 100%
    );
    background-repeat: no-repeat;
    background-size: 800px 104px;
    display: inline-block;
    position: relative;
    overflow: hidden;
    animation: ${shimmer} 1.5s infinite linear;
`;

const StSkeletonCardList = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
`;
