import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { media } from '../../styles/MediaQueries';
import CardList from './components/CardList/CardList';
import { useQuery } from '@tanstack/react-query';
import { getCards } from '../../api/cardApi';
import SkeletonCardList from './components/CardList/SkeletonCardList';
import Banner from './components/Banner/Banner';
import ContactVC from './components/ContactVC/ContactVC';
import { getJwt } from '../../shared/TokenUtils/TokenUtils';
import Footer from '../../shared/Footer/Footer';
import TallySurvey from './components/Survey/Tally';
import { getUserInfo } from '../../api/userApi';

export default function LandingPage() {
    const jwt = getJwt();
    const isLogin = !!jwt;

    const {
        data: cards,
        isLoading,
        isError,
        error,
    } = useQuery({
        queryKey: ['cards'],
        queryFn: getCards,
    });

    if (isError) {
        return <span>Error: {error.message}</span>;
    }

    // console.log(cards);

    return (
        <StLandingPageContainer $isLogin={isLogin}>
            <StTextContainer>
                <h2>
                    <p>
                        What you <span>seek</span> is
                    </p>
                    <p>
                        <span>seeking</span> you
                    </p>
                </h2>
                <p>VC와 스타트업이 가장 빠르게 연결되는 곳</p>
            </StTextContainer>
            {isLoading ? <SkeletonCardList /> : <CardList cards={cards} />}
            {/* <ContactVC /> */}
            <TallySurvey isLogin={isLogin} />
            <Footer />
            {!isLogin && <Banner />}
        </StLandingPageContainer>
    );
}

interface StLandingPageContainerProps {
    $isLogin: boolean;
}

const StLandingPageContainer = styled.div<StLandingPageContainerProps>`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: ${({ $isLogin }) => ($isLogin ? '0px' : '140px')};
`;

const StTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 80px 0px 80px 0px;
    gap: 20px;

    color: #121212;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Pretendard;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;

    ${media.mobile`
        font-size: 16px;
  `}

    h2 {
        font-family: Red Hat Display;
        font-size: 70px;
        line-height: 120%;
        font-family: 'Red Hat Display', sans-serif;

        ${media.mobile`
font-size: 40px;
  `}

        span {
            font-style: italic;
            font-weight: 700;
        }
    }
`;
