import React, { useRef, useState, useCallback, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import TrendingBoardsView from './views/TrendingBoardsView/TrendingBoardsView';
import CardDetailView from './views/CardDetailView/CardDetailView';
import SendButton from './components/SendButton';
import clap from '../../assets/clap.png';
import comment from '../../assets/comment.png';
import share from '../../assets/share.png';
import { CommentView } from './views/CommentView/CommentView';
import { media } from '../../styles/MediaQueries';
import { useQuery, useMutation } from '@tanstack/react-query';
import { getCardDetail } from '../../api/cardApi';
import { useNavigate, useParams } from 'react-router-dom';
import { patchClap } from '../../api/cardApi';
import ShareModal from './components/ShareModal';
import ReactCanvasConfetti from 'react-canvas-confetti';

const formatCount = (count: number) => {
    return count > 999 ? `${(count / 1000).toFixed(1)}k` : count;
};

const canvasStyles: React.CSSProperties = {
    position: 'fixed',
    pointerEvents: 'none',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
};

export default function DetailPage() {
    const { cardId } = useParams();
    const commentViewRef = useRef<HTMLDivElement>(null);
    const [showShareModal, setShowShareModal] = useState(false);
    const navigate = useNavigate();
    const confettiRef = useRef<any>();
    const [clapCount, setClapCount] = useState<number>(0);

    const getInstance = useCallback((instance: any) => {
        confettiRef.current = instance;
    }, []);

    const makeShot = useCallback((particleRatio: number, opts: any) => {
        confettiRef.current &&
            confettiRef.current({
                ...opts,
                origin: { y: 0.7 },
                particleCount: Math.floor(200 * particleRatio),
            });
    }, []);

    const fire = useCallback(() => {
        makeShot(0.25, { spread: 26, startVelocity: 55 });
        makeShot(0.2, { spread: 60 });
        makeShot(0.35, { spread: 100, decay: 0.91, scalar: 0.8 });
        makeShot(0.1, {
            spread: 120,
            startVelocity: 25,
            decay: 0.92,
            scalar: 1.2,
        });
        makeShot(0.1, { spread: 120, startVelocity: 45 });
    }, [makeShot]);

    const navigateToSendMailPage = () => {
        navigate(`/contact/${cardId}`);
        window.scrollTo(0, 0);
    };

    const handleShareClick = () => {
        setShowShareModal(!showShareModal);
    };

    const scrollToComments = () => {
        commentViewRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    const {
        isLoading,
        isError,
        data: cardDetail,
        error,
        isSuccess,
    } = useQuery({
        queryKey: ['CardDetail', cardId],
        queryFn: () => getCardDetail(cardId),
        enabled: !!cardId,
    });

    useEffect(() => {
        if (isSuccess && cardDetail) {
            setClapCount(cardDetail.clapCount);
        }
    }, [isSuccess]);

    const mutation = useMutation({
        mutationFn: patchClap,
        onSuccess: () => {
            setClapCount((prevCount) => prevCount + 1);
        },
    });

    const handleClap = () => {
        if (cardId) {
            mutation.mutate(cardId);
            fire();
        }
    };

    if (isLoading) {
        return <Spinner />;
    }

    if (isError) {
        return <span>Error: {error.message}</span>;
    }

    // console.log(cardDetail);

    return (
        <StDetailPageContainer>
            <CardDetailView cardDetail={cardDetail} />
            <SendButton
                navigateToSendMailPage={navigateToSendMailPage}
                writerId={cardDetail.writer.id}
                cardId={cardDetail.id}
            />
            <CommentView ref={commentViewRef} />
            <TrendingBoardsView />

            <StShareButtonContainer>
                <StClapContainer onClick={handleClap}>
                    <StClap src={clap} alt='clap' />
                    <div>{formatCount(clapCount)}</div>
                </StClapContainer>
                <StCommentContainer onClick={scrollToComments}>
                    <StComment src={comment} alt='reply' />
                    <div>{cardDetail.commentCount}</div>
                </StCommentContainer>
                <StShareContainer onClick={handleShareClick}>
                    <StShareBox>
                        <StShare src={share} alt='share' />
                        <div>공유하기</div>
                    </StShareBox>
                    {showShareModal && <ShareModal />}
                </StShareContainer>
            </StShareButtonContainer>
            <ReactCanvasConfetti
                refConfetti={getInstance}
                style={canvasStyles}
            />
        </StDetailPageContainer>
    );
}

const StDetailPageContainer = styled.div`
    margin: 40px 0px 100px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 28px;
    overscroll-behavior: none;
`;

const StShareButtonContainer = styled.div`
    position: fixed;
    display: flex;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    max-width: 865px;
    width: 60%;
    height: 60px;
    padding: 18px 20px;
    justify-content: center;
    align-items: center;
    gap: 50px;
    flex-shrink: 0;
    z-index: 990;

    border-radius: 60px;
    background: #121212;
    box-shadow: 0px 12px 24px 0px rgba(0, 0, 0, 0.3);

    color: #fff;
    font-size: 15px;
    font-weight: 400;
    line-height: 18px;
    ${media.tablet`
    width: 80%;
    `}

    ${media.mobile`
    width: 90%;
    `}
`;

const StClapContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2px;
    cursor: pointer;
    &:hover {
        div {
            color: #ffe458;
        }
    }
`;
const StClap = styled.img`
    width: 28px;
    height: 28px;
`;

const StCommentContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2px;
    cursor: pointer;
    &:hover {
        div {
            color: #ffe458;
        }
    }
`;

const StComment = styled.img`
    width: 28px;
    height: 28px;
`;

const StShareContainer = styled.div`
    position: relative;
`;

const StShareBox = styled.div`
    display: flex;
    padding: 4px 14px 4px 10px;
    justify-content: center;
    align-items: center;
    gap: 2px;
    border-radius: 100px;
    border: 1px solid #ffe458;
    color: #ffe458;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    cursor: pointer;

    &:hover {
        filter: opacity(0.7);
    }
`;

const StShare = styled.img`
    width: 28px;
    height: 28px;
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

// 스피너 스타일링
const Spinner = styled.div`
    border: 5px solid #f3f3f3;
    border-top: 5px solid #888888;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    animation: ${spin} 2s linear infinite;
    margin: 300px auto;
`;
