import React from 'react';
import styled from 'styled-components';
import amount from '../../../../assets/amount.png';
import round from '../../../../assets/round.png';
import requirement from '../../../../assets/requirement.png';
import { SectionItem } from './components/SectionItem';
import Tag from '../../../../shared/Tag/Tag';
import { media } from '../../../../styles/MediaQueries';
import RequestLogin from './components/RequestLogin';
import { CardData } from '../../../../shared/Type/type';
import { getJwt } from '../../../../shared/TokenUtils/TokenUtils';

interface CardDetailViewProps {
    cardDetail: CardData;
}

export default function CardDetailView({ cardDetail }: CardDetailViewProps) {
    const jwt = getJwt();
    const isLoggedIn = !!jwt;

    return (
        <StCardDetailViewContainer>
            <StTopSection>
                <StSection1>
                    <h1>{cardDetail.title}</h1>
                    <StVCContainer>
                        <StLogoContainer>
                            <img
                                src={cardDetail.house.profileUrl}
                                alt='심사역 로고'
                            />
                        </StLogoContainer>
                        <StHouseName>{cardDetail?.house.name}</StHouseName>
                    </StVCContainer>
                </StSection1>
                <StDesktopOnly>
                    <SectionItem
                        icon={amount}
                        title='투자 금액'
                        content={`${cardDetail.minInvestAmount} ~ ${cardDetail.maxInvestAmount}억원`}
                        isLoggedIn={isLoggedIn}
                    />
                    <SectionItem
                        icon={round}
                        title='투자 단계'
                        content={cardDetail.investmentRounds}
                        isLoggedIn={isLoggedIn}
                    />
                    <SectionItem
                        icon={requirement}
                        title='투자 우대 조건'
                        content={cardDetail.investmentRequirements}
                        isLoggedIn={isLoggedIn}
                    />
                    {!isLoggedIn && <RequestLogin />}
                </StDesktopOnly>
            </StTopSection>
            <StSection3>
                <Tag label={cardDetail.tag} />
                <StPurposeMessageContainer>
                    <StBottomSection>
                        <StContentTitle>
                            왜 이런 회사를 찾고 있는지 궁금하시죠?
                        </StContentTitle>
                        <p>{cardDetail.purpose}</p>
                    </StBottomSection>
                    <StBottomSection>
                        <StContentTitle>
                            창업가에게 전하고 싶은 한마디
                        </StContentTitle>
                        <p>{cardDetail.messageToEntrepreneur}</p>
                    </StBottomSection>
                </StPurposeMessageContainer>
            </StSection3>
            <StMobileOnly>
                <StSection2>
                    <SectionItem
                        icon={amount}
                        title='투자 금액'
                        content={`${cardDetail.minInvestAmount} ~ ${cardDetail.maxInvestAmount}억원`}
                        isLoggedIn={isLoggedIn}
                    />
                    <SectionItem
                        icon={round}
                        title='투자 단계'
                        content={cardDetail?.investmentRounds}
                        isLoggedIn={isLoggedIn}
                    />
                    <SectionItem
                        icon={requirement}
                        title='투자 우대 조건'
                        content={cardDetail?.investmentRequirements}
                        isLoggedIn={isLoggedIn}
                    />
                    {!isLoggedIn && <RequestLogin />}
                </StSection2>
            </StMobileOnly>
        </StCardDetailViewContainer>
    );
}

const StCardDetailViewContainer = styled.div`
    display: flex;
    width: 60%;
    padding: 28px;
    flex-direction: column;
    gap: 20px;
    border-radius: 28px;
    border: 1px solid #fff;
    background: #fff388;
    background-image: repeating-linear-gradient(
        0deg,
        transparent,
        transparent 33px,
        #ffea61 33px,
        #ffea61 35px
    );
    box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.15);
    line-height: 1.3;
    ${media.tablet`
    width: 80%;
    `}

    ${media.mobile`
    width: 90%;
    padding: 10px;
    gap: 10px;
    border: none;
    `}
`;

const StTopSection = styled.div`
    display: flex;
    justify-content: center;
    gap: 20px;

    h4 {
        font-size: 18px;
        font-weight: 700;
        line-height: 140%; /* 25.2px */
    }

    ${media.mobile`
    flex-direction: column;
    gap: 10px;
    `}
`;

const StSection1 = styled.div`
    display: flex;
    width: 50%;
    min-height: 310px;
    padding: 28px;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    border-radius: 20px;
    background: #fffbd2;
    word-break: break-word;
    white-space: pre-line;

    h1 {
        color: #121212;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%; /* 42px */
    }

    ${media.mobile`
    width: 100%;
    `}
`;

const StVCContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;

    font-size: 18px;
    line-height: 140%;
`;
const StLogoContainer = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`;

const StHouseName = styled.p`
    font-weight: 600;
`;

const StSection2 = styled.div`
    display: flex;
    width: 50%;
    min-height: 310px;
    padding: 28px;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    border-radius: 20px;
    background: #fffbd2;
    position: relative;

    h4 {
        font-size: 16px;
        font-weight: 700;
        line-height: 140%; /* 25.2px */
    }

    ${media.mobile`
    width: 100%;
    `};
`;

const StSection3 = styled.div`
    display: flex;

    gap: 16px;
    width: 100%;
    min-height: 360px;
    padding: 28px;
    flex-direction: column;

    align-items: flex-start;
    border-radius: 20px;
    background: #fffbd2;
    position: relative;

    text {
        word-break: break-word;
        color: #121212;
        font-size: 16px;
        font-weight: 400;
        line-height: 160%;
        white-space: pre-line;
    }
`;
const StPurposeMessageContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-top: 20px;
`;

const StContentTitle = styled.p`
    font-size: 18px;
    font-weight: 700;
`;

const StBottomSection = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    word-break: break-word;
    white-space: pre-line;
`;

const StDesktopOnly = styled.div`
    display: block;
    display: flex;
    width: 50%;
    min-height: 310px;
    padding: 28px;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    border-radius: 20px;
    background: #fffbd2;
    position: relative;

    ${media.mobile`
    width: 100%;
    `};

    ${media.mobile`
        display: none;
    `}
`;

const StMobileOnly = styled.div`
    display: none;

    ${media.mobile`
        display: block;
    `}
`;
