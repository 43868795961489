import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import logo from '../../assets/logo.png';
import logoText from '../../assets/logo-text.png';
import { media } from '../../styles/MediaQueries';
import { OnboardingFormData } from './FounderOnboarding';
import { postCode, postOnboarding, verifyCode } from '../../api/onboardingApi';
import { getHouses } from '../../api/houseApi';
import { useNavigate } from 'react-router-dom';

interface House {
    id: string;
    name: string;
    homepage: string;
}

export default function InvestorOnboarding() {
    const navigate = useNavigate();
    const [verificationStatus, setVerificationStatus] = useState('pending');
    const [isCodeSent, setIsCodeSent] = useState(false);
    const [isSendingCode, setIsSendingCode] = useState(false);
    const [timer, setTimer] = useState('');
    const [intervalId, setIntervalId] = useState<number | undefined>(undefined);
    const startTimer = () => {
        const endTime = Date.now() + 5 * 60 * 1000;

        const id = window.setInterval(() => {
            const remaining = endTime - Date.now();

            if (remaining <= 0) {
                clearInterval(id);
                setTimer('0:00');
                return;
            }

            const minutes = Math.floor(remaining / 60000);
            const seconds = Math.floor((remaining % 60000) / 1000);
            setTimer(`${minutes}:${seconds < 10 ? '0' : ''}${seconds}`);
        }, 1000);

        setIntervalId(id);
    };

    useEffect(() => {
        return () => clearInterval(intervalId);
    }, [intervalId]);

    const accessToken = localStorage.getItem('accessToken');
    const [emailId, setEmailId] = useState<string>('');
    const [code, setCode] = useState<string>('');
    const [formData, setFormData] = useState<OnboardingFormData>({
        accessToken,
        userType: 'INVESTOR',
        name: '',
        position: '',
        phoneNumber: '',
        houseId: '',
        businessEmail: '',
        isAgreeEmail: true,
    });
    const [isNewsletterSubscribed, setIsNewsletterSubscribed] = useState(true);
    const [selectedHouseHomepage, setSelectedHouseHomepage] = useState('');

    const [houses, setHouses] = useState<House[]>([]);
    const [filteredHouses, setFilteredHouses] = useState<House[]>([]);
    const [selectedHouseName, setSelectedHouseName] = useState('');
    useEffect(() => {
        async function fetchHouses() {
            try {
                const housesList = await getHouses();
                setHouses(housesList as House[]);
            } catch (error) {
                console.error('Error fetching houses:', error);
            }
        }
        fetchHouses();
    }, []);
    const handleHouseNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const searchQuery = e.target.value;
        setSelectedHouseName(searchQuery); // 추가된 부분

        const matchedHouses = houses.filter((house) =>
            house.name.toLowerCase().includes(searchQuery.toLowerCase())
        );
        setFilteredHouses(matchedHouses);
    };

    const handleHouseSelect = (
        houseId: string,
        houseName: string,
        houseHomepage: string
    ) => {
        setFormData({
            ...formData,
            houseId: houseId,
        });
        setSelectedHouseName(houseName);
        setSelectedHouseHomepage(houseHomepage); // 추가된 부분
        setFilteredHouses([]);
    };

    useEffect(() => {
        if (emailId && selectedHouseHomepage) {
            setFormData((prevFormData) => ({
                ...prevFormData,
                businessEmail: `${emailId}@${selectedHouseHomepage}`,
            }));
        }
    }, [emailId, selectedHouseHomepage]);

    const navigateToServiceTerm = () => {
        window.open(
            'https://decacorn-team.notion.site/fddf05944fbb4364bc931ae72c2c9558?pvs=4',
            '_blank'
        );
    };

    const navigateToPrivacyPolicy = () => {
        window.open(
            'https://decacorn-team.notion.site/0b29459b7429451e8db12359475d2acb?pvs=4',
            '_blank'
        );
    };

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmailId(e.target.value);
    };

    const handleCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCode(e.target.value);
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const handleNewsletterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const isChecked = e.target.checked;
        setIsNewsletterSubscribed(isChecked);
        setFormData((prevFormData) => ({
            ...prevFormData,
            isAgreeEmail: isChecked,
        }));
    };

    const handleSendCode = async () => {
        if (formData.businessEmail) {
            try {
                setIsSendingCode(true);
                await postCode(formData.businessEmail);
                startTimer();
                setIsCodeSent(true);
            } catch (error) {
                console.error(error);
            } finally {
                setIsSendingCode(false);
            }
        }
    };

    const handleVerifyCode = async () => {
        if (formData.businessEmail && code) {
            setVerificationStatus('pending');
            try {
                const response = await verifyCode(formData.businessEmail, code);
                if (response === false) {
                    setVerificationStatus('verified');
                } else {
                    setVerificationStatus('failed');
                }
            } catch (error) {
                console.error('Error verifying code:', error);
                setVerificationStatus('failed');
            } finally {
                clearInterval(intervalId);
                setTimer('');
            }
        }
    };

    const isFormValid = () => {
        return (
            formData.houseId &&
            formData.businessEmail &&
            formData.name &&
            formData.phoneNumber &&
            formData.position &&
            verificationStatus === 'verified'
        );
    };

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        try {
            const response = await postOnboarding(formData);

            const newJwt = response.data.value.user.jwt;
            const newRefreshToken = response.data.value.user.refreshToken;
            localStorage.setItem('jwt', newJwt);
            localStorage.setItem('refreshToken', newRefreshToken);

            const previousPath = localStorage.getItem('preLoginPath') || '/';
            navigate(previousPath);
        } catch (error) {
            console.error('회원가입 실패:', error);
            alert('회원가입에 실패하였습니다.');
        }
    };

    // console.log(formData);

    return (
        <StContainer>
            <StLogoBox>
                <StLogoContainer>
                    <StLogoText src={logoText} alt='logo-text' />
                    <StLogo src={logo} alt='logo' />
                </StLogoContainer>
            </StLogoBox>
            <StInvestorOnboarding>
                <StTitle>안녕하세요, 투자자님.</StTitle>
                <StInput>
                    <label htmlFor='name'>성함 *</label>
                    <input
                        type='text'
                        name='name'
                        value={formData.name}
                        onChange={handleInputChange}
                        required
                    ></input>
                </StInput>
                <StSelect>
                    <label htmlFor='position'>직책 *</label>
                    <select
                        id='position'
                        name='position'
                        value={formData.position}
                        onChange={handleSelectChange}
                        required
                    >
                        <option value='' disabled selected hidden>
                            선택해주세요
                        </option>
                        <option value='투자 심사역'>투자 심사역</option>
                        <option value='책임 심사역'>책임 심사역</option>
                        <option value='수석 심사역'>수석 심사역</option>
                        <option value='수석 팀장'>수석 팀장</option>
                        <option value='VP'>VP</option>
                        <option value='파트너'>파트너</option>
                        <option value='대표'>대표</option>
                        <option value='기타'>기타</option>
                    </select>
                </StSelect>
                <StInput>
                    <label htmlFor='phoneNumber'>전화번호 *</label>
                    <input
                        type='text'
                        name='phoneNumber'
                        value={formData.phoneNumber}
                        onChange={handleInputChange}
                        placeholder='숫자만 입력해주세요'
                        required
                    ></input>
                </StInput>
                <StInput>
                    <label htmlFor='houseName'>재직 중인 VC명 *</label>
                    <input
                        type='text'
                        name='houseName'
                        required
                        value={selectedHouseName}
                        onChange={handleHouseNameChange}
                        placeholder='한국벤처투자협회에 등록된 기관명으로 조회  🔍'
                    ></input>
                    {filteredHouses.length > 0 && (
                        <StSearchList>
                            {filteredHouses.map((house) => (
                                <div
                                    key={house.id}
                                    onClick={() =>
                                        handleHouseSelect(
                                            house.id,
                                            house.name,
                                            house.homepage
                                        )
                                    }
                                >
                                    {house.name}
                                </div>
                            ))}
                        </StSearchList>
                    )}
                </StInput>
                <StInput>
                    <StSendCodeContainer>
                        <label htmlFor='businessEmail'>
                            비즈니스 이메일 인증 *
                        </label>
                        <StSendCode
                            disabled={emailId.length === 0 || isSendingCode}
                            onClick={handleSendCode}
                        >
                            {isSendingCode ? (
                                <StSpinnerContainer>
                                    <Spinner />
                                </StSpinnerContainer>
                            ) : (
                                '인증코드 보내기'
                            )}
                        </StSendCode>
                    </StSendCodeContainer>
                    <StEmailInput>
                        <input
                            name='emailId'
                            value={emailId}
                            onChange={handleEmailChange}
                            required
                        ></input>
                        <div>@</div>
                        <StEmail>{selectedHouseHomepage}</StEmail>
                    </StEmailInput>
                </StInput>
                {isCodeSent && (
                    <StInput>
                        <label htmlFor='verifyCode'>
                            이메일로 전송된 인증코드를 입력해주세요
                        </label>
                        <input
                            value={code}
                            maxLength={6}
                            onChange={handleCodeChange}
                            placeholder='인증코드 6자리 입력'
                        ></input>
                        <StCountVerify>
                            <StCount>{timer}</StCount>
                            <StVerifyButton
                                disabled={code.length !== 6}
                                onClick={handleVerifyCode}
                            >
                                인증하기
                            </StVerifyButton>
                        </StCountVerify>
                        {verificationStatus === 'verified' && (
                            <StVerified>인증이 완료되었습니다</StVerified>
                        )}
                        {verificationStatus === 'failed' && (
                            <StNotVerified>인증에 실패하였습니다</StNotVerified>
                        )}
                    </StInput>
                )}
                <StFooter>
                    <StAgreementContainer>
                        <StCheckBoxContainer>
                            <input
                                type='checkbox'
                                checked={isNewsletterSubscribed}
                                onChange={handleNewsletterChange}
                            />
                            <span>Seek 뉴스레터 수신 동의</span>
                        </StCheckBoxContainer>
                        <StPolicyText>
                            동의하고 시작하기를 누르는 것으로{' '}
                            <span onClick={navigateToServiceTerm}>
                                Seek 이용 약관
                            </span>
                            과{' '}
                            <span onClick={navigateToPrivacyPolicy}>
                                개인정보 처리방침
                            </span>
                            에 동의합니다.
                        </StPolicyText>
                    </StAgreementContainer>
                    <StStartButton
                        onClick={handleSubmit}
                        disabled={!isFormValid()}
                    >
                        동의하고 시작하기
                    </StStartButton>
                </StFooter>
            </StInvestorOnboarding>
        </StContainer>
    );
}

const StContainer = styled.div`
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    height: 100%;

    ::placeholder {
        font-size: 12px;
    }
`;

const StLogoBox = styled.div`
    position: absolute;
    top: 20px;
    left: 3vw;
`;

const StLogoContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 50px;
`;
const StLogoText = styled.img`
    width: auto;
    height: 30px;
    flex-shrink: 0;
`;
const StLogo = styled.img`
    width: 30px;
    height: 30px;
    flex-shrink: 0;
`;

const StInvestorOnboarding = styled.div`
    margin-top: 100px;
    margin-bottom: 100px;
    width: 70%;
    min-height: 100px;
    border-radius: 20px;
    padding: 86px 10px 60px 10px;
    border: 1px solid #c5c5c5;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;

    @media (max-width: 930px) {
        width: 90%;
    }
`;

const StTitle = styled.div`
    color: #121212;
    font-size: 25px;
    font-weight: 500;

    ${media.mobile`
    font-size: 20px;
    `}
`;

const StInput = styled.div`
    display: flex;
    width: 60%;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 25px;
    position: relative;

    label {
        font-size: 17px;
        font-weight: 700;
    }

    input {
        padding: 20px;
        font-size: 15px;
        font-weight: 300;
        height: 50px;
        border-radius: 8px;
        border: 0.5px solid #828282;
        box-shadow: 0px 1.5px 0px 0px rgba(0, 0, 0, 0.2);

        &:hover {
            box-shadow: 0px 2.5px 3px 0px rgba(0, 0, 0, 0.2);
        }

        &:focus {
            outline: 3px solid #bababa;
        }
    }

    ${media.tablet`
        width: 80%;
        `}

    ${media.mobile`
        width: 90%;
        `}
`;

const StSelect = styled.div`
    display: flex;
    width: 60%;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 25px;

    label {
        font-size: 18px;
        font-weight: 700;
    }

    select {
        padding: 20px;
        font-size: 15px;
        font-weight: 300;
        height: 60px;
        border-radius: 8px;
        border: 0.5px solid #828282;
        box-shadow: 0px 1.5px 0px 0px rgba(0, 0, 0, 0.2);
        cursor: pointer;

        &:hover {
            box-shadow: 0px 2.5px 3px 0px rgba(0, 0, 0, 0.2);
        }

        &:focus {
            outline: 3px solid #bababa;
        }
    }

    ${media.tablet`
        width: 80%;
        `}
    ${media.mobile`
        width: 90%;
        `}
`;

const StSendCodeContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const StSendCode = styled.button`
    cursor: pointer;
    background-color: #fff48e;
    border: none;
    padding: 5px 10px;
    border-radius: 20px;
    font-size: 14px;

    &:disabled {
        cursor: default;
        background-color: #f1f1f1;
        color: #828282;
    }
`;

const StEmailInput = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;

    input {
        padding: 20px;
        font-size: 15px;
        font-weight: 300;
        width: 100%;
        height: 50px;
        border-radius: 8px;
        border: 0.5px solid #828282;
        box-shadow: 0px 1.5px 0px 0px rgba(0, 0, 0, 0.2);
    }
`;

const StEmail = styled.div`
    padding: 20px;
    font-size: 15px;
    font-weight: 300;
    width: 100%;
    height: 50px;
    border-radius: 8px;
    background-color: #f1f1f1;
    border: 0.5px solid #828282;
    box-shadow: 0px 1.5px 0px 0px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
`;

const StCountVerify = styled.div`
    display: flex;
    align-items: center;
    position: absolute;
    top: 44px;
    right: 13px;
    gap: 10px;
`;

const StCount = styled.div`
    font-size: 12px;
`;

const StVerifyButton = styled.button`
    cursor: pointer;
    font-size: 14px;
    border: none;
    background-color: #fff48e;
    border-radius: 20px;
    padding: 4px 8px;

    &:disabled {
        cursor: default;
        background-color: #f1f1f1;
        color: #828282;
    }
`;

// const StSendAgain = styled.div`
//     display: flex;
//     gap: 20px;
//     padding: 0px 5px;
//     font-size: 14px;
//     color: #6f6f6f;

//     p {
//         cursor: pointer;
//         color: black;
//         font-weight: 500;
//         text-decoration: underline;
//     }
// `;

const StVerified = styled.div`
    padding: 0px 5px;
    font-size: 15px;
    font-weight: 500;
    color: #0099ff;
`;

const StNotVerified = styled.div`
    padding: 0px 5px;
    font-size: 15px;
    font-weight: 500;
    color: #fa5793;
`;

const StFooter = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 60%;

    ${media.tablet`
        width: 80%;
        `}
    ${media.mobile`
        width: 90%;
        `}
`;

const StAgreementContainer = styled.div`
    width: 100%;
    min-width: 310px;
    display: flex;
    flex-direction: column;
    justify-content: baseline;
`;

const StCheckBoxContainer = styled.div`
    font-size: 11px;
    display: flex;
    gap: 5px;
    align-items: center;
    padding-left: 2px;
    span {
    }
    ${media.mobile`
   font-size: 9.5px;
    `}
`;

const StPolicyText = styled.p`
    padding-left: 5px;
    font-size: 11px;
    margin-bottom: 10px;
    span {
        text-decoration: underline;
        cursor: pointer;

        &:hover {
            color: black;
        }
    }

    ${media.mobile`
   font-size: 9.5px;
    `}
`;

const StStartButton = styled.button`
    cursor: pointer;
    background-color: #121212;
    padding: 13px;
    font-size: 15px;
    color: white;
    border: none;
    border-radius: 30px;
    width: 100%;
    min-width: 310px;

    &:hover {
        opacity: 0.7;
    }

    &:disabled {
        cursor: default;
        background-color: #f1f1f1;
        color: #828282;

        &:hover {
            opacity: 1;
        }
    }
`;

const StSearchList = styled.div`
    position: absolute;
    background-color: white;
    top: 95%; // 입력 필드 바로 아래에 위치
    left: 0;
    right: 0;
    z-index: 10; // 다른 요소 위에 표시
    border: 1px solid #bababa;
    border-top: none;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

    div {
        padding: 10px 20px;
        border-bottom: 1px solid #ddd;

        &:hover {
            background-color: #f1f1f1;
        }
    }
`;

const StSpinnerContainer = styled.div`
    padding: 1px 5px;
    border-radius: 20px;
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const Spinner = styled.div`
    border: 2px solid #f3f3f3;
    border-top: 2px solid #3498db;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    animation: ${spin} 2s linear infinite;
`;
