import React from 'react';
import styled from 'styled-components';
import Card from '../Card/Card';
import { CardData } from '../../../../shared/Type/type';

interface CardListProps {
    cards: CardData[];
}

export default function CardList({ cards }: CardListProps) {
    return (
        <StCardListContainer>
            {cards?.reverse().map((card, index) => (
                <Card
                    index={index}
                    createdAt={card.createdAt}
                    key={card.id}
                    id={card.id}
                    title={card.title}
                    profileUrl={card.house.profileUrl}
                    name={card.house.name}
                    tag={card.tag}
                    viewCount={card.viewCount}
                />
            ))}
        </StCardListContainer>
    );
}

export const StCardListContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
`;
