import styled from 'styled-components';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1100 },
        items: 2,
    },
    tablet: {
        breakpoint: { max: 1100, min: 680 },
        items: 1,
    },
    mobile: {
        breakpoint: { max: 680, min: 0 },
        items: 1,
    },
};

type Props = {
    children: React.ReactNode;
};

export default function MultiCarousel({ children }: Props) {
    return (
        <StyledCarousel infinite autoPlay responsive={responsive}>
            {children}
        </StyledCarousel>
    );
}

const StyledCarousel = styled(Carousel)`
    align-items: center;
    padding-top: 30px;
    padding-left: 45px;
    z-index: 1;

    @media (max-width: 1100px) {
        padding-left: 120px;
    }

    @media (max-width: 990px) {
        padding-left: 200px;
    }

    @media (max-width: 850px) {
        padding-left: 120px;
    }

    @media (max-width: 700px) {
        padding-left: 85px;
    }

    @media (max-width: 550px) {
        padding-left: 70px;
    }

    @media (max-width: 460px) {
        padding-left: 0;
    }
`;
