import { atom } from 'recoil';
import { CardData, UserData } from '../Type/type';

export const userState = atom<UserData | null>({
    key: 'userState',
    default: null,
});

export const cardState = atom<CardData | null>({
    key: 'cardState',
    default: null,
});
