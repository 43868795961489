import { getJwt } from '../shared/TokenUtils/TokenUtils';
import { api } from './api';
import { EventSourcePolyfill } from 'event-source-polyfill';

export const joinChat = async (partnerUserId: string | undefined) => {
    const response = await api.post(`/chats`, {
        partnerUserId,
    });
    return response.data.value;
};

export const getMessages = async (chatId: string | undefined) => {
    const response = await api.get(`/chats/message/${chatId}`);
    return response.data.value.messages;
};

export const postMessage = async ({
    chatId,
    text,
}: {
    chatId: string;
    text: string;
}) => {
    const response = await api.post(`/chats/message/${chatId}`, { text });
    return response.data.value.message;
};

export const getChatList = async () => {
    const response = await api.get(`/chats`);
    return response.data.value.list;
};

export const connectChat = (chatId: string) => {
    const jwt = getJwt();

    const eventSource = new EventSourcePolyfill(
        `${process.env.REACT_APP_API_URL}/chats/events/${chatId}`,
        {
            headers: { Authorization: `Bearer ${jwt}` },
        }
    );

    eventSource.onerror = (error: any) => {
        console.error('EventSource failed:', error);
        eventSource.close();
    };

    return eventSource;
};
