import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';
import { Theme } from './theme';

export const GlobalStyle = createGlobalStyle`
    ${reset}  

    *, *::before, *::after {
        box-sizing: border-box;  
        font-family: 'Pretendard', sans-serif;       
    }  

    html {
        
    }

    body {
        display: flex;

        margin: 0;
        padding: 0;


        min-height : 100%;

        font-size: 1rem;
        background-color: ${Theme.colors.white};


        font-family: 'Pretendard', sans-serif;

    }

    input {
        padding: 0 8px 0 8px;
    }

    input:focus {
        outline: none;
        box-shadow: 0 0 0 2px #8f62f4; 
    }

    a {
    color: inherit;
    text-decoration: none;
  }

  #root {
    width: 100vw;
    min-height: 100vh; 
    max-width: 1440px;
    margin: 0 auto;
    background-color: ${Theme.colors.white};
    color: ${Theme.colors.black};
}

`;
