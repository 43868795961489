import React from 'react';
import styled, { keyframes } from 'styled-components';
import Card from '../../../LandingPage/components/Card/Card';
import MultiCarousel from '../../components/MultiCarousel';
import useNavi from '../../../../shared/hooks/useNavi';
import { media } from '../../../../styles/MediaQueries';
import { useQuery } from '@tanstack/react-query';
import { getCards } from '../../../../api/cardApi';

export default function TrendingBoardsView() {
    const { navigateToLandingPage } = useNavi();

    const {
        data: cards,
        isPending,
        isError,
        error,
    } = useQuery({
        queryKey: ['cards'],
        queryFn: getCards,
    });

    if (isPending) {
        return <Spinner />;
    }

    if (isError) {
        return <span>Error: {error.message}</span>;
    }

    const topCards = [...cards]
        .sort((a, b) => b.viewCount - a.viewCount)
        .slice(0, 6);

    return (
        <StTrendingBoardsViewContainer>
            <StTextContainer>
                <h3>Trending boards</h3>
                <StAllView onClick={navigateToLandingPage}>전체보기</StAllView>
            </StTextContainer>
            <div>
                <MultiCarousel>
                    {topCards.map((card) => (
                        <Card
                            key={card.id}
                            createdAt={card.createdAt}
                            id={card.id}
                            title={card.title}
                            profileUrl={card.house.profileUrl}
                            name={card.house.name}
                            tag={card.tag}
                            viewCount={card.viewCount}
                        />
                    ))}
                </MultiCarousel>
            </div>
        </StTrendingBoardsViewContainer>
    );
}

const StTrendingBoardsViewContainer = styled.div`
    display: flex;
    width: 60%;
    padding: 40px 40px 20px 40px;
    flex-direction: column;
    gap: 16px;
    border-radius: 20px;
    background: #fff;

    h3 {
        color: #121212;
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        line-height: 130%;
    }

    ${media.tablet`
    width: 80%;
    padding: 30px 30px 15px 30px;
    `}

    ${media.mobile`
    width: 90%;
    padding: 20px 20px 10px 20px;
    `}
`;

const StTextContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    color: #121212;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
`;

const StAllView = styled.div`
    cursor: pointer;
    &:hover {
        filter: opacity(0.5);
    }
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

// 스피너 스타일링
const Spinner = styled.div`
    border: 5px solid #f3f3f3;
    border-top: 5px solid #888888;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: ${spin} 2s linear infinite;
    margin: 50px auto;
`;
