import React from 'react';
import styled, { keyframes } from 'styled-components';
import { getChatList } from '../../../../api/chatApi';
import { media } from '../../../../styles/MediaQueries';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { UserData } from '../../../../shared/Type/type';

interface LastMessage {
    createdAt: string;
}

interface Chat {
    partner: UserData;
    lastMessage: LastMessage;
}

function formatDateTime(dateString: string) {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString('ko-KR', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    });
    const formattedTime = date.toLocaleTimeString('ko-KR', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
    });

    return `${formattedDate}\n${formattedTime}`;
}

export default function Messages() {
    const navigate = useNavigate();
    const {
        data: chatList,
        isLoading,
        isError,
        error,
    } = useQuery({
        queryKey: ['chatList'],
        queryFn: () => getChatList(),
    });

    if (isLoading) {
        return <Spinner />;
    }

    if (isError) {
        return <span>Error: {error.message}</span>;
    }

    // console.log(chatList);

    return (
        <StMessages>
            <StHeader>{`채팅 내역 (${chatList?.length})`}</StHeader>
            <StChatRoomList>
                {chatList.length > 0 ? (
                    chatList?.map((chat: Chat, index: number) => (
                        <StChatRoom
                            key={index}
                            onClick={() => navigate(`/chat/${chat.partner.id}`)}
                        >
                            <StImg src={chat.partner.profileUrl} />
                            <InvestorContainer>
                                <StInvestorName>
                                    {chat.partner.name}
                                </StInvestorName>
                                <StInvestorPosition>
                                    {`${chat.partner.position} @ ${chat.partner.house.name}`}
                                </StInvestorPosition>
                            </InvestorContainer>
                            <StTime>
                                {formatDateTime(chat.lastMessage.createdAt)
                                    .split('\n')
                                    .map((line, lineIndex) => (
                                        <div key={lineIndex}>{line}</div>
                                    ))}
                            </StTime>
                        </StChatRoom>
                    ))
                ) : (
                    <StEmpty>리스트가 비어있습니다.</StEmpty>
                )}
            </StChatRoomList>
        </StMessages>
    );
}

const StMessages = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-left: 20px;
    gap: 20px;

    ${media.mobile`
    margin-left: 0px;
    `}
`;

const StHeader = styled.div`
    padding: 10px 0px 20px 0px;
    font-size: 20px;
    font-weight: 700;
    border-bottom: 1px solid #d4d4d4;
    width: 100%;
`;

const StChatRoomList = styled.div`
    gap: 10px;
`;

const StChatRoom = styled.div`
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    gap: 40px;
    width: 95%;
    background-color: white;
    border-radius: 10px;
    padding: 20px 30px;

    &:hover {
        opacity: 0.7;
    }
`;

const StImg = styled.img`
    width: 80px;
    height: 80px;
    border-radius: 50%;
    object-fit: cover;
`;

const InvestorContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const StInvestorName = styled.div`
    font-size: 22px;
    font-weight: 700;
`;

const StInvestorPosition = styled.div`
    font-size: 16px;
    font-weight: 300;
`;

const StTime = styled.div`
    position: absolute;
    font-size: 14px;
    font-weight: 300;
    text-align: right;
    line-height: 1.2;
    right: 50px;
`;

const StEmpty = styled.div`
    text-align: center;
    color: #919191;
    font-size: 18px;
    padding: 100px;
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

// 스피너 스타일링
const Spinner = styled.div`
    border: 5px solid #f3f3f3;
    border-top: 5px solid #888888;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    animation: ${spin} 2s linear infinite;
    margin: 200px auto;
`;
