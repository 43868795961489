import { api } from './api';

export const getHouseInfo = async (houseId: string) => {
    const response = await api.get(`/house/${houseId}`);
    return response.data.value;
};

export const getHouses = async () => {
    const response = await api.get('/houses');
    return response.data.value.list;
};
