import React, { useEffect } from 'react';
import styled from 'styled-components';
import { deleteUser } from '../../../../api/userApi';
import { useMutation } from '@tanstack/react-query';
import { handleLogout } from '../../../../shared/TokenUtils/TokenUtils';

interface DeleteUserModalProps {
    onClose: () => void;
    type: string;
}

export default function DeleteUserModal({
    onClose,
    type,
}: DeleteUserModalProps) {
    const deleteMutation = useMutation({
        mutationFn: () => deleteUser(),
        onSuccess: () => {
            handleLogout();
        },
    });

    useEffect(() => {
        document.body.style.overflow = 'hidden';
        return () => {
            document.body.style.overflow = 'unset';
        };
    }, []);

    const handleDelete = () => {
        deleteMutation.mutate();
    };

    return (
        <StOverlay>
            <StDeleteUserModal>
                <StModalHeader>정말로 탈퇴할까요?</StModalHeader>
                <StMessage>
                    {type === 'INVESTOR' ? (
                        <div>
                            탈퇴하시면 받은 투자 검토 요청과 카드 및 개인 정보가
                            모두 삭제되어 복구할 수 없어요.
                        </div>
                    ) : (
                        <div>
                            탈퇴하시면 보낸 투자 검토 요청과 투자자로부터 받은
                            회신 및 개인 정보가 모두 삭제되어 복구할 수 없어요.
                        </div>
                    )}
                    <div>그래도 탈퇴하시겠어요?</div>
                </StMessage>
                <StButtonContainer>
                    <StCancel onClick={onClose}>취소하기</StCancel>
                    <StDelete onClick={handleDelete}>탈퇴하기</StDelete>
                </StButtonContainer>
            </StDeleteUserModal>
        </StOverlay>
    );
}

const StOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
`;

const StDeleteUserModal = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 25px 35px;
    background-color: white;
    border-radius: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    width: 365px;
    gap: 10px;
`;
const StModalHeader = styled.h3`
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 10px;
`;

const StMessage = styled.p`
    display: flex;
    flex-direction: column;
    gap: 5px;
    font-size: 15px;
    margin-bottom: 20px;
`;

const StButtonContainer = styled.div`
    display: flex;
    gap: 20px;
`;

const StCancel = styled.div`
    padding: 10px 20px;
    font-size: 18px;
    font-weight: 550;
    cursor: pointer;

    &:hover {
        opacity: 0.6;
    }
`;

const StDelete = styled.div`
    padding: 10px 20px;
    font-size: 17px;
    font-weight: 550;
    color: #d4554c;
    border: 1px solid #d4554c;
    border-radius: 10px;
    cursor: pointer;

    &:hover {
        opacity: 0.6;
    }
`;
