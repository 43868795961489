import React, { useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { media } from '../../../../styles/MediaQueries';

interface StNavItemProps {
    selected: boolean;
}

export default function InvestorNav() {
    const navigate = useNavigate();
    const [selected, setSelected] = useState('');

    const handleSelect = (item: string) => {
        setSelected(item);
        navigate(`/investor-mypage/${item}`);
    };

    return (
        <StInvestorNavContainer>
            <StInvesterNav>
                <StNavItem
                    selected={selected === 'mycards'}
                    onClick={() => handleSelect('mycards')}
                >
                    <div>📜</div>
                    <StText>나의 카드</StText>
                </StNavItem>
                <StNavItem
                    selected={selected === 'messages'}
                    onClick={() => handleSelect('messages')}
                >
                    <div>📨</div>
                    <StText>채팅 내역</StText>
                </StNavItem>
                <StNavItem
                    selected={selected === 'profile'}
                    onClick={() => handleSelect('profile')}
                >
                    <div>👤</div>
                    <StText>프로필</StText>
                </StNavItem>
            </StInvesterNav>
            <StLine></StLine>
        </StInvestorNavContainer>
    );
}

const StInvestorNavContainer = styled.div`
    display: flex;
`;

const StInvesterNav = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-size: 18px;
    padding-right: 20px;
    min-width: 200px;

    ${media.mobile`
       flex-direction: row;
    `}
`;

const StNavItem = styled.div<StNavItemProps>`
    display: flex;
    gap: 20px;
    padding: 10px 15px 10px 10px;
    border-radius: 4px;
    cursor: pointer;
    background-color: ${({ selected }) => (selected ? 'white' : 'transparent')};
    border: ${({ selected }) => (selected ? '1px solid #a9a9a9' : 'none')};

    ${media.mobile`
        width: 100px;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;
    `}
`;

const StLine = styled.div`
    width: 1px;
    height: 400px;
    background-color: #d4d4d4;

    ${media.mobile`
        display:none;
    `}
`;

const StText = styled.div`
    ${media.mobile`
        display:none;
    `}
`;
