import React from 'react';
import styled from 'styled-components';

const tagColors: { [key: string]: string } = {
    'AR/VR': '#FBBEBE',
    핀테크: '#AAF2D4',
    게임: '#FFCB67',
    SaaS: '#F3858E',
    에듀테크: '#D9DB20',
    로보틱스: '#8ECEF3',
    AI: '#BB9BFF',
    프롭테크: '#C5B3B3',
    블록체인: '#7AE47E',
    소셜미디어: '#CEB36D',
    커뮤니티: '#B2CDE0',
    '여행/레저': '#FF9900',
    시니어: '#FF6535',
    '환경/ESG': '#F8D206',
    '제조/하드웨어': '#7B81FF',
    커머스: '#EEEEEE',
    콘텐츠: '#59EC4C',
    '애그리 테크': '#839D77',
    웰니스: '#DFD1FF',
    펫테크: '#FFD596',
    '라이프 스타일': '#DD6DDF',
    '헬스케어/바이오': '#7A97A4',
    기타: '#21A2FF',
    'WEB 3.0': '#B8FFBB',
};

interface TagProps {
    label: string;
}

export default function Tag({ label }: TagProps) {
    const backgroundColor = tagColors[label] || '#FFFFFF';
    return <StTagContainer style={{ backgroundColor }}>{label}</StTagContainer>;
}

const StTagContainer = styled.div`
    height: 28px;
    display: flex;
    padding: 4px 12px;
    justify-content: center;
    border-radius: 20px;
    background: inherit;

    color: #121212;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
`;
